/**
 * 公共API
 */

import Http from "./Http"

interface SelectOption {
    label: any,
    value: any,
}

/**
 * 获取k,v集合
 * @param url url
 */
async function getKv(url: string) {
    let data: any = await Http.get(url)
    let kvs: SelectOption[] = []
    data.map((e: any) => kvs.push({ value: e.k, label: e.v }))
    return kvs
}

async function getLookup(type: string): Promise<LV[]> {
  let data: any = await Http.get<KV[]>('/sys/lookups/kv', {
    lookupType: type,
  })
  let lvs: LV[] = []
  data.map((item: KV) => lvs.push({ value: item.k, label: item.v }))
  return lvs;
}

const Api = {
    getKv,
    getLookup,
}
export default Api
