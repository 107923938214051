import * as React from 'react';
import {Form, DatePicker, Input, message, Space} from 'antd';
import { Page, Card, PermissionButton, Lookup , Table } from '@comps';
import { ColumnProps } from '@comps/types';
import {DateUtils, Http, Context, Env} from '@utils';
import _ from 'lodash';
import LinesMd from './lines.md';
import { Link } from 'react-router-dom';
import {useState} from "react";

export interface Props {};

const XXXPage: React.FC<Props> = () => {
  let [table, setTable] = useState<Table>()

  const onTableRef = (tableRef: Table) => {
    table = tableRef
    setTable(tableRef)
  }

  // const tableRef = React.useRef<Table>();
  const initQuery = React.useMemo<JSONObject>(() => ({
    invoiceDateFromTo: [DateUtils.monthFirstDay(), DateUtils.now()],
  }), []);

  const columns: ColumnProps[] = [
    { key: 'invoiceNo', title: '发票号码', width: 120,
      render:(value, row) => (<Link to={`/tax/outputInvoice/${row.requestNo}/detail`}>{value}</Link>)
    },
    { key: 'invoiceCode', title: '发票代码', width: 140, },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 170, },
    { key: 'invoiceDate', title: '开票日期', width: 110, },
    { key: 'buyerName', title: '客户名称', width: 200, },
    { key: 'invoiceAmount', title: '不含税金额', format: 'amount', width: 120 },
    { key: 'taxAmount', title: '税额', format: 'amount', width: 120 },
    { key: 'totalAmount', title: '价税合计', width: 120, format: 'amount' },
    { key: 'remark', title: '备注', width: 200 },
    { key: 'buyerAddrTel', title: '地址电话', width: 250, },
    { key: 'buyerBank', title: '银行账号', width: 250, },
  ];

  const expandedRowRender = ({ requestNo }: JSONObject) => {
    return (<LinesMd requestNo={requestNo} />);
  };

  const handleCreate = React.useCallback(async () => {
    const ids = table?.getSelectedRowKeys()
    if (_.isEmpty(ids)) {
      message.warn('请选择至少一条记录');
      return;
    }
    const result = await Http.post<JSONObject>('/tax/changes', {
      ids,
    });
    Context.route(`/tax/change/update/${result.id}`);
  }, []);

  function handleExport2() {
    let url = `${Env.getBaseUrl()}/tax/outputInvoiceTrace/sumExportChange?accessToken=${Context.getToken()}`;
    const param: any = table?.getFormParam()

    for (let key in param) {
      if (param[key]!=undefined) {
        url += '&' + key + '=' + param[key];
      }
    }
    window.open(url,'_blank');
  }

  function handleExport3() {
    let url = `${Env.getBaseUrl()}/tax/outputInvoiceTrace/detailExportChange?accessToken=${Context.getToken()}`;
    const param: any = table?.getFormParam()

    for (let key in param) {
      if (param[key]!=undefined) {
        url += '&' + key + '=' + param[key];
      }
    }
    window.open(url,'_blank');
  }

  return (
    <Page>
      <Card title='我要改票' actions={(
        <Space>
          <PermissionButton permissions="ChangeRequest.create"
                            clickCallback={handleCreate}
                            type="primary">申请改票</PermissionButton>
          <PermissionButton type="primary" permissions="OutputInvoiceTrace.sumExportChange"  onClick={handleExport2}>汇总导出</PermissionButton>
          <PermissionButton type="primary" permissions="OutputInvoiceTrace.detailExportChange" onClick={handleExport3}>明细导出</PermissionButton>
        </Space>)}>

        <Table url="/tax/changes/invoices"
          queryProps={{
            initialValues: initQuery,
          }}
          onTableRef={onTableRef}
          selectedRowKeys={[]}
          pagination={{hideOnSinglePage:false}}
          expandable={{ expandedRowRender }}
          columns={columns}>
          <Form.Item name="invoiceType">
            <Lookup lookupType="TAX_INVOICE_TYPE" placeholder="发票类型"></Lookup>
          </Form.Item>
          <Form.Item name="invoiceNo">
            <Input placeholder="发票号码" />
          </Form.Item>
          <Form.Item name="invoiceDateFromTo">
            <DatePicker.RangePicker format='YYYYMMDD' placeholder={["开票日期从", "至"]} />
          </Form.Item>
          <Form.Item name="buyerName">
            <Input placeholder="客户名称" />
          </Form.Item>
          <Form.Item name="employeeCode">
            <Input placeholder="业务员编码" />
          </Form.Item>
          <Form.Item name="projectName">
            <Input placeholder="项目名称" />
          </Form.Item>
          <Form.Item name="remarks">
            <Input placeholder="备注" />
          </Form.Item>
          <Form.Item name="isChangeStatus">
            <Lookup lookupType="IS_CHANGE_STATUS" placeholder="是否可改票"></Lookup>
          </Form.Item>
        </Table>
      </Card>
    </Page>
  );
};

export default XXXPage;
