import React, { FC, useEffect, useState } from 'react';
import { Tree, Space, Row, Col, Form, Input, Table, Checkbox, Select } from 'antd';
import { Page, Card, DisplayInput, Modal, LinkText } from '@comps'
import { Http, Message, Layout, Api } from '@utils'


/**
 * 组织架构
 */
const Item = Form.Item

const Dept: FC = () => {
    let [deptTree, setDeptTree] = useState<[]>()
    let [selectedDeptId, setSelectedDeptId] = useState(-1)
    let [positionDs, setPositionDs] = useState<any[]>()
    let [empDs, setEmpDs] = useState<any[]>()
    let [positionModalVisible, setPositionModalVisible] = useState(false)
    let [deptModalVisible, setDeptModalVisible] = useState(false)
    let [dispatchModalVisible, setDispatchModalVisible] = useState(false)
    let [empOptions, setEmpOptions] = useState<any[]>()
    let [orgOptions, setOrgOptions] = useState<any[]>()
    const [basicInfoForm] = Form.useForm()
    const [positionForm] = Form.useForm()
    const [deptForm] = Form.useForm()
    const [dispatchForm] = Form.useForm()

    useEffect(() => {
        renderDeptTree()
        Api.getKv('/org/orgs/kv').then(data => {
            setOrgOptions(data)
        })
    }, [])

    /**
     * 渲染部门树
     */
    const renderDeptTree = () => {
        Http.get('/hr/depts/deptTree/antd').then((data: any) => {
            setDeptTree(data)
        })
    }

    /**
     * 选中/取消选中树上的部门
     * @param selectedKeys 
     * @param info 
     */
    const onDeptSelected = (selectedKeys: any[], info: any) => {
        let selected: boolean = info.selected
        let node = info.node
        let key = node.key

        setSelectedDeptId(selected ? key : -1)

        if (selected) {
            Http.get(`/hr/depts/${key}`).then(data => {
                basicInfoForm.setFieldsValue({ ...data })

                renderPositions(key)
                renderDeptEmployees(key)
            })
        }
    }

    /**
     * 渲染部门下的岗位
     * @param deptId 部门ID
     */
    const renderPositions = (deptId: number) => {
        Http.get(`/hr/positions/deptPositions?deptId=${deptId}`).then((data: any) => {
            setPositionDs(data)
        })
    }

    /**
     * 渲染部门下的员工
     * @param deptId 部门ID
     */
    const renderDeptEmployees = (deptId: number) => {
        Http.get(`/hr/depts/${deptId}/employees`).then((data: any) => {
            setEmpDs(data)
        })
    }

    /**
     * 渲染岗位下的员工
     * @param positionId 岗位ID
     */
    const renderPositionEmployees = (positionId: number) => {
        Http.get(`/hr/positions/${positionId}/employees`).then((data: any) => {
            setEmpDs(data)
        })
    }

    /**
     * 删除岗位
     * @param id
     */
    const deletePosition = (id: number) => {
        Http.del(`/hr/positions/${id}`).then(data => {
            renderPositions(selectedDeptId)
            Message.ok()
        })
    }

    /**
     * 在弹出框中展示岗位信息
     * @param id 岗位ID
     */
    const showPositionDetail = (id: number) => {
        setPositionModalVisible(true)
        Http.get(`/hr/positions/${id}`).then(data => {
            positionForm.setFieldsValue({ ...data })
        })
    }

    /**
     * 展示分派员工窗口
     * @param positionId 
     */
    const showDispathModal = (positionId: number, positionName: string) => {
        setDispatchModalVisible(true)
        dispatchForm.setFieldsValue({ positionId, positionName })
        Api.getKv('/sys/users/kv').then(data => {
            setEmpOptions(data)
        })
    }

    /**
     * 在弹出框中展示部门信息
     * @param id 
     */
    const showDeptDetail = (id: number) => {
        setDeptModalVisible(true)
        Http.get(`/hr/depts/${id}`).then(data => {
            deptForm.setFieldsValue({ ...data })
        })
    }

    /**
     * 删除部门
     * @param id 
     */
    const deleteDept = (id: number) => {
        Http.del(`/hr/depts/${id}`).then(data => {
            renderDeptTree()
            Message.ok()
        })
    }

    /**
     * 创建或更新岗位
     */
    const createOrUpdatePosition = () => {
        let id = positionForm.getFieldValue("id")

        positionForm.validateFields().then(values => {
            if (id < 0) {
                Http.post('/hr/positions', { ...values, deptId: selectedDeptId }).then(data => {
                    setPositionModalVisible(false)
                    positionForm.resetFields()
                    renderPositions(selectedDeptId)
                    Message.saveOk()
                })
            } else {
                Http.put('/hr/positions', { ...values }).then(data => {
                    setPositionModalVisible(false)
                    positionForm.resetFields()
                    renderPositions(selectedDeptId)
                    Message.saveOk()
                })
            }
        })
    }

    /**
     * 创建或更新部门
     */
    const createOrUpdateDept = () => {
        let id = deptForm.getFieldValue("id")
        deptForm.validateFields().then(values => {
            if (id < 0) {
                Http.post('/hr/depts', { ...values, parentDeptId: selectedDeptId }).then(data => {
                    setDeptModalVisible(false)
                    deptForm.resetFields()
                    renderDeptTree()
                    Message.saveOk()
                })
            } else {
                Http.put('/hr/depts', { ...values }).then(data => {
                    setDeptModalVisible(false)
                    deptForm.resetFields()
                    renderDeptTree()
                    Message.saveOk()
                })
            }
        })
    }

    /**
     * 给员工分派岗位
     */
    const dispatchEmpPosition = () => {
        dispatchForm.validateFields().then(values => {
            Http.post('/hr/positions/dispatch', values).then(data => {
                Message.ok()
                dispatchForm.resetFields()
                setDispatchModalVisible(false)
                renderDeptEmployees(selectedDeptId)
            })
        })
    }

    /**
     * 解除员工岗位
     */
    const revokeEmpPosition = (empId: number, positionId: number) => {
        Http.del(`/hr/positions/revoke?userId=${empId}&positionId=${positionId}`).then(data => {
            Message.ok()
            renderDeptEmployees(selectedDeptId)
        })
    }

    return (
        <Page >
            <Card title='组织架构'>
                <Row>
                    <Col span={7}>
                        <Tree
                            treeData={deptTree}
                            onSelect={onDeptSelected}
                        />
                    </Col>
                    <Col span={17}>
                        <Card title='基本信息' actions={selectedDeptId > 0 &&
                            <Space>
                                <LinkText label='创建子部门' onClick={() => setDeptModalVisible(true)} />
                                <LinkText label='修改' onClick={() => showDeptDetail(selectedDeptId)} />
                                <LinkText label='删除' onClick={() => deleteDept(selectedDeptId)} />
                            </Space>}>

                            <Form form={basicInfoForm}>
                                <Row>
                                    <Col span={10}>
                                        <Item name='deptName' label='部门名称'><DisplayInput /></Item>
                                        <Item name='orgName' label='关联公司'><DisplayInput /></Item>
                                    </Col>
                                    <Col span={8}>
                                        <Item name='parentDeptName' label='上级部门'><DisplayInput /></Item>
                                        <Item name='seq' label='序号'><DisplayInput /></Item>
                                    </Col>
                                    <Col span={6}>
                                        <Item name='deptCode' label='部门编码'><DisplayInput /></Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        <Card title='岗位信息' actions={selectedDeptId > 0 &&
                            <Space>
                                <LinkText label='新增岗位' onClick={() => setPositionModalVisible(true)} />
                            </Space>}>
                            <Table columns=
                                {[
                                    { title: '序号', dataIndex: 'seq' },
                                    { title: '岗位编码', dataIndex: 'positionCode' },
                                    { title: '岗位名称', dataIndex: 'positionName' },
                                    { title: '主管岗位', dataIndex: 'managerFlag', render: (value, row, index) => value ? '是' : '否' },
                                    {
                                        title: '操作', dataIndex: 'id', width: 250,
                                        render: (positionId, row, index) =>
                                            <Space>
                                                <LinkText label='修改' onClick={() => showPositionDetail(positionId)} />
                                                <LinkText label='查看员工' onClick={() => renderPositionEmployees(positionId)} />
                                                <LinkText label='分配员工' onClick={() => showDispathModal(positionId, row.positionName)} />
                                                <LinkText label='删除' onClick={() => deletePosition(positionId)} />
                                            </Space>
                                    },
                                ]}
                                dataSource={positionDs}
                                rowKey='id'
                                pagination={false}
                            ></Table>
                        </Card>

                        <Card title='员工信息'>
                            <Table columns=
                                {[
                                    { title: '员工编号', dataIndex: 'employeeNo' },
                                    { title: '员工名称', dataIndex: 'lastName' },
                                    { title: '岗位名称', dataIndex: 'positionName' },
                                    { title: '在岗状态', dataIndex: 'partTimeFlag', render: (value) => value ? '兼职岗位' : '主岗位' },
                                    {
                                        title: '操作', dataIndex: 'id',
                                        render: (value, row, index) => 
                                            <LinkText label='解除岗位' onClick={() => revokeEmpPosition(value, row.positionId)} />
                                    },
                                ]}
                                dataSource={empDs}
                                rowKey='id'
                                pagination={false}
                            ></Table>
                        </Card>
                    </Col>
                </Row>
            </Card>

            <Modal title='新增/更新岗位' visible={positionModalVisible}
                onCancel={() => { setPositionModalVisible(false); positionForm.resetFields() }}
                onOk={createOrUpdatePosition}>
                <Form form={positionForm} {...Layout._1c_1_2}>
                    <Item name='id' style={{ display: 'none' }} initialValue={-1}><Input /></Item>
                    <Item label='序号' name='seq' required><Input type='number' min={1} placeholder='推荐以10为单位递增,如:10,20...' /></Item>
                    <Item label='岗位名称' name='positionName' required><Input /></Item>
                    <Item label='岗位编码' name='positionCode' ><Input placeholder='请使用OA系统编码或留空' /></Item>
                    <Item label='主管岗位' name='managerFlag' required valuePropName='checked'><Checkbox /></Item>
                </Form>
            </Modal>

            <Modal title='新增子部门' visible={deptModalVisible}
                onCancel={() => { setDeptModalVisible(false); deptForm.resetFields() }}
                onOk={createOrUpdateDept}>
                <Form form={deptForm} {...Layout._1c_1_2}>
                    <Item name='id' style={{ display: 'none' }} initialValue={-1}><Input /></Item>
                    <Item label='序号' name='seq' required><Input type='number' min={1} placeholder='推荐以10为单位递增,如:10,20...' /></Item>
                    <Item label='部门名称' name='deptName' required><Input /></Item>
                    <Item label='部门编码' name='deptCode' ><Input placeholder='请使用OA系统编码或留空' /></Item>
                    <Item label='关联公司' name='orgId' ><Select showSearch optionFilterProp="label" options={orgOptions} /></Item>
                </Form>
            </Modal>

            <Modal title='分配员工' visible={dispatchModalVisible}
                onCancel={() => { setDispatchModalVisible(false); dispatchForm.resetFields() }}
                onOk={dispatchEmpPosition}>
                <Form form={dispatchForm} {...Layout._1c_1_2}>
                    <Item name='positionId' style={{ display: 'none' }} initialValue={-1}><Input /></Item>
                    <Item label='岗位名称' name='positionName'><DisplayInput /></Item>
                    <Item label='员工' name='userId' required><Select showSearch optionFilterProp="label" options={empOptions} /></Item>
                    <Item label='主岗位' name='mainPositionFlag' valuePropName='checked'><Checkbox /></Item>
                </Form>
            </Modal>
        </Page >

    );
};

export { Dept }