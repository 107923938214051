import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Page, Card, Invoice } from '@comps';

import { Http } from '@utils';

interface Props {
  url: string 
};

const InvDetail: FC<Props> = ({ url }) => {
  const routerParams: JSONObject = useParams();
  const { id } = routerParams;
  
  const [invInfo, setInvInfo] = useState<JSONObject>({});

  useEffect(() => {
    getInvInfo()       
  }, []);
  
  const getInvInfo = () => {
    Http.get(url, { id })
      .then(_invInfo => {
        const lines: JSONObject[] = _invInfo.lineList;

        for(let detailRow of lines) {
          detailRow.invoiceGoodsName = detailRow.goodsName;
          detailRow.withTaxPrice = detailRow.price;
        }

        setInvInfo({ 
          ..._invInfo, 
          lines,
          fullInvoiceTypeDesc: _invInfo.invoiceTypeDesc,
          invoiceGoodsName: _invInfo.goodsName,
          withTaxPrice: _invInfo.price,
          invoiceAmount: _invInfo.invoiceAmount ?? _invInfo.amount,
          totalAmount: _invInfo.totalAmount ?? _invInfo.withTaxAmount
        });
      })
  }

  return (
    <Page backable>
      <Card>
        <Invoice invoice={invInfo} />
      </Card>
    </Page>
  );
};

export default InvDetail;