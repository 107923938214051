import React, { FC } from 'react'
import { Input } from 'antd'
import { ColumnProps } from '@comps/types'
import { Page, Card, Table } from '@comps'


const LoginLogList: FC = () => {

    const columns: ColumnProps[] = [
        {
            title: '用户名',
            key: "userName",
        },
        {
            title: '登陆时间',
            key: 'loginTime',
            format: 'datetime',
        },
        {
            title: 'IP地址',
            key: 'ipAddress',
        },
        {
            title: '操作系统',
            key: 'os',
        },
        {
            title: '浏览器',
            key: 'browser',
        },
        {
            title: '浏览器版本',
            key: 'browserVersion',
        },
        {
            title: '登陆设备',
            key: "device",
        },
    ];

    return (
        <Page>
            <Card title='登陆日志' >
                <Table columns={columns} url='/auth/loginLogs'>
                    <Input name='userName' placeholder='用户名' />
                    <Input name='ipAddress' placeholder='IP地址' />
                </Table>
            </Card>
        </Page >
    )
}

export { LoginLogList }