/**
 * Form 布局
 */

/** Modal 单列布局 */
const modal_1c = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

/**
 * Card 单列布局
 */
const card_1c = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
}

const card_1c_actions = {
    wrapperCol: { span: 8, offset: 8 },
}

/**
 * 单列, label span:wrapper span=1:2
 */
const _1c_1_2 = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}


const Layout = {
    modal_1c,
    card_1c,
    card_1c_actions,
    _1c_1_2,
}

export default Layout