import React, { FC, useState, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Space, Form, DatePicker, Input, Button, Tabs, Tag, Select } from 'antd';

import { Page, Card, Table, Modal, PermissionButton, Lookup, Download, Upload } from '@comps';
import BookkeepingMD from './modal/Bookkeeping.md';
import InvoiceCollectMD from './modal/InvoiceCollect.md';
import ProcessNoteMD from './modal/ProcessNote.md'; 

import { Http, Message, FormUtil } from '@utils';
import { ColumnProps } from '@comps/types';
import { ExportType } from './interface';
import AbnormalInvRemindMD from './modal/AbnormalInvRemind.md';

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;

const QueryList: FC = () => {

  const [modal, modalHolder] = Modal.useModal();

  const [invPoolTable, setInvPoolTable] = useState<Table>();
  const [abnormalInvTable, setAbnormalInvTable] = useState<Table>();

  const isFirstClickAbnormalTabRef = useRef(true);

  const onInvPoolTableRef = (tableRef: Table) => {
    setInvPoolTable(tableRef);
  };

  const onAbnormalTableRef = (tableRef: Table) => {
    setAbnormalInvTable(tableRef);
  };

  const [checkLoading, setCheckLoading] = useState(false);
  const [batchDelLoading, setBatchDelLoading] = useState(false);

  const refreshInvPoolTable = () => {
    invPoolTable?.refresh();
  };

  const refreshAbnormalTable = () => {
    abnormalInvTable?.refresh();
  };

  const handleExport = (exportType: string) => {
    const ids = invPoolTable?.getSelectedRowKeys();

    if (ids?.length === 0) {
      Message.warn('请至少选择一条数据');
      return;
    }

    let url = '';

    switch (exportType) {
      case ExportType.SUMMARY:
        url = '/input/invPool/collectExport'
        break;
      case ExportType.DETAIL:
        url = '/input/invPool/detailedExport'
        break;
      default:
        break;
    }

    FormUtil.exportFileByPost(url, { ids });
  };

  const handleAbnormalInvExport = () => {
    const ids = abnormalInvTable?.getSelectedRowKeys();

    if (ids?.length === 0) {
      Message.warn('请至少选择一条数据');
      return;
    }

    FormUtil.exportExcelFile('/input/invPool/collectExceExport', { ids });
  };

  const showBookKeepingMD = () => {
    const selectedRow = invPoolTable?.getSelectedRowData();

    if (selectedRow?.length !== 1) {
      Message.warn('请选择一条 【已查验】【未报销】【正常】 的数据');
      // Message.warn('请选择一条 【已查验】【未报销】 的数据');
      return;
    }

    const currentRow = selectedRow[0];
    const { checked, reimburseStatus, statusCode } = currentRow;

    if (checked !== 1) {
      Message.warn('请选择一条 “已查验” 的数据');
      return;
    }

    if (reimburseStatus) {
      Message.warn('该发票已报销成功，无法重复记账');
      return;
    }

    if (statusCode !== 0) {
      Message.warn('本发票为异常发票，无法进行记账操作');
      return;
    }

    modal.open({
      width: 400,
      content: (_1, reject) => (
        <BookkeepingMD
          rowData={selectedRow[0]} 
          refreshTable={refreshInvPoolTable}
          onClose={reject} 
        />
      )
    });
  };

  const showInvCollectMD = () => {
    modal.open({
      width: 460,
      content: (_1, reject) => (
        <InvoiceCollectMD onClose={reject} />
      )
    });
  };

  const showProcessNoteMD = (rowInfo: JSONObject) => {
    modal.open({
      width: 350,
      content: (_1, reject) => (
        <ProcessNoteMD 
          rowInfo={rowInfo} 
          onClose={reject} 
          refreshTable={refreshAbnormalTable}
        /> 
      )
    });
  };

  const handleAbnormalInv = () => {
    const selectedRow = abnormalInvTable?.getSelectedRowData() ?? [];

    if (selectedRow?.length !== 1) {
      Message.warn('请选择一张发票进行处理');
      return;
    } 

    showProcessNoteMD(selectedRow[0]);
  };

  const cancelHandleAbnormalInv = (id: number) => {
    Http.get('/input/invPool/CdealExceInvoice', { invoiceId: id })
      .then(() => {
        Message.success('取消成功');
        abnormalInvTable?.refresh();
      })
  };

  const isCancelHandleAbnormalInv = () => {
    const ids = abnormalInvTable?.getSelectedRowKeys() ?? [];

    if (ids?.length === 0) {
      Message.warn('请至少选择一条数据');
      return;
    }

    Modal.confirm({
      title: '是否进行取消处理 ？',
      onOk: () => cancelHandleAbnormalInv(ids[0])
    })
  };

  const handleTabClick = (key: string) => {
    if (key === '2' && isFirstClickAbnormalTabRef.current) {
      modal.open({
        width: 400,
        content: (_1, reject) => (
          <AbnormalInvRemindMD onClose={reject} /> 
        )
      });
    }

    isFirstClickAbnormalTabRef.current = false;
  };

  const renderCheckStatus = (checkType: number) => {
    if (checkType === 0) {
      return <span style={{ color: '#333333' }}>未查验</span>
    } else if (checkType === 1) {
      return <span style={{ color: '#04d919' }}>已查验</span>
    } else if (checkType === 2) {
      return <span style={{ color: '#3facdf' }}>查验中</span>
    } else if (checkType === 3) {
      return <span style={{ color: '#d9001b' }}>查验失败</span>
    }
  };

  const baseColumns: ColumnProps[] = [
    { key: 'checked', title: '查验状态', width: 110, fixed: 'left',
      render: renderCheckStatus
    },
    { key: 'sourceDesc', title: '数据来源', width: 130 }, 
    { key: 'reimburseBy', title: '报销人', width: 130 },
    { key: 'voucherNo', title: '凭证号', width: 130 },
    { key: 'reimburseTime', title: '报销日期', width: 130,
      render: (reimburseTime: string) => reimburseTime ? reimburseTime.split(' ')[0] : ''
    },
    { key: 'reimburseStatus', title: '报销状态', width: 130,
      render: (reimburseStatus: any) => {
        return reimburseStatus ? <Tag color="green">已报销</Tag> : <Tag color="orange">未报销</Tag>
      }
    },
    { key: 'invoiceCode', title: '发票代码', width: 160 },
    { key: 'invoiceNo', title: '发票号码', width: 130 },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 130 },
    { key: 'statusCodeDesc', title: '发票状态', width: 130, },
    { key: 'invoiceDate', title: '开票日期', width: 120 },
    { key: 'invoiceAmount', title: '金额', width: 130 },
    { key: 'taxAmount', title: '税额', width: 130 },
    { key: 'totalAmount', title: '价税合计', width: 130 },
    { key: 'sellerBank', title: '销方银行账号', width: 400 }, 
    { key: 'sellerName', title: '销方名称', width: 300 },
    { key: 'sellerTaxNo', title: '销方税号', width: 200 },
    { key: 'sellerAddrTel', title: '销方地址电话', width: 400 }
  ];

  const invPoolColumns: ColumnProps[] = [
    ...baseColumns,
    {
      key: 'id', title: '操作', width: 100, fixed: 'right', align: 'center',
      render(id, row) {
        return (
          <Space>
            <Link to={`/income/query/${id}/detail`}>详情</Link>
          </Space>
        )
      }
    }
  ];

  const abnormalInvColumns: ColumnProps[] = [
    ...baseColumns,
    {
      key: 'processedStatus', title: '处理状态', width: 100, fixed: 'right', align: 'center',
      render: (processedStatus: boolean, rowInfo: JSONObject) => {
        return processedStatus 
          ? <span 
              style={{ color: '#02a7f0', cursor: 'pointer' }} 
              onClick={() => showProcessNoteMD(rowInfo)}
            >
              已处理
            </span> 
          : '未处理' 
      }
    }
  ];

  return (
    <Page>
      {modalHolder}
      <Card style={{ paddingTop: 0 }}>
        <Tabs 
          defaultActiveKey="1" 
          onTabClick={handleTabClick}
          tabBarStyle={{ margin: 0 }} 
        >
          <TabPane tab="发票池" key="1">
            <Card 
              style={{ 
                paddingTop: 0, 
                boxShadow: 'none' 
              }} 
              actions={
                <Fragment>
                  <Button
                    type="primary"
                    loading={checkLoading}
                    onClick={showInvCollectMD}
                  >
                    发起进项发票采集
                  </Button>
                  <Button
                    type="primary"
                    loading={checkLoading}
                  >
                    统计
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleExport(ExportType.SUMMARY)}
                  >
                    汇总导出
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleExport(ExportType.DETAIL)}
                  >
                    明细导出
                  </Button>
                  <Button
                    type="primary"
                    loading={batchDelLoading}
                    onClick={showBookKeepingMD}
                  >
                    记账
                  </Button>
                </Fragment>
              }>
              <Table 
                onTableRef={onInvPoolTableRef}
                url='/input/invPool/getInvoicesPool' 
                columns={invPoolColumns} 
                selectedRowKeys={[]}
              >
                <Item name="invoiceCono">
                  <Input placeholder="发票号码/代码" />
                </Item>
                <Item name="invoiceDateFromTo">
                  <RangePicker 
                    placeholder={['开票日期从', '开票日期至']} 
                    format='YYYY-MM-DD'
                  />
                </Item>
                <Item name="invoiceType">
                  <Lookup placeholder='发票类型' lookupType='INPUT_INVOICE_TYPE' />
                </Item>
                <Item name="statusCode">
                  <Lookup placeholder='发票状态' lookupType='input_invoice_status' />
                </Item>
                <Item name="reimburseStatus">
                  <Select placeholder="报销状态">
                    <Option value={'0'}>未报销</Option>
                    <Option value={'1'}>已报销</Option>
                  </Select>
                </Item>
              </Table>
            </Card>
          </TabPane>
          <TabPane tab="异常发票" key="2">
            <Card 
              style={{ 
                paddingTop: 0, 
                boxShadow: 'none' 
              }} 
              actions={
                <Fragment>
                  <Button
                    type="primary"
                    loading={checkLoading}
                    onClick={handleAbnormalInv}
                  >
                    处理
                  </Button>
                  <Button
                    type="primary"
                    loading={checkLoading}
                    onClick={isCancelHandleAbnormalInv}
                  >
                    取消处理
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleAbnormalInvExport}
                  >
                    汇总导出
                  </Button>
                </Fragment>
              }>
              <Table 
                onTableRef={onAbnormalTableRef}
                url='/input/invPool/getExceInvoices' 
                columns={abnormalInvColumns} 
                selectedRowKeys={[]}
              >
                <Item name="invoiceCono">
                  <Input placeholder="发票号码/代码" />
                </Item>
                <Item name="invoiceDateFromTo">
                  <RangePicker 
                    placeholder={['开票日期从', '开票日期至']} 
                    format='YYYY-MM-DD'
                  />
                </Item>
                <Item name="invoiceType">
                  <Lookup placeholder='发票类型' lookupType='INPUT_INVOICE_TYPE' />
                </Item>
                <Item name="statusCode">
                  <Lookup placeholder='发票状态' lookupType='input_invoice_status' />
                </Item>
              </Table>
            </Card>
          </TabPane>
        </Tabs>
      </Card>
    </Page>
  );
};

export default QueryList;