import { Context, Env } from '@utils';
import { Button } from 'antd';
import React, { FC, Fragment } from 'react'

interface DownloadProps {
    url: string,
    label?: string,
    preview?: boolean,
    type?: string,
}

const Download: FC<DownloadProps> = (props) => {
    let url = props.url;
    if (!url) {
        return <Fragment/>
    }

    let baseUrl = Env.getBaseUrl() as string;
    if (!url.includes('http')) {
        url = baseUrl + url
    }
    url = url.concat(!url.includes('?') ? '?' : '')
    url = url.concat(!props.preview ? '&download=true' : '');
    url = url.concat('&accessToken=').concat(Context.getToken())

    let label = props.label
    if (!label) {
        label = props.preview ? '预览' : '下载';
    }

    let type = props.type
    if (!props.type) {
        type = 'link'
    }

    return (
        <Fragment>
            {type === 'link' && <a href={url} target="_bank">{label}</a>}
            {type === 'button' && <Button type='primary'><a href={url} target="_bank">{label}</a></Button>}
            {type === 'linkButton' && <Button type="link"><a href={url} target="_bank">{label}</a></Button>}
        </Fragment>
    )
}

export default Download