import React, { FC, useState } from 'react'
import { Button, Form, Row, Upload, Spin, Space, Popconfirm, Input } from 'antd'
import { HomeOutlined, UploadOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/lib/upload'

import { Page, Card, LinkText, Table, Modal } from '@comps'
import { Http, Message, Env, Context, Layout } from '@utils'
import { ColumnProps } from '@comps/types'
import api from './api'

const Item = Form.Item

/**
 * 文件管理
 * @param props 
 */
const FileList: FC = (props: any) => {
    const bucketId = props.match.params.id
    let [fileTable, setFileTable] = useState<Table>()
    let [parentFileId, setParentFileId] = useState(bucketId)
    let [levelPaths, setLevelPaths] = useState<[]>()
    let [uploading, setUploading] = useState(false)
    let [isVisible, setVisible] = useState(false)
    let [cardLoading, setCardLoading] = useState(false)
    let [form] = Form.useForm()

    const onFolderChange = (folderId: any) => {
        setParentFileId(folderId)

        Http.get(`/cms/files/${folderId}/levelPath`).then((data: any) => {
            setLevelPaths(data)
            refreshTable()
        })
    }

    const onBucketClick = () => {
        setLevelPaths([])
        setParentFileId(bucketId)
        refreshTable()
    }

    const onDeleteFile = (fileId: number) => {
        setCardLoading(true)
        Http.del(`/cms/files/${fileId}`).then(data => {
            Message.ok()
        }).finally(() => {
            setCardLoading(false)
            refreshTable()
        })
    }

    const columns: ColumnProps[] = [
        {
            key: 'fileType',
            title: '',
            width: 40,
            render: (value) => (api.getFileLogo(value))
        },
        {
            key: 'originalFileName',
            title: '文件名',
            width: 300,
            render: (value, row) => (row.folderFlag ? <LinkText label={value} onClick={() => onFolderChange(row.id)} /> : value)
        },
        {
            key: 'humanFileSize',
            title: '文件大小',
            width: 120,
        },
        {
            key: 'creationTime',
            title: '上传时间',
            format: 'datetime',
        },
        {
            key: 'lastUpdateTime',
            title: '最后更新时间',
            format: 'datetime',
        },
        {
            key: 'id',
            title: '操作',
            render: (value, row) => (<Space>
                {!row.folderFlag && <a href={api.getDownloadUrl(row.fileUrl)}
                    download={row.contentType}
                    target="_blank" rel="noopener noreferrer">下载</a>}
                {!row.folderFlag && <LinkText label='拷贝链接' onClick={() => { api.copyToClipboard(row.fileUrl) }} />}
                <Popconfirm title='您确认要删除文件吗?' onConfirm={() => onDeleteFile(value)}><LinkText label='删除' /></Popconfirm>
            </Space>)
            ,
        }
    ]

    const refreshTable = () => {
        setTimeout(() => { fileTable?.refresh() }, 100);
    }

    const onTableRef = (table: Table) => {
        fileTable = table
        setFileTable(table)
    }

    /**
     * 文件上传
     * @param info 
     */
    const onUploadChange = (info: UploadChangeParam) => {
        //const [uploadingStatus, doneStatus, errorStatus] = ['uploading', 'done', 'error']
        let file: any = info.file
        let status = file.status

        switch (status) {
            case 'uploading':
                let event: any = info.event
                if (event) {
                    // const { loaded, percent, total } = event
                    const { percent } = event
                    Message.info("文件上传中,当前进度:" + parseInt(percent) + '%')
                } else {
                    Message.info("文件上传中,请稍后...")
                }

                setUploading(true)
                break
            case 'done':
                setUploading(false)
                let statusCode = file.response.code
                let tips = file.response.msg
                if (statusCode === '200') {
                    Message.info("上传成功")
                    setUploading(false)
                    refreshTable()
                } else {
                    Message.info("上传失败,原因:" + tips)
                }
                break
        }
    }

    const createFolder = () => {
        setCardLoading(true)
        form.validateFields().then(values => {
            Http.post('/cms/folders', { ...values, bucketId: bucketId, parentFileId: parentFileId }).then(data => {
                Message.saveOk()
                setCardLoading(false)
                setVisible(false)
                refreshTable()
            })
        })
    }

    return (
        <Page>
            <Card title='文件管理' loading={cardLoading} actions={
                <Space>
                    <Button onClick={() => { form.resetFields(); setVisible(true) }}>创建文件夹</Button>
                    <Upload name='file' showUploadList={false}
                        action={`${Env.getBaseUrl()}/cms/files`}
                        headers={{ authorization: Context.getToken() }}
                        data={{ bucketId: bucketId, folderId: parentFileId }}
                        onChange={onUploadChange}>
                        <Spin spinning={uploading}><Button type='primary'><UploadOutlined /> 上传文件</Button></Spin>
                    </Upload>
                </Space>
            }>

                <Row>
                    <Space><HomeOutlined /> <LinkText onClick={onBucketClick} label='我的文件' />
                        {levelPaths?.map((e: any) => <LinkText label={` > ${e.v}`} onClick={() => onFolderChange(e.k)} />)}
                    </Space>
                </Row>
                <br />
                <Table url={`/cms/files?parentFileId=${parentFileId}`} columns={columns} onTableRef={onTableRef} />

                <Modal title='创建文件夹' visible={isVisible} onCancel={() => setVisible(false)} onOk={createFolder}>
                    <Form form={form} {...Layout._1c_1_2}>
                        <Item name='fileName' label='文件夹名' required><Input /></Item>
                        <Item name='fileCode' label='文件夹编码' ><Input placeholder='如无特殊用途请不要设置' /></Item>
                    </Form>
                </Modal>
            </Card>
        </Page>
    )
}

export { FileList }