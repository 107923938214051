import React, { FC } from 'react';

import { InvDetail } from '../../component/index';

const QueryInvDetail: FC = () => {
  return (
    <InvDetail url="/input/invPool/details" />
  );
};

export default QueryInvDetail;