import * as React from 'react';
import { Space, Form, DatePicker, Input } from 'antd';
import { Card, Button, Lookup } from '@comps'
import { Context, DateUtils, Env } from '@utils'

const { RangePicker } = DatePicker;

const Item = Form.Item
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

export interface Props {
    onClose: () => void,
}

const InvoiceCancel: React.FC<Props> = ({
    onClose,
}) => {

    const [form] = Form.useForm();

    const handleExport = () => {
        let values = form.getFieldsValue()
        const { requestDateFromTo, buyerTaxNo, sellerTaxNo, invoiceType, statusCode } = values
        let url = `${Env.getBaseUrl()}/tax/outputInvoices/export?accessToken=${Context.getToken()}`
        if (requestDateFromTo) {
            url += `&requestDateFromTo=${requestDateFromTo}`
        }
        if (buyerTaxNo) {
            url += `&buyerTaxNo=${buyerTaxNo}`
        }
        if (sellerTaxNo) {
            url += `&sellerTaxNo=${sellerTaxNo}`
        }
        if (invoiceType) {
            url += `&invoiceType=${invoiceType}`
        }
        if (statusCode) {
            url += `&statusCode=${statusCode}`
        }
        window.open(url, '_blank');
        onClose()
    }

    return (
        <Card title='请选择参数' style={{ marginBottom: '0' }}>
            <Form {...layout} form={form} name="form" layout='horizontal'>
                <Item name='requestDateFromTo' label='申请日期从/至' rules={[{ required: true }]}>
                    <RangePicker format='YYYYMMDD' defaultValue={[DateUtils.monthFirstDay(), DateUtils.monthLastDay()]} />
                </Item>
                <Item name='buyerTaxNo' label='购方税号'><Input /></Item>
                <Item name='sellerTaxNo' label='销方税号'><Input /></Item>
                <Item name='invoiceType' label='发票类型'>
                    <Lookup lookupType='TAX_INVOICE_TYPE' />
                </Item>
                <Item name='statusCode' label='发票状态'>
                    <Lookup lookupType='TAX_OUTPUT_INVOICE_STATUS' />
                </Item>

                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                    <Space>
                        <Button type="primary" onClick={handleExport}>确认导出</Button>
                        <Button onClick={() => onClose()}>取消</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default InvoiceCancel;
