/**
 * 所有 redux action
 */

/**
 * 创建 redux action
 * @param text 描述
 */
function createAction(type: string, text: string) {
    return {
        type: type,
        text: text,
    }
}

// action 值
const F_ACTION_LOGIN = 'ACTION_LOGIN'
const F_ACTION_LOGOUT = 'ACTION_LOGOUT'
const F_ACTION_TOKEN_EXPIRE = 'ACTION_TOKEN_EXPIRE'
const F_ACTION_PROFILE = 'ACTION_PROFILE'
const F_ACTION_SET_PANES = 'ACTION_SET_PANES'

// 用户登陆成功
const ACTION_LOGIN = createAction(F_ACTION_LOGIN, "user login");
// 用户登出
const ACTION_LOGOUT = createAction(F_ACTION_LOGOUT, "user logout");

const ACTION_PROFILE = createAction(F_ACTION_PROFILE, "获取公开配置");

export { F_ACTION_LOGIN, F_ACTION_LOGOUT, F_ACTION_TOKEN_EXPIRE, F_ACTION_PROFILE, F_ACTION_SET_PANES }

export {
    ACTION_LOGIN,
    ACTION_LOGOUT,
    ACTION_PROFILE,
}