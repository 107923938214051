import React, { FC, Fragment, useState } from 'react'
import { Upload as AdUpload, Space, Row, Button } from 'antd'
import { Card, Download, Modal, PermissionButton } from '@comps';
import { Env, Context, Message } from '@utils';
import { RcFile } from 'antd/lib/upload';

interface UploadProps {
    url: string,
    label: string,
    accept?: string,
    isExcel?: boolean,
    // 按钮权限
    btnPermission?: string,
    onFinish?: () => void,
}

const Upload: FC<UploadProps> = ({
    url,
    label,
    accept = '.xlsx,.xls',
    isExcel = true,
    btnPermission,
    onFinish
}) => {
    const [modal, modalHolder] = Modal.useModal()
    let [uploading, setUploading] = useState<boolean>(false)

    const beforeUpload = (file: RcFile, FileList: RcFile[]) => {
        setUploading(true)
        if (isExcel) {
            Message.info('正在处理Excel文件,请耐心等待...')
        } else {
            Message.info('文件上传中,请耐心等待...')
        }
        return true
    }

    return (
        <Fragment>
            {modalHolder}
            <AdUpload action={`${Env.getBaseUrl()}${url}`}
                headers={{ Authorization: Context.getToken() }}
                accept={accept}
                beforeUpload={beforeUpload}
                onChange={async ({ file: { status, response = {} } }) => {
                    console.log(status)
                    if (!['done', 'error'].includes(status as string)) {
                        return;
                    }

                    setUploading(false)

                    const { data = {}, code, msg } = response;
                    if (String(code) !== '200') {
                        Message.error(msg || '上传文件发生异常,请联系管理员排查.');
                        return;
                    }

                    if (isExcel) {
                        const { errorFileUrl, errorSize, hasError, successSize, total } = data;
                        await modal.open({
                            width: '600px',
                            content(_1, reject) {
                                return (<Card title="导入结果" style={{ marginBottom: '0' }}>
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Row justify="center">
                                            {`共 ${total} 条数据, 成功导入 ${successSize} 条, 失败 ${errorSize} 条`}
                                        </Row>

                                        {hasError &&
                                            <Row justify="center">
                                                <Download url={errorFileUrl} label='点击下载失败的导入记录' />
                                            </Row>}

                                        <Row justify="center">
                                            <Button onClick={() => { reject(); }}>关闭</Button>
                                        </Row>
                                    </Space>
                                </Card>);
                            },
                        });

                        if (onFinish) {
                            onFinish()
                        }
                    }
                    return;
                }}
                showUploadList={false}>
                {btnPermission && <PermissionButton permissions={btnPermission} loading={uploading} type='primary'>{label}</PermissionButton>}
                {!btnPermission && <Button loading={uploading} type='primary'>{label}</Button>}

            </AdUpload>
        </Fragment>
    )
}

export default Upload