import { message } from 'antd';

const saveOk = () => {
    message.success('保存成功');
}

const updateOk = () => {
    message.success('更新成功');
}

const submitOk = () => {
    message.success('提交成功');
}

const ok = () => {
    message.success('操作成功');
}

const success = (msg: string, duration: number = 3) => {
  message.success(msg, duration);
}

const warn = (msg: string, duration: number = 3) => {
    message.warn(msg, duration);
}

const info = (msg: string) => {
    message.info(msg);
}

const error = (msg: string) => {
    message.error(msg);
}

const Message = {
    saveOk,
    updateOk,
    submitOk,
    ok,
    success,
    warn,
    info,
    error,
}

export default Message