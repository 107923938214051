import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Button, Space } from 'antd';

import { Card } from '@comps';

import { Http } from '@utils';

interface Props {
  onClose: () => void
};

interface AbnormalInvProps {
  totalNum: number,
  redInkInvoiceNum: number,
  runawayInvoiceNum: number,
  voidInvoiceNum: number,
};

const AbnormalInvRemindMD: FC<Props> = ({
  onClose
}) => {
  
  const [abnormalInvInfo, setAbnormalInvInfo] = useState<AbnormalInvProps>({
    totalNum: 0,
    redInkInvoiceNum: 0,
    runawayInvoiceNum: 0,
    voidInvoiceNum: 0
  });

  useEffect(() => {
    getAbnormalInvInfo()
  }, [])

  const getAbnormalInvInfo = () => {
    Http.get('/input/invPool/getExceInvoicesNum') 
      .then(data => {
        setAbnormalInvInfo(data);
      })
  };

  return (
    <Card title="异常发票提醒">
      <Row justify="space-between" style={{ marginTop: 20 }}>
        <Col span={24}>
          <p>系统检测到您当前存在的异常发票：{abnormalInvInfo.totalNum} 份</p>
        </Col>
        <Col span={24}>
          <p>红冲发票：{abnormalInvInfo.redInkInvoiceNum} 份</p>
        </Col>
        <Col span={24}>
          <p>作废发票：{abnormalInvInfo.voidInvoiceNum} 份</p>
        </Col>
        <Col span={24}>
          <p>失控发票：{abnormalInvInfo.runawayInvoiceNum} 份</p>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Space>
            <Button type="primary" onClick={onClose}>确定</Button>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default AbnormalInvRemindMD;