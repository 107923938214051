export interface RedNoticeNo {
  actionId: number,
  invoiceCode: string,
  invoiceNo: string,
  redNoticeNo?: string,
}

export interface Change {
  id: number,
  requestNo: string,
  statusCode: number,
  statusCodeDesc: string,
  needRedNoticeNo: boolean,
  allowRetry?: boolean,
  redNoticeNos: RedNoticeNo[],
  submitTime: string,
  submitterName: string,
  approverName: string,
  buyerCode:string,
  approveTime: string,
  approveRemarks: string,
  normalStats: {
    amount: number,
    quantity: number,
    taxAmount: number,
    withTaxAmount: number,
  },
  specialStats: {
    amount: number,
    quantity: number,
    taxAmount: number,
    withTaxAmount: number,
  },
  totalStats: {
    amount: number,
    quantity: number,
    taxAmount: number,
    withTaxAmount: number,
  },
}

export enum Status {
  /**
   * 待提交
   */
  SUBMIT = 1,
  /**
   * 审批中
   */
  IN_APPROVAL = 2,
  /**
   * 已拒绝
   */
  REJECTED = 3,
  /**
   * 开票中
   */
  INVOICING = 4,
  /**
   * 改票成功
   */
  SUCCESS = 5,
  /**
   * 已取消
   */
  CANCEL = 6,
}

export interface ChangeLine {
  id: number,
  allowUpdate: boolean,
  allowDelete: boolean,
  allowSplit: boolean,
  quantity: number,
  withTaxAmount: number,
  invoiceType: string,
  groupNo: number,
  confirmed: boolean,
  discountLineFlag: boolean,
  goodsName:string,
  spec:string,
  unit:string,
  taxCode:string,
  // 编辑模式
  _isEdit: boolean,
  // 下划线开头的为临时数据
  _groupNo: number,
  _goodsName:string,
  _spec:string,
  _unit:string,
  _taxCode:string,
  // 用户保存输入数据
  __data: JSONObject,
}

// export interface TableShow {
//   isShowRedNoticeNo: boolean
// }
