import * as React from 'react';
import { Table } from '@comps';
import { ColumnProps } from '@comps/types';

export interface Props extends React.Props<HTMLElement>,
  React.BaseHTMLAttributes<HTMLElement> {
  requestNo: string,
};

const LinesMd: React.FC<Props> = ({ requestNo }: Props) => {
  const columns: ColumnProps[] = [
    { key: 'lineNum', title: '序号', width: 80 },
    { key: 'goodsName', title: '商品名称', width: 200, },
    { key: 'spec', title: '规格型号', width: 120, },
    { key: 'unit', title: '单位', width: 120, },
    { key: 'quantity', title: '数量', width: 100, },
    { key: 'price', title: '单价', format: 'amount', width: 120 },
    { key: 'amount', title: '金额', format: 'amount', width: 150 },
    { key: 'taxRate', title: '税率', width: 120 },
    { key: 'taxAmount', title: '税额', width: 120, format: 'amount' },
  ];
  return <Table
    url={`/tax/outputInvoices/${requestNo}/lines`}
    columns={columns}/>;
};

export default LinesMd;
