import React, { FC, Fragment } from 'react'

import { Input, Form, DatePicker, Button, Space } from 'antd'
import { Page, Card, Table, Lookup, Modal, PermissionButton } from '@comps'
import { Link } from 'react-router-dom'

import BatchPrintMd from './batchPrint.md';

import { ColumnProps } from '@comps/types'
import { DateUtils } from '@utils'

import Export from './export'
import InvoiceCancel from './cancel'
import InvoiceRed from './red'

const Item = Form.Item
const { RangePicker } = DatePicker;

/**
 * 发票列表
 */
const List: FC = () => {
  const [model, modalHolder] = Modal.useModal();
  let [table, setTable] = React.useState<Table>();

  const onTableRef = (tableRef: Table) => {
    table = tableRef
    setTable(tableRef)
  }

  async function handleOnCancel(invoice?: JSONObject) {
    await model.open({
      width: '560px',
      content(resolve, reject) {
        return (<InvoiceCancel onConfirm={resolve} onCancel={reject} invoice={invoice} />);
      },
    });
    table?.refresh();
  }

  async function handleOnRed(invoice?: JSONObject) {
    await model.open({
      width: '560px',
      content(resolve, reject) {
        return (<InvoiceRed onConfirm={resolve} onCancel={reject} invoice={invoice} />);
      },
    });
    table?.refresh();
  }

  /**
   * 发票列表
   */
  const columns: ColumnProps[] = [
    {
      key: 'invoiceNo', title: '发票号码', width: 95, fixed: 'left',
      render: (value, row) => (<Link to={`/tax/outputInvoice/${row.id}/detail`}>{value}</Link>)
    },
    { key: 'invoiceCode', title: '发票代码', width: 120, },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 90, },
    { key: 'invoiceDate', title: '开票日期', width: 90, },
    { key: 'buyerName', title: '购买方', width: 200, },
    { key: 'invoiceAmount', title: '不含税金额', width: 110, format: 'amount' },
    { key: 'taxAmount', title: '税额', width: 110, format: 'amount' },
    { key: 'totalAmount', title: '价税合计', width: 110, format: 'amount' },
    { key: 'requestDate', title: '申请日期', width: 100, },
    { key: 'sellerName', title: '销售方', width: 320, },
    { key: 'invoicer', title: '开票人', width: 100, },
    { key: 'machineNo', title: '分机号', width: 60 },
    { key: 'creationTime', title: '创建时间', width: 180 },
    { key: 'statusCodeDesc', title: '状态', width: 90, fixed: 'right', },
    {
      key: 'id',
      title: '操作',
      width: 160,
      fixed: 'right',
      render: (value, row, index) => (
        <Fragment>
          <Link to={`/tax/outputInvoice/${row.id}/detail`}>详情</Link>
          {row.allowCancel && <PermissionButton type='link' permissions="OutputInvoice.cancel" clickCallback={() => handleOnCancel(row)}>作废</PermissionButton>}
          {row.allowRed && <PermissionButton type='link' permissions="OutputInvoice.createRedInvoice" clickCallback={() => handleOnRed(row)}>红冲</PermissionButton>}
        </Fragment>),
    },
  ]

  async function handleExport() {
    await model.open({
      width: '560px',
      content(resolve, reject) {
        return (<Export onClose={reject} />);
      },
    });
    table?.refresh();
  }

  const getIds = React.useCallback(() => {
    return table?.getSelectedRowKeys();
  }, [table]);

  return (
    <Page>
      <Card title='发票管理' actions={
        <Space>
          <BatchPrintMd getIds={getIds}
                        permissions="Order.batchPrint"
                        onTimerEnd={() => {table?.refresh()}}/>
          <Button type='primary' onClick={() => handleExport()}>导出</Button>
        </Space>
      }>
        {modalHolder}
        <Table url='/tax/outputInvoices?func=INVOICE' columns={columns} pagination={{ hideOnSinglePage: false }}
          selectedRowKeys={[]} onTableRef={onTableRef}>
          <Input name='invoiceNo' placeholder='发票号码' />
          <Input name='buyerTaxNo' placeholder='购方税号' />
          <Item name='statusCode'>
            <Lookup lookupType='TAX_OUTPUT_INVOICE_STATUS' placeholder="发票状态" />
          </Item>
          <Item name='requestDateFromTo' >
            <RangePicker placeholder={['申请日期从', '申请日期至']} format='YYYYMMDD' defaultValue={[DateUtils.monthFirstDay(), DateUtils.monthLastDay()]} />
          </Item>
          <Item name='invoiceType'>
            <Lookup lookupType='TAX_INVOICE_TYPE' placeholder="发票类型" />
          </Item>
        </Table>
      </Card>
    </Page>
  )
}

export default List;
