import React, { Component, FC } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

interface Props {
  label?: string,
}

/**
 * 返回按钮
 */
class BackButton extends Component<Props> {

  static propTypes = {
    label: PropTypes.string,
  }

  render() {
    return (
      <Button onClick={() => { window.history.back() }}>{this.props.label ? this.props.label : '返回'}</Button>
    )
  }
}

interface ButtonProps {
  label: string,
  onClick?: React.MouseEventHandler<HTMLElement>;
}

/** Link 类型文本 */
const LinkText: FC<ButtonProps> = (props) => {
  return (<span className='btn-text' onClick={props.onClick}>{props.label}</span>)
}

export { BackButton, LinkText }
