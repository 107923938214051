interface Location {
  hash: string,
  host: string,
  hostname: string,
  origin: string,
  href: string,
  pathname: string,
  port: string,
  protocol: string,
  search: string,
}

function parse(url: string): Optional<Location> {
  const regex = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
  const result = regex.exec(url);
  if (!result) {
    return undefined;
  }
  const names = ['url', 'scheme', 'slash', 'host', 'port', 'path', 'query', 'hash'];
  const info: JSONObject = {};
  for (let i = 0; i < names.length; i += 1) {
    info[names[i]] = result[i];
  }
  return {
    hash: info.hash ? `#${info.hash}` : '',
    host: info.host,
    hostname: info.host,
    origin: `${info.scheme}:${info.slash}${info.host}${
      info.port ? `:${info.port}` : ''
    }`,
    href: info.url,
    pathname: info.path ? `/${info.path}` : '',
    port: info.port || '',
    protocol: `${info.scheme}:`,
    search: info.query ? `?${info.query}` : '',
  };
}

/**
 * 下载文件.
 * 不会打开新的窗口或者标签页.
 * 请注意后台返回不会被浏览器默认打开的MIME类型.
 * @param {string} url 地址
 * @param {object} data ={}, 需要修改的数字
 * @param {string} method =get, 请求方式
 */
export default function (url: string, data: JSONObject = {}, method: string = 'get'): void {
  const { search } = parse(url) || {};
  if (search) {
    const _data = data;
    const query = search.slice(1);
    if (query) {
      const queryItems = query.split('&');
      queryItems.forEach((queryItem) => {
        const [key, value] = queryItem.split('=');
        if (key && value) {
          _data[key] = value;
        }
      });
    }
  }

  const $form = document.createElement('form');
  $form.setAttribute('method', method);
  $form.setAttribute('action', url);
  Object.keys(data).forEach((key) => {
    const $input = document.createElement('input');
    $input.setAttribute('type', 'hidden');
    $input.setAttribute('name', key);
    $input.setAttribute('value', data[key]);
    $form.appendChild($input);
  });
  document.body.appendChild($form);
  $form.addEventListener('submit', (e) => {
    e.preventDefault();
  });
  $form.submit();
  document.body.removeChild($form);
}
