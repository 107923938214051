import * as React from 'react';
import { Space, Form, Row, Col } from 'antd';
import { Card, Button, DisplayInput } from '@comps'
import { Http, Message } from '@utils'

/**
 * 发票作废
 */
const Item = Form.Item
const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
};

export interface Props {
    onCancel: () => void,
    onConfirm: () => void,
    invoice?: JSONObject,
}

const InvoiceCancel: React.FC<Props> = ({
    onCancel, onConfirm, invoice = {},
}) => {
    const [form] = Form.useForm();
    form.setFieldsValue(invoice)

    async function handleSave() {
        await Http.post(`/tax/outputInvoices/cancel?requestNo=${invoice.requestNo}`);
        Message.success('作废成功')
        onConfirm();
    }

    return (
        <Card title='您确认要作废发票吗？' style={{ marginBottom: '0' }}>
            <Form {...layout} form={form} name="cancel" initialValues={{}}>
                <Row>
                    <Col span={11} ><Item label="发票代码：" name='invoiceCode'><DisplayInput /></Item></Col>
                    <Col span={11} offset={1}><Item label="发票号码：" name='invoiceNo'><DisplayInput /></Item></Col>
                </Row>
                <Row>
                    <Col span={11} ><Item label="发票金额：" name='totalAmount'><DisplayInput /></Item></Col>
                    <Col span={11} offset={1}><Item label="开票日期：" name='invoiceDate'><DisplayInput /></Item></Col>
                </Row>

                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                    <Space>
                        <Button type="primary" clickCallback={handleSave}>确认作废</Button>
                        <Button onClick={onCancel}>取消</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default InvoiceCancel;
