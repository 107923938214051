import { RouteProps } from '@models'

import RegisterList from './register'
import RegisterInvDetail from './register/detail';
import QuerylList from './query';
import QueryInvDetail from './query/detail';
import ArchivesList from './archives';
import ArchivesInvDetail from './archives/detail';

const incomeRoutes: RouteProps[] = [
  { path: '/income/register', component: RegisterList, private: true },
  { path: '/income/register/:id/detail', component: RegisterInvDetail, private: true, isCancelCache: true },
  { path: '/income/query', component: QuerylList, private: true },
  { path: '/income/query/:id/detail', component: QueryInvDetail, private: true, isCancelCache: true },
  { path: '/income/archives', component: ArchivesList, private: true },
  { path: '/income/archives/:id/detail', component: ArchivesInvDetail, private: true, isCancelCache: true },
];

export {
  incomeRoutes
};
