import { RouteProps } from '@models';

import { TenantList } from './tenant';
import { UserList, UserAdd, UserEdit, UserProfile } from './user';
import { FunctionList, FunctionEdit } from './function';
import { CodeRuleList, CodeRuleEdit } from './codeRule';
import { MenuList, RoleMenu } from './menu';
import { RoleList } from './role';
import { ApiLogList } from './apiLog';
import { AppLogList } from './appLog';
import { PermissionList } from './permission';
import { Dept } from './dept';
import { BucketList, FileList } from './cms';
import { GlobalProfile } from './profile';
import { EmployeeList } from './employee';
import { AsyncTasks } from './asyncTask';
import { AsyncTaskDetail } from './asyncTask/detail';
import messageRoutes from './message/routes';

const sysRoutes: RouteProps[] = [
  { path: '/', component: UserProfile, private: true },
  { path: '/sys/tenants', component: TenantList, private: true },
  { path: '/sys/users', component: UserList, private: true },
  { path: '/sys/users/add', component: UserAdd, private: true },
  { path: '/sys/users/:id/edit', component: UserEdit, private: true, isCancelCache: true },
  { path: '/sys/users/profile', component: UserProfile, private: true },

  { path: '/sys/functions', component: FunctionList, private: true },
  { path: '/sys/functions/:id/edit', component: FunctionEdit, private: true },

  { path: '/sys/codeRules', component: CodeRuleList, private: true },
  { path: '/sys/codeRules/:id/edit', component: CodeRuleEdit, private: true },

  { path: '/sys/menus', component: MenuList, private: true },
  { path: '/sys/menus/:menuId/roles/:roleId', component: RoleMenu, private: true },

  { path: '/sys/roles', component: RoleList, private: true },

  { path: '/sys/apiLogs', component: ApiLogList, private: true },
  { path: '/sys/appLogs', component: AppLogList, private: true },

  { path: '/sys/permissions', component: PermissionList, private: true },

  { path: '/hr/depts', component: Dept, private: true },
  { path: '/hr/employees', component: EmployeeList, private: true },

  { path: '/cms/buckets', component: BucketList, private: true },
  { path: '/cms/buckets/:id/files', component: FileList, private: true },

  { path: '/sys/profiles/global', component: GlobalProfile, private: true },

  { path: '/sys/asyncTasks', component: AsyncTasks, private: true },
  { path: '/sys/asyncTasks/:id', component: AsyncTaskDetail, private: true },

  ...messageRoutes
];

export {
  sysRoutes
};
