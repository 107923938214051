import React, { FC, useEffect, useState } from 'react';
import { Form, Row, Col, Button, DatePicker, Space, Input, InputNumber } from 'antd';
import moment from 'moment';

import { Card, HiddenFormItem } from '@comps';

import { Http, Message, DateUtils } from '@utils';

const { Item } = Form;

interface Props {
  rowData: JSONObject,
  refreshTable: () => void,
  onClose: () => void
};

const EditRowMD: FC<Props> = ({
  rowData,
  refreshTable,
  onClose
}) => {

  const [form] = Form.useForm();

  const [editLoading, setEditLoading] = useState(false);

  const checkIsGeneralTicket = (ticketCode: string) => {
    const generalTicketCodes = ['04', '10'];

    return generalTicketCodes.includes(ticketCode);
  };

  useEffect(() => {
    const invoiceDate = moment(rowData.invoiceDate + '');
    form.setFieldsValue({ ...rowData, invoiceDate });
  }, []);

  const handleEdit = () => {
    setEditLoading(true);

    const formData = form.getFieldsValue();
    const { invoiceDate } = formData;
    
    formData.invoiceDate = DateUtils.momentToNum(invoiceDate, 'YYYYMMDD');

    Http.post('/input/invoices/update', formData)
      .then(() => {
        refreshTable();
        onClose();
        Message.success('编辑成功');
      })
      .finally(() => {
        setEditLoading(false);
      })
  }

  return (
    <Card title="编辑">
      <Form 
        form={form} 
        labelCol={{ span: 6 }}
        onFinish={handleEdit}
      >
        <Row>
          <Col span={24}>
            <HiddenFormItem name="id" />
            <Item 
              label="发票代码" 
              name="invoiceCode"
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="发票号码" 
              name="invoiceNo"
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {checkIsGeneralTicket(rowData.invoiceType) 
              ? <Item 
                  label="校验码（6 位）" 
                  name='checkCode'
                  rules={[{ 
                    required: true, 
                    pattern: new RegExp(/\d{6}/),
                    message: '请输入 6 位校验码', 
                  }]}
                >
                  <Input 
                    minLength={6} 
                    maxLength={6} 
                  /> 
                </Item>
              : <Item 
                  label="不含税金额" 
                  name="amount"
                  rules={[{ required: true }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Item>
            }
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="开票日期" 
              name="invoiceDate"
              rules={[{ required: true }]}
            >
              <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
           <Space>
             <Button 
               type="primary" 
               loading={editLoading}
               htmlType="submit"
             >
               确定
              </Button>
             <Button onClick={onClose}>取消</Button>
           </Space>
          </Col>
        </Row>

      </Form>
    </Card>
  )
};

export default EditRowMD;