import { OrgList, OrgDetail } from './org'
import { CustomerList, CustomerDetail } from './customer'
import { RouteProps } from '@models'

const orgRoutes: RouteProps[] = [
  { path: '/org/customers', component: CustomerList, private: true },
  { path: '/org/orgs', component: OrgList, private: true },
  { path: '/org/orgs/:id', component: OrgDetail, private: true },
  { path: '/org/customers/:id', component: CustomerDetail, private: true },
]

export {
  orgRoutes
};