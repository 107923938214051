import React, { FC, useState, useEffect } from 'react';

import { Http, Message, Context } from '@utils';
import { Tree, Row, Col } from 'antd';
import { Card } from '@comps';
import Button from '@comps/Button';

export interface Props {
    onCancel: () => void,
    onConfirm: () => void,
}

const DeptTree: FC<Props> = ({
    onCancel, onConfirm
}) => {
    let [treeData, setTreeData] = useState<[]>()
    const [selectedDeptId, setSelectedDeptId] = useState(-1)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        Http.get('/hr/depts/deptTreeForSwitchDept/antd').then((childrens: any) => {
            setTreeData(childrens);
        })
    }, [])

    async function handleSave() {
        if (selectedDeptId < 0) {
            Message.warn("请选择要切换的部门")
        } else {
            setLoading(true)
            await Http.get(`/sys/users/switchDept?deptId=${selectedDeptId}`)
            await Context.initUserContext()
            Message.success("切换成功")
            setLoading(false)
            onConfirm()
            // 清除路由缓存
            window.location.reload()
        }
    }

    const onSelect = (selectedKeys: any[], info: any) => {
        let selected = info.selected
        if (selected) {
            let node = info.node
            let deptId = node.key
            setSelectedDeptId(deptId)
        } else {
            setSelectedDeptId(-1)
        }
    }

    return (
        <Card title='切换部门' loading={loading}>
            <Row>{treeData && <Tree
                treeData={treeData}
                showLine
                defaultExpandAll
                height={600}
                style={{ width: 476 }}
                onSelect={onSelect}
            />}

            </Row>
            <Row>
                <Col offset={10} span={10}>
                    <Button type="primary" clickCallback={handleSave}>确认切换</Button>
                </Col>
            </Row>
        </Card>
    )
}

export default DeptTree
