import React, { useEffect, useState } from 'react';
import { Space, Form, Input, Select, InputNumber, Switch } from 'antd';
import { Card, Button, Lookup } from '@comps'
import { Http, Message, Api } from '@utils'

const Item = Form.Item

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

export interface Props {
    onCancel: () => void,
    onConfirm: () => void,
    agent?: JSONObject,
}

const Add: React.FC<Props> = ({
    onCancel, onConfirm, agent = {},
}) => {
    const [orgs, setOrgs] = useState<any>()
    const [form] = Form.useForm();

    useEffect(() => {
        Api.getKv('/org/orgs/kv').then(data => {
            setOrgs(data)
        })
    }, [])

    async function handleSave() {
        await form.validateFields()
        const submitData = {
            id: agent?.id,
            ...form.getFieldsValue()
        };
        if (submitData.id) {
            await Http.put('/tax/agents', submitData);
        } else {
            await Http.post('/tax/agents', submitData);
        }
        Message.ok();
        onConfirm();
    }

    return (
        <Card title='税盘管理' style={{ marginBottom: '0' }}>
            <Form {...layout} form={form} initialValues={agent}>
                <Item label="企业名称" name='orgId' rules={[{ required: true }]}>
                  <Select 
                    showSearch 
                    optionFilterProp="label" 
                    options={orgs} 
                  />
                </Item>
                <Item label="税盘类型" name='agentType' rules={[{ required: true }]}>
                    <Lookup lookupType='TAX_AGENT_TYPE' placeholder="发票类型" />
                </Item>
                <Item label="分机号" name='machineNo' rules={[{ required: true }]} ><InputNumber disabled={agent?.id ? true : false} /></Item>
                <Item label="税控终端编号" name='agentNo' rules={[{ required: true }]}><Input /></Item>
                <Item label="可用于扫码开票" name='allowSmkp' valuePropName="checked" rules={[{ required: true }]}>
                    <Switch />
                </Item>
                <Item label="EndPoint" name='endpoint' rules={[{ required: true }]}><Input /></Item>
                <Item label="税盘密码" name='certPassword' rules={[{ required: true }]}><Input type='password' /></Item>
                <Item label="设备类型" name='agentPhysicalType' rules={[{ required: true }]}>
                    <Lookup lookupType='TAX_AGENT_DEVICE_TYPE' />
                </Item>
                <Item label="签章机制" name='signatureMechanism' rules={[{ required: true }]}>
                   <Lookup lookupType='SIGNATURE_MECHANISM' />
                </Item>
                <div style={{ textAlign: 'center' }}>
                    <Space>
                        <Button type="primary" clickCallback={handleSave}>保存</Button>
                        <Button onClick={onCancel}>取消</Button>
                    </Space>
                </div>
            </Form>
        </Card>
    );
};

export default Add;
