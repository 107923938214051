import React from 'react';
import { Space, Form, Input, Checkbox } from 'antd';

import { Card, Button } from '@comps';

import { Http, Message } from '@utils';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 }
};

export interface Props {
  onCancel: () => void,
  onConfirm: () => void,
  title?: JSONObject
};

const Add: React.FC<Props> = ({
  onCancel,
  onConfirm,
  title = {},
}) => {

  const [form] = Form.useForm();

  const handleSave = async () => {
    await form.validateFields();

    const submitData = {
      ...title,
      ...form.getFieldsValue()
    };

    if (submitData.id) {
      await Http.put('/org/customers/invoiceTitles', submitData);
    } else {
      await Http.post('/org/customers/invoiceTitles', submitData);
    }

    Message.ok();
    onConfirm();
  }

  return (
    <Card title='发票抬头' style={{ marginBottom: '0' }}>
      <Form {...layout} form={form} initialValues={title}>
        <Item label="开户行" name='bankName' rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item label="银行账号" name='accountNo' rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item label="地址" name='address' >
          <Input />
        </Item>
        <Item label="电话" name='tel'>
          <Input />
        </Item>
        <Item
          label="默认抬头"
          name='defaultFlag'
          valuePropName='checked'
        >
          <Checkbox />
        </Item>
        <div style={{ textAlign: 'center' }}>
          <Space>
            <Button type="primary" clickCallback={handleSave}>保存</Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default Add;
