import React, { FC } from 'react';
import { Input, Form, DatePicker, Space, message, Table as AdTable, Row, Col } from 'antd';
import { Page, Card, Table, Lookup, LinkText, Modal, Button, PermissionButton } from '@comps';
import { ColumnProps } from '@comps/types';
import { DateUtils, Lang, Http, Math } from '@utils';
import { Link } from 'react-router-dom';
import Source from './source.md';
import Sync from './sync.md';
import BatchDelete from './batchDelete.md';
import BatchPrintMd from '../batchPrint.md';
import UpdateInvoiceType from './updateInvoiceType.md';

export interface Prompt {
  finished: boolean,
  messages: {
    title: string,
    content: string,
  }[],
}

const Item = Form.Item
const { RangePicker } = DatePicker;

/**
 * 待开票记录
 */
const DocManage: FC = () => {
  let [table, setTable] = React.useState<Table>()
  const [model, modalHolder] = Modal.useModal();
  const [batchInvoiceLoading, setBatchInvoiceLoading] = React.useState<boolean>(false);
  const timerRef = React.useRef<number>(0);

  React.useEffect(() => {
    return () => {
      if (timerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const startTimer = React.useCallback((batchNo) => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    const _timerId = Lang.timer(async () => {
      const {
        finished = false,
      } = await Http.get<Prompt>(`/tax/orders/batchInvoiceStatus?batchNo=${batchNo}`);
      table?.refresh();
      if (finished) {
        setBatchInvoiceLoading(false);
        clearInterval(_timerId);
        timerRef.current = 0;
      }
    }, 2000);
  }, [table]);

  async function showSource(bill: JSONObject) {
    await model.open({
      width: '1150px',
      content() {
        return (<Source invoiceId={bill.id} />);
      },
    });
  }

  const onTableRef = (tableRef: Table) => {
    setTable(tableRef)
    table = tableRef
  }

  const handleSync = React.useCallback(() => Lang.noError(async () => {
    await model.open({
      width: '1150px',
      ignoreError: false,
      content(resolve, reject) {
        return (<Sync onClose={reject} onConfirm={resolve} />);
      },
    });
    table?.refresh();
  }), [model, table]);

  const handleBatchInvoice = React.useCallback(async () => {
    setBatchInvoiceLoading(true);
    try {
      const ids = table?.getSelectedRowKeys();
      const batchNo = await Http.post('/tax/orders/batchInvoice', {
        ids,
      });
      startTimer(batchNo);
    } catch (error) {
      setBatchInvoiceLoading(false);
      console.error(error);
    }
  }, [table, startTimer]);

  const handleDelete = React.useCallback(async () => {
    const ids = table?.getSelectedRowKeys();
    await Http.post('/tax/orders/batchDelete', {
      ids,
    });
    message.success('删除成功');
    table?.refresh();
  }, [table]);

  const handleBatchDelete = React.useCallback(() => Lang.noError(async () => {
    await model.open({
      width: '800px',
      ignoreError: false,
      content(resolve, reject) {
        return (<BatchDelete onClose={reject} onConfirm={resolve} />);
      },
    });
    table?.refresh();
  }), [model, table]);

  const handleUpdateInvoiceType = React.useCallback(() => Lang.noError(async () => {
    const ids = table?.getSelectedRowKeys() || [];
    if (!ids.length) {
      message.warn('请至少选择一行');
      return;
    }
    await model.open({
      width: '800px',
      content(resolve) {
        return (<UpdateInvoiceType ids={ids} onClose={resolve} />);
      },
    });
    table?.refresh();
  }), [model, table]);

  const handleBatchUpdateSeller = React.useCallback(() => Lang.noError(async () => {
    const ids = table?.getSelectedRowKeys();
    await Http.put('/tax/outputInvoices/batchUpdateSeller', {
      ids,
    });
    message.success('更新成功');
    table?.refresh();
  }), [table]);

  const columns: ColumnProps[] = [
    {
      key: 'bizNo', title: '单据编号', width: 260,
      render: (value, row) => (
        <>
          {row.statusCode === 1 && <Link to={`/tax/outputInvoice/wait/${row.requestNo}/detail`}>{value}</Link>}
          {row.statusCode !== 1 && <Link to={`/tax/outputInvoice/${row.requestNo}/detail`}>{value}</Link>}
        </>)
    },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 90 },
    { key: 'requestDate', title: '单据日期', width: 100 },
    { key: 'buyerName', title: '客户名称', width: 200 },
    { key: 'invoiceAmount', title: '不含税金额', format: 'amount', width: 120 },
    { key: 'taxAmount', title: '税额', format: 'amount', width: 120 },
    { key: 'totalAmount', title: '含税金额', format: 'amount' },
    // { key: 'batchNo', title: '批次号', width: 180, },
    // { key: 'employeeName', title: '业务员', width: 100, },
    { key: 'remark', title: '备注', width: 200 },
    { key: 'buyerAddrTel', title: '地址电话', width: 200 },
    { key: 'buyerBank', title: '银行账号', width: 200 },
    { key: 'sellerName', title: '销方名称', width: 320 },
    { key: 'buyerTaxNo', title: '客户税号', width: 200 },
    { key: 'machineNo', title: '分机号', width: 60 },
    { key: 'generateWayDesc', title: '单据来源', width: 100 },
    { key: 'creationTime', title: '创建时间', width: 180 },
    { key: 'statusCodeDesc', title: '发票状态', width: 100,fixed:"right" },
    {
      key: 'id',
      title: '操作',
      width: 100,
      fixed: 'right',
      render: (value, row, index) => (
        <Space>
          <Link to={`/tax/outputInvoice/${row.id}/detail`}>详情</Link>
          {
            row?.allowUpdate 
            ? <Link to={`/tax/outputInvoice/wait/${row.requestNo}/detail`} target='_blank'>修改</Link>
            : null
          }
        </Space>)
    }
    // { key: 'orderStatusDesc', title: '单据状态', width: 100, fixed:"right" },
    // {
    //   key: 'requestNo',
    //   title: '操作',
    //   width: 120,
    //   fixed: 'right',
    //   render: (value, bill: JSONObject) => (
    //     <Space>
    //       {bill?.allowUpdate ? (
    //         <Link to={`/tax/outputInvoice/wait/${value}/detail`} target='_blank'>修改</Link>
    //       ) : undefined}
    //       <LinkText onClick={() => showSource(bill)}
    //                 label='来源' />
    //     </Space>
    //   ),
    // },
  ]

  const getIds = React.useCallback(() => {
    return table?.getSelectedRowKeys();
  }, [table]);

  return (
    <Page>
      {modalHolder}
      <Card title='单据管理' actions={(
        <Space>
          <Button type="primary"
            loading={batchInvoiceLoading}
            onClick={handleBatchInvoice}>批量开发票</Button>
          <BatchPrintMd getIds={getIds}
            permissions="Order.batchPrint"
            onTimerEnd={() => {
              table?.refresh();
            }}/>
          <Button type="primary"
            clickCallback={handleDelete}>批量删除</Button>
          <PermissionButton type="primary"
            permissions={'Order.delByBatchNo'}
            onClick={handleBatchDelete}>按批次删除</PermissionButton>
          {/*<PermissionButton type="primary"*/}
          {/*  permissions={'OutputInvoice.batchUpdateSeller'}*/}
          {/*  clickCallback={handleBatchUpdateSeller}>更新销方信息</PermissionButton>*/}
          {/*<PermissionButton type="primary"*/}
          {/*  permissions={'OutputInvoice.batchUpdateInvType'}*/}
          {/*  clickCallback={handleUpdateInvoiceType}>更新发票类型</PermissionButton>*/}
        </Space>
      )}>
        <Table url='/tax/orders?'
          onTableRef={onTableRef}
          selectedRowKeys={[]}
          pagination={{hideOnSinglePage:false}}
          columns={columns}
          summary={(invoices, selectIds) => {
            const selectInvoices = invoices.filter((invoice => selectIds?.includes(invoice.id)));
            const invoiceTotal = selectInvoices.reduce((total, invoice) => {
              total.invoiceAmount += invoice.invoiceAmount;
              total.taxAmount += invoice.taxAmount;
              total.totalAmount += invoice.totalAmount;
              return total;
            }, {
              invoiceAmount: 0,
              taxAmount: 0,
              totalAmount: 0,
            });
            return (<AdTable.Summary.Row style={{ position: 'relative'}}>
              <AdTable.Summary.Cell index={0}>
                <span style={{ visibility: 'hidden'}}>看不见</span>
              </AdTable.Summary.Cell>
              <Row gutter={14}
                style={{ position: 'absolute', bottom: '25px', left: '10px', right: '0' }}>
                <Col span={6}>
                  选中数量: {selectIds?.length || 0}
                </Col>
                <Col span={6}>
                  选中不含税金额: { Math.num2money(invoiceTotal.invoiceAmount) }
                </Col>
                <Col span={6}>
                  选中税额: { Math.num2money(invoiceTotal.taxAmount) }
                </Col>
                <Col span={6}>
                  选中含税金额: { Math.num2money(invoiceTotal.totalAmount) }
                </Col>
              </Row>
            </AdTable.Summary.Row>)
          }}>
          <Item name='requestDateFromTo' >
            <RangePicker placeholder={['单据日期从', '单据日期至']} format='YYYYMMDD'
              defaultValue={[DateUtils.monthFirstDay(), DateUtils.monthLastDay()]} />
          </Item>
          <Item name='invoiceType'>
            <Lookup lookupType='TAX_INVOICE_TYPE' placeholder="发票类型" />
          </Item>
          <Item name='statusCode'>
            <Lookup lookupType='TAX_ORDER_STATUS' placeholder="单据状态" />
          </Item>
          <Item name='buyerName'>
            <Input placeholder="客户名称" />
          </Item>
          {/*<Item name='employeeName'>*/}
          {/*  <Input placeholder="业务员" />*/}
          {/*</Item>*/}
          <Item name='invoiceNo'>
            <Input placeholder="发票号码" />
          </Item>
          <Item name='orderNo'>
            <Input placeholder="单据编号" />
          </Item>
        </Table>
      </Card>
    </Page>
  )
}

export default DocManage;
