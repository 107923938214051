import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Form, Select, Radio, message, Space, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { dropByCacheKey } from 'react-router-cache-route';

import { Card, Page, DisplayInput, BackTag } from '@comps';

import { Http, Context, Api } from '@utils';

import { FormProps } from 'antd/lib/form';

const { Item } = Form;
const { confirm } = Modal;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 }
};

const validateMessages = {
  // eslint-disable-next-line
  required: '请输入 ${label}',
  types: {
    email: '请输入正确的邮箱',
  }
};

interface State {
  id: any,
  loading: boolean,
  userName: string,
};

interface Props extends FormProps {
  history: any,
  match: any,
};

const Edit: FC = (props: any) => {
  const id = props.match.params.id;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState<[]>([]);
  const [billingVis, setBillingVis] = useState<boolean>(true)

  useEffect(() => {
    getSelectOptions();
    setFormVal();
  }, [id]);

  const getTicket = () => {
    Http.get("/org/orgs/deptMasterNode").then(res => {
      form?.setFieldsValue({billingVendorType: res})
      if (res) {
        setBillingVis(true)
      } else {
        setBillingVis(false)
      }
    })
  }

  const getSelectOptions = () => {
    Api.getKv('/sys/roles/kv')
      .then((data: any) => {
        setRoleOptions(data);
      });
  };

  const setFormVal = () => {
    Http.get(`/sys/users/${id}`)
      .then((data: any) => {
        form?.setFieldsValue({ ...data });
        getTicket()
      });
  };

  const getUrl = (msg: string, url: string) => {
    // Http.get("/tax/outputInvoices/getBillingUrl").then(res => {
      confirm({
        title: msg,
        icon: <ExclamationCircleOutlined />,
        okText: '去绑定',
        onOk() {
          window.open(url, "_blank")
          dropByCacheKey('/sys/users');
          Context.route('/sys/users');
        },
        onCancel() {
          dropByCacheKey('/sys/users');
          Context.route('/sys/users');
        },
      });
    // }).catch(() => {
    //   dropByCacheKey('/sys/users');
    //   Context.route('/sys/users');
    // })
  }

  const handOnSave = () => {
    form.validateFields()
      .then(values => {
        setLoading(true);
        const { billingUserName, billingPassword } = values || {}
        if ((!billingUserName && billingPassword) || (billingUserName && !billingPassword)) {
          message.warning("账号密码需同时填写")
          setLoading(false);
          return
        }

        Http.put('/sys/users', { ...values, id })
          .then((res: JSONObject) => {
            message.info('保存成功');
            if (res.url) {
              getUrl(res.message, res.url)
            } else {
              dropByCacheKey('/sys/users');
              Context.route('/sys/users');
            }
          })
          .finally(() => {
            setLoading(false);
          });
      });
  };

  return (
    <Page backable>
      <Card title='编辑用户' loading={loading}>
        <Form
          {...layout}
          name="form-user-edit"
          form={form}
          initialValues={{ status: 'ACTIVE' }}
          validateMessages={validateMessages}
        >
          <Item label="用户名" name='userName'>
            <DisplayInput />
          </Item>
          <Item name='lastName' label='姓名'>
            <Input />
          </Item>
          <Item
            name='emailAddress'
            label='邮箱'
            rules={[{ type: 'email' }]}
          >
            <Input />
          </Item>
          <Item name='mailAddress' label='邮寄地址'>
            <Input />
          </Item>
          <Item name='mobile' label='手机号'>
            <Input />
          </Item>
          <Item name='gender' label='性别'>
            <Radio.Group >
              <Radio value='M'>男</Radio>
              <Radio value='W'>女</Radio>
            </Radio.Group>
          </Item>
          <Item name='status' label='状态'>
            <Radio.Group>
              <Radio value='ACTIVE'>有效</Radio>
              <Radio value='INACTIVE'>已失效</Radio>
              <Radio value='FROZEN'>冻结</Radio>
            </Radio.Group>
          </Item>
          <Item name='roleIds' label='角色'>
            <Select showSearch mode="multiple" options={roleOptions} />
          </Item>
          {billingVis && (<div>
            <Item name='billingVendorType' label="开票厂商">
              <DisplayInput />
            </Item>
            <Item name='billingUserName' label='账户' rules={[{ pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的手机号' }]}>
              <Input />
            </Item>
            <Item name='billingPassword' label='密码'>
              <Input />
            </Item>
          </div>)}
          <Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Space size="large">
              <Button type="primary" onClick={handOnSave}>保存</Button>
            </Space>
          </Item>
        </Form>
      </Card>
    </Page>
  );
};

export default Edit;
