import React, { FC, useState } from 'react';
import { Input, Form, Space, DatePicker, Row, Col } from 'antd';
import XMLViewer from 'react-xml-viewer';

import { Page, Card, Table, Modal, JsonEditor, LinkText, DisplayInput, Download } from '@comps';

import { Http } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;

const ApiLogList: FC = () => {
  const [form] = Form.useForm();
  const [detail, setDetail] = useState<JSONObject>({});

  const [isXML, setIsXML] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const [requestBody, setRequestBody] = useState({});
  const [responseBody, setResponseBody] = useState({});
  
  const [XMLRequestBody, setXMLRequestBody] = useState('');
  const [XMLResponseBody, setXMLResponseBody] = useState('');

  const customTheme = {
    attributeKeyColor: '#f00',
    attributeValueColor: '#000',
    overflowBreak: true
  };

  const viewDetail = async (id: number) => {
    setModalVisible(true);

    const row = await Http.get(`/sys/apiLogs/${id}`);
    const { requestBody = '{}', responseBody = '{}' } = row;
    
    const _isXML = (requestBody[0] === '<') && (responseBody[0] === '<');
    if (_isXML) {
      setXMLRequestBody(requestBody);
      setXMLResponseBody(responseBody);
    } else {
      setRequestBody(JSON.parse(requestBody));
      setResponseBody(JSON.parse(responseBody));
    };
    
    form.setFieldsValue({ ...row })
    setDetail(row);
    setIsXML(_isXML);
  };

  const columns: ColumnProps[] = [
    {
      title: '接口名称',
      key: "permissionName",
      width: 200,
      fixed: true,
    },
    {
      title: '方向',
      key: "direction",
      width: 80,
      render: (value) => ('IN' === value ? '入站' : '出站')
    },
    {
      title: '外部系统',
      key: "thirdSystemName",
      width: 170,
    },
    {
      title: '请求时间',
      key: 'requestTime',
      format: 'datetime',
    },
    {
      title: '响应时间',
      key: 'responseTime',
      format: 'datetime',
    },
    {
      title: '时间消耗',
      key: 'costTime',
      width: 100,
    },
    {
      title: '用户名',
      key: 'userName',
    },
    {
      title: '用户姓名',
      key: 'fullName',
      width: 100,
    },
    {
      title: '用户角色',
      key: 'roleName',
      width: 100,
    },
    {
      title: '返回信息',
      key: "message",
      width: 300,
    },
    {
      title: 'Content Type',
      key: 'contentType',
      width: 220,
    },
    {
      title: '请求方式',
      key: 'method',
      filterKey: "method",
      filters: [
        { text: 'GET', value: 'GET' },
        { text: 'DELETE', value: 'DELETE' },
        { text: 'POST', value: 'POST' },
        { text: 'PUT', value: 'PUT' }
      ],
      tag: { 
        colors: { 
          'GET': 'green', 
          'DELETE': 'red', 
          'POST': 'orange', 
          'PUT': 'blue' 
        } 
      }
    },
    {
      title: 'IP地址',
      key: 'remoteAddr',
    },

    {
      title: 'URI',
      key: "requestUri",
      width: 200,
    },
    {
      title: '响应状态',
      key: 'responseStatus',
      width: 250,
    },
    {
      title: '请求ID',
      key: "id",
      width: 180,
    },
    {
      title: '操作',
      key: 'id',
      fixed: 'right',
      width: 100,
      render: (id: number, row: any) => (
        <LinkText label='详情' onClick={() => viewDetail(id)} />
      )
    }
  ];

  return (
    <Page>
      <Card title="接口日志" >
        <Table 
          url="/sys/apiLogs" 
          columns={columns}
        >
          <Item name="permissionName">
            <Input placeholder="权限名称" />
          </Item>
          <Item name="userName">
            <Input placeholder="用户名" />
          </Item>
          <Item name="id">
            <Input placeholder="请求ID" />
          </Item>
          <Item name="requestBody">
            <Input placeholder="requestBody" />
          </Item>
          <Item name="responseBody">
            <Input placeholder="responseBody" />
          </Item>
          <Item name="requestTimeFrom">
            <DatePicker showTime placeholder="请求时间从" />
          </Item>
          <Item name="requestTimeTo">
            <DatePicker showTime  placeholder="请求时间至" />
          </Item>
          <Item name="requestUri">
            <Input placeholder="URI" />
          </Item>
          <Item name="responseStatus">
            <Input placeholder="响应状态" />
          </Item>
          <Item name="accessToken">
            <Input placeholder="Token" />
          </Item>
        </Table>

        <Modal 
          title="日志详情"
          width={1200}
          visible={isModalVisible} 
          onCancel={() => setModalVisible(false)}
          onOk={() => setModalVisible(false)}
        >
          <Form form={form}>
            <Row>
              <Col span={6}>
                <Item label="接口名称" name="permissionName">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={6}>
                <Item label="URI" name="requestUri">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={6}>
                <Item label="请求时间" name="requestTime">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={6}>
                <Item label="响应时间" name="responseTime">
                  <DisplayInput />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Item label="用户名" name="userName">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={6}>
                <Item label="用户姓名" name="fullName">
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={6}>
                <Item label="角色名称" name="roleName">
                  <DisplayInput />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Item 
                  label="调试信息" 
                  name="debugMessage" 
                  labelCol={{ span: 2 }} 
                  wrapperCol={{ span: 22 }}
                >
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={24}>
                <Item 
                  label="User Agent" 
                  name="userAgent" 
                  labelCol={{ span: 2 }} 
                  wrapperCol={{ span: 22 }}
                >
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={24}>
                <Item 
                  label="URL 查询参数" 
                  name="queryParams" 
                  labelCol={{ span: 2 }} 
                  wrapperCol={{ span: 22 }}
                >
                  <DisplayInput />
                </Item>
              </Col>
              <Col span={24} style={{ paddingBottom: '20px' }}>
                <Space>
                  <Download 
                    label='下载请求报文'
                    url={`/sys/apiLogs/${detail.id}/download?type=2`} 
                  />
                  <Download 
                    label='下载响应报文'
                    url={`/sys/apiLogs/${detail.id}/download?type=1`} 
                  />
                  <Download 
                    label='下载stacktrace'
                    url={`/sys/apiLogs/${detail.id}/download?type=3`} 
                  />
                </Space>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col span={11}>
              {isXML 
                ? <XMLViewer xml={XMLRequestBody} theme={customTheme} />
                : <JsonEditor datasource={requestBody} />
              }
            </Col>
            <Col span={11} offset={1} > 
              {isXML
                ? <XMLViewer xml={XMLResponseBody} theme={customTheme} />
                : <JsonEditor datasource={responseBody} />
              }
            </Col>
          </Row>
        </Modal>
      </Card>
    </Page>
  );
};

export { ApiLogList };
