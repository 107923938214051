let numeral = require('numeral');

/**
 * 数学相关工具类
 */

/**
 * 小数转百分数
 * @param percent 小数
 */
const num2Percent = (percent: number): string => {
    return (percent * 100) + '%';
}

/**
 * 数字转金额(带千分位)
 * @param num 数字
 */
const num2money = (num: number): string => {
    return numeral(num).format('0,0.00')
}

/**
 * 数字带千分位
 * @param num 数字
 */
const numThousandth = (num: number): string => {
    return numeral(num).format('0,0')
}

const Math = {
    num2Percent,
    num2money,
    numThousandth
}

export default Math