import * as React from 'react';
import { Page, Card, Table, Lookup } from '@comps';
import { Form, Input, DatePicker } from 'antd';
import { ColumnProps } from '@comps/types';
import { DateUtils } from '@utils';
import { Link } from 'react-router-dom';
import { Change, Status } from './interface';

export interface Props {};

const List: React.FC<Props> = () => {
  const getTargetRoute = React.useCallback((change: Change) => {
    const _route = change.statusCode === Status.SUBMIT ? 'update' : 'detail';
    return `/tax/change/${_route}/${change.id}`;
  }, []);
  const initQuery = React.useMemo<JSONObject>(() => ({
    requestDateFromTo: [DateUtils.monthFirstDay(), DateUtils.now()],
  }), []);

  const columns: ColumnProps[] = [
    {
      key: 'requestNo', title: '申请单号', width: 150,
      render (value, change: Change) {
        return (
          <Link to={getTargetRoute(change)}>{value}</Link>
        );
      },
    },
    { key: 'creationTime', title: '申请时间', width: 120, format:'datetime' },
    { key: 'submitterName', title: '申请人', width: 120, },
    { key: 'approverName', title: '开票人员', width: 120, },
    { key: 'buyerName', title: '客户名称', width: 250, },
    { key: 'withTaxAmount', title: '价税合计', format: 'amount', width: 120 },
    { key: 'approveRemarks', title: '拒绝原因', width: 200 },
    { key: 'statusCodeDesc', title: '改票状态', width: 100, fixed:'right' },
    {
      key: 'id', title: '操作', width: 80, fixed:'right',
      render (_1, change: Change) {
        return (<Link to={getTargetRoute(change)}>详情</Link>);
      },
    },
  ];

  return (
    <Page>
      <Card title='改票申请'>
      <Table url="/tax/changes"
        queryProps={{
          initialValues: initQuery,
        }}
        pagination={{hideOnSinglePage:false}}
        columns={columns}>
        <Form.Item name="buyerName">
          <Input placeholder="客户名称" />
        </Form.Item>
        <Form.Item name="requestDateFromTo">
          <DatePicker.RangePicker format='YYYYMMDD'
            placeholder={['申请日期从', '至']} />
        </Form.Item>
        <Form.Item name="statusCode">
          <Lookup placeholder="改票状态" lookupType="CHANGE_REQUEST_STATUS"/>
        </Form.Item>
        <Form.Item name="employeeCode">
          <Input placeholder="业务员编码" />
        </Form.Item>
        <Form.Item name="requestNo">
          <Input placeholder="改票申请单号" />
        </Form.Item>
      </Table>
      </Card>
    </Page>
  );
};

export default List;
