import React, { useState, useEffect, FC } from 'react';
import { Button, Input, Form, Select, Radio, message, Space } from 'antd';

import { Card, Page, BackTag, DisplayInput } from '@comps';

import { Http, Context, Api } from '@utils';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const Add: FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState<[]>([]);
  const [billingVis, setBillingVis] = useState<boolean>(true)

  useEffect(() => {
    // getTicket()
    getSelectOptions();
  }, []);

  useEffect(() => {
    getTicket()
  })

  const getTicket = () => {
    Http.get("/org/orgs/deptMasterNode").then(res => {
      form.setFieldsValue({billingVendorType: res})
      if (res) {
        setBillingVis(true)
      } else {
        setBillingVis(false)
      }
    })
  }

  const getSelectOptions = () => {
    Api.getKv('/sys/roles/kv')
      .then((data: any) => {
        setRoleOptions(data)
      })
  };

  const toggle = () => {
    setLoading(!loading);
  };

  const handOnSave = () => {
    form?.validateFields()
      .then((values: any) => {
        const { billingUserName, billingPassword } = values || {}
        if ((!billingUserName && billingPassword) || (billingUserName && !billingPassword)) {
          message.warning("账号密码需同时填写")
          return
        }
        toggle();

        Http.post('/sys/users', values)
          .then(data => {
            message.info('创建成功');
            Context.route('/sys/users');
          })
          .finally(() => {
            toggle();
          })
      });
  };

  return (
    <Page>
      <Card title='新增用户' loading={loading}>
        <Form
          {...layout}
          name="form-user-edit"
          form={form}
          initialValues={{ status: 'ACTIVE' }}
        >
          <Item
            name='userName'
            label="用户名"
            rules={[{ required: true }]}
          >
            <Input />
          </Item>
          <Item name='lastName' label='姓名'>
            <Input />
          </Item>
          <Item
            name='password'
            label='密码'
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Item>
          <Item
            name='emailAddress'
            label='邮箱'
            rules={[{ type: 'email' }]}
          >
            <Input />
          </Item>
          <Item name='mobile' label='手机号'>
            <Input />
          </Item>
          <Item name='gender' label='性别'>
            <Radio.Group >
              <Radio value='M'>男</Radio>
              <Radio value='W'>女</Radio>
            </Radio.Group>
          </Item>
          <Item name='status' label='状态'>
            <Radio.Group>
              <Radio value='ACTIVE'>有效</Radio>
              <Radio value='INACTIVE'>已失效</Radio>
              <Radio value='FROZEN'>冻结</Radio>
            </Radio.Group>
          </Item>
          <div style={{ position: 'relative' }}>
            <Item name='roleIds' label='角色'>
              <Select showSearch mode="multiple" options={roleOptions} />
            </Item>
          </div>
          {billingVis && (<div>
            <Item name='billingVendorType' label="开票厂商">
              <DisplayInput />
            </Item>
            <Item name='billingUserName' label='账户' rules={[{ pattern: /^1[3|4|5|7|8][0-9]\d{8}$/, message: '请输入正确的手机号' }]}>
              <Input />
            </Item>
            <Item name='billingPassword' label='密码'>
              <Input />
            </Item>
          </div>)}
          <Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Space>
              <Button type="primary" onClick={handOnSave}>保存</Button>
            </Space>
          </Item>
        </Form>
      </Card>
    </Page>
  );
};

export default Add;
