export enum EntryMethod {

  /**
   * 手工录入
   */
  MANUAL,
  /**
   * 扫描仪录入
   */
  SCANNER,
  /**
   * 高拍仪录入
   */
  HIGH_BEAT_METER,
  /**
   * 图片录入
   */
  IMAGE,
  /**
   * PDF 录入
   */
  PDF,
  /**
   * OFD 录入
   */
  OFD
}