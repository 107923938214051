import React, { FC, useEffect, useState } from 'react'
import { Button, Input, Form, Row, Col, Space } from 'antd'
import { Card, Page, BackButton, DisplayInput } from '@comps'
import { Http, Message } from '@utils'

const Item = Form.Item

const Profile: FC = () => {
  const [userForm] = Form.useForm()
  const [pwdForm] = Form.useForm()
  const [user, setUser] = useState<any>({ id: -1, userName: '', roleName: '' })

  useEffect(() => {
    Http.get('/sys/users/self').then((data: any) => {
      setUser({
        id: data.userId,
        userName: data.userName,
        roleName: data.roleName,
      })

      userForm.setFieldsValue({ ...data })
    })
  }, [userForm])


  const handOnSave = () => {
    userForm.validateFields().then(values => {
      Http.put('/sys/users', { id: user.id, ...values }).then(data => {
        Message.saveOk()
      })
    })
  }

  const updatePassword = () => {
    pwdForm.validateFields().then(values => {
      Http.put('/sys/password', { userId: user.id, ...values }).then(data => {
        Message.updateOk()
      })
    })
  }

  return (
    <Page>
      <Card title='基本信息'>
        <Form name="form-user-edit" form={userForm}>
          <Row>
            <Col span={7}><Item name='userName' label="用户名" ><DisplayInput/></Item></Col>
            <Col span={7} offset={1}><Item name='employeeNo' label='员工编号'><DisplayInput /></Item></Col>
            <Col span={7} offset={1}><Item name='roleName' label='角色'><DisplayInput/></Item></Col>
          </Row>
          <Row>
            <Col span={7}><Item name='emailAddress' label='邮箱'><Input /></Item></Col>
            <Col span={7} offset={1}><Item name='mobile' label='手机号'><Input /></Item></Col>
            <Col span={7} offset={1}><Item name='lastName' label='姓名'><Input /></Item></Col>
          </Row>
          <Item wrapperCol={{ offset: 8, span: 8 }}>
            <Space>
              <Button type='primary' onClick={handOnSave}>保存</Button>
              <BackButton />
            </Space>
          </Item>
        </Form>
      </Card>

      <Card title='修改密码'>
        <Form form={pwdForm} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
          <Item name='oldPassword' label='旧密码' rules={[{ required: true }]}>
            <Input type='password' />
          </Item>
          <Item name='newPassword' label='新密码' rules={[{ required: true }]}>
            <Input type='password' />
          </Item>

          <Item wrapperCol={{ offset: 8, span: 8 }}>
            <Button type='primary' onClick={updatePassword}>保存</Button>
          </Item>
        </Form>
      </Card>
    </Page>
  )
}





export default Profile
