export default function flatPromise(): {
  promise: Promise<any>,
  resolve: (data: any) => void,
  reject: (error: any) => void,
} {
  let res;
  let rej;
  const promise = new Promise((resolve, reject) => {
    res = resolve;
    rej = reject;
  });
  return { promise, resolve: res as any, reject: rej as any };
}
