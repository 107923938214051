import { combineReducers } from 'redux';

import contextReducer from './reducers/contextReducer'

/**
 * 统一管理应用所有 reducers
 */
const rootReducer = combineReducers({
    ctx: contextReducer,
})

export { rootReducer };