import React, { FC, useEffect, useState, Fragment } from 'react'

import { Input, Button, Form, Space, Row, Select, Checkbox } from 'antd'

import { Page, Card, Table, DisplayInput, Modal } from '@comps'
import { Http, Message, Layout, Validation } from '@utils'
import { ColumnProps } from '@comps/types'

const Item = Form.Item
const Option = Select.Option

interface Props {
    match?: any,
}

const columns: ColumnProps[] = [
    {
        key: 'orderNo',
        title: '序号',
        width: 60,
    },
    {
        key: 'segmentTypeDesc',
        title: '段类型',
        width: 80,
    },
    {
        key: 'constantValue',
        title: '常量值',
        render: (value, record, index) => (record.segmentType === 'CONSTANT' ? value : null),
    },
    {
        key: 'dateMask',
        title: '日期格式',
        width: 100,
        render: (value, record, index) => (record.segmentType === 'DATE' ? value : null),
    },
    {
        key: 'seqResetFrequencyDesc',
        title: '重置频率',
        width: 100,
        render: (value, record, index) => (record.segmentType === 'SEQUENCE' ? value : null),
    },
    {
        key: 'seqLength',
        title: '序列长度',
        width: 100,
        render: (value, record, index) => (record.segmentType === 'SEQUENCE' ? value : null),
    },
    {
        key: 'seqInitialValue',
        title: '序列初始值',
        width: 100,
        render: (value, record, index) => (record.segmentType === 'SEQUENCE' ? value : null),
    },
    {
        key: 'seqNextValue',
        title: '序列下一值',
        width: 100,
        render: (value, record, index) => (record.segmentType === 'SEQUENCE' ? value : null),
    },
]

const Edit: FC<Props> = (props) => {
    let [isAddModalVisible, setAddModalVisible] = useState(false)
    let [types, setTypes] = useState({ isConstant: false, isDate: false, isSequence: false })
    let [segmentTable, setSegmentTable] = useState<Table>()

    const [form] = Form.useForm();
    const [segmentForm] = Form.useForm();
    const id = props.match.params.id

    /**
     * 将useEffect第二个参数设置为[], 告诉react只渲染一次, 不要追踪变更
     */
    useEffect(() => {
        Http.get(`/sys/codeRules/${id}`).then((data: any) => {
            form.setFieldsValue({ ...data })
        })
    }, [id, form])

    const save = () => {
        form.validateFields().then(values => {
            Http.put('/sys/codeRules', { id: id, ...values }).then(data => {
                Message.saveOk()
            })
        })
    }

    const onSegmentTypeChange = (value: string) => {
        setTypes({
            isConstant: value === 'CONSTANT',
            isDate: value === 'DATE',
            isSequence: value === 'SEQUENCE',
        })
    }

    const onSaveSegment = () => {
        segmentForm.validateFields().then(values => {
            let params = {
                ruleId: id,
                ...values
            }

            Http.post('/sys/codeRules/segments', params).then(data => {
                segmentForm.resetFields()
                setAddModalVisible(false)

                if (segmentTable) {
                    segmentTable.refresh()
                    Message.ok()
                }
            })
        })
    }

    const onTableRef = (table: Table) => {
        setSegmentTable(table)
    }

    return (
        <Page backable>
            <Card title='编码规则'>
                <Form name='detail' form={form} >
                    <Row>
                        <Space size='large'>
                            <Item name='ruleCode' label='规则编码'>
                                <DisplayInput />
                            </Item>
                            <Item name='levelDesc' label='层级'>
                                <DisplayInput />
                            </Item>
                            <Item name='ruleName' label='规则名称' rules={[{ required: true }]}>
                                <Input />
                            </Item>
                            <Item name='enabled' label='启用' valuePropName='checked' rules={[{ required: true }]}>
                                <Checkbox />
                            </Item>
                            <Item>
                                <Button type='primary' onClick={save}>保存</Button>
                            </Item>
                        </Space>
                    </Row>
                </Form>
            </Card>

            <Modal title='新增段' visible={isAddModalVisible} onOk={onSaveSegment} onCancel={() => setAddModalVisible(false)}>
                <Form name='segment' {...Layout.modal_1c} form={segmentForm} validateMessages={Validation.formValidateMsg}>
                    <Item name='segmentType' label='段类型' rules={[{ required: true }]}>
                        <Select onChange={onSegmentTypeChange}>
                            <Option value='CONSTANT'>常量</Option>
                            <Option value='DATE'>日期</Option>
                            <Option value='SEQUENCE'>序列</Option>
                        </Select>
                    </Item>
                    <Item name='orderNo' label='序号' rules={[{ required: true }]}>
                        <Input type='number' min={1} />
                    </Item>
                    {types.isConstant && <Item name='constantValue' label='常量值' rules={[{ required: true }]}>
                        <Input />
                    </Item>}

                    {
                        types.isDate &&
                        <Item name='dateMask' label='日期格式' rules={[{ required: true }]}>
                            <Select>
                                <Option value='yyyy'>年(yyyy)</Option>
                                <Option value='yyyyMM'>年月(yyyyMM)</Option>
                                <Option value='yyyyMMdd'>年月日(yyyyMMdd)</Option>
                            </Select>
                        </Item>
                    }

                    {types.isSequence &&
                        <Fragment>
                            <Item name='seqResetFrequency' label='重置频率' rules={[{ required: true }]}>
                                <Select>
                                    <Option value='DAY'>每天重置</Option>
                                    <Option value='MONTH'>每月第一天</Option>
                                    <Option value='YEAR'>每年第一天</Option>
                                    <Option value='NEVER'>从不重置</Option>
                                </Select>
                            </Item>
                            <Item name='seqLength' label='序列长度' rules={[{ required: true }]}>
                                <Input type='number' min={3} max={12} />
                            </Item>
                            <Item name='seqInitialValue' label='序列初始值' rules={[{ required: true }]}>
                                <Input type='number' />
                            </Item>
                        </Fragment>
                    }
                </Form>
            </Modal>

            <Card title='规则段' actions={<Button type='primary' onClick={() => setAddModalVisible(true)}>新增段值</Button>}>
                <Table url={`/sys/codeRules/${id}/segments`} columns={columns} onTableRef={onTableRef} />
            </Card>

        </Page>
    )
}

export { Edit }