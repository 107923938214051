import React, { FC } from 'react';
import { Button } from 'antd';
import { Card } from '@comps';

interface Props {
  checkResObject: JSONObject,
  onClose: () => void
};

const CheckResMD: FC<Props> = ({
  checkResObject,
  onClose
}) => {

  const { successNum, failNum } = checkResObject;

  return (
    <Card title="查验结果">
      <p>
        {`本次查验成功 ${successNum} 张，查验失败 ${failNum} 张，查验结果已返回列表，请查看`}
      </p>
      <div style={{ textAlign: 'center' }}>
        <Button onClick={onClose}>确定</Button>
      </div>
    </Card>
  )
};

export default CheckResMD;