import * as React from 'react';
import { Space, Form, Input} from 'antd';
import { Card, Button } from '@comps';
import { Http } from '@utils';

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 12, offset: 6 },
};

export interface Props {
  onCancel: () => void,
  onConfirm: () => void,
  requestNo: string,
}

const Add: React.FC<Props> = ({
  onCancel, onConfirm, requestNo,
}) => {
  const [form] = Form.useForm();

  async function handleSave() {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }
    try {
      const { email } = form.getFieldsValue();
      await Http.post(`/tax/outputInvoices/sendQrCode`, {
        email,
        requestNo,
      });
      onConfirm();
    } catch (_1) {
      onCancel();
    }
  }

  return (
    <Card title="请输入客户邮箱" style={{ marginBottom: '0' }}>
      <Form {...layout} form={form} style={{ paddingTop: '24px' }}>
        <Form.Item name="email"
          rules={[
            {
              type: 'email',
              message: '请输入正确格式的邮箱!',
            },
            {
              required: true,
              message: '请输入邮箱!',
            },
          ]}>
          <Input />
        </Form.Item>
        <div style={{ textAlign: 'center' }}>
          <Space>
            <Button type="primary" clickCallback={handleSave}>确认发送</Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default Add;
