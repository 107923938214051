import InternalModal from './Modal';
import { ModalProps } from 'antd/lib/modal';
import AdModal from 'antd/es/modal';
import innerOpen, { OpenProps, ModalStaticFunctions, OpenFn } from './open';
import proxyUseModal from './AdModal/useModal'

export type ModalOpenProps = OpenProps;


export interface ModalComponent extends React.FC<ModalProps>,
  Pick<AdModal, 'destroyAll' | 'info' | 'success' | 'error' | 'warn' | 'warning' | 'confirm'> {
  open: OpenFn,
  useModal: () => [ModalStaticFunctions, React.ReactElement],
}

const Modal = InternalModal as ModalComponent;
Modal.displayName = 'Modal';
Modal.destroyAll = AdModal.destroyAll;
Modal.useModal = (): [ModalStaticFunctions, React.ReactElement] => {
  const [ins, holder] = proxyUseModal();
  const newIns = ins as ModalStaticFunctions;
  newIns.open = (props: ModalOpenProps) => {
    return innerOpen(props, newIns);
  }
  return [newIns, holder];
}

Modal.info = AdModal.info;
Modal.success = AdModal.success;
Modal.error = AdModal.error;
Modal.warn = AdModal.warn;
Modal.warning = AdModal.warning;
Modal.confirm = AdModal.confirm;
Modal.open = (props: ModalOpenProps) => {
  return innerOpen(props, Modal);
};

export default Modal;
