import React, { FC, useState, useEffect } from 'react'
import { Page, Table, Card, LinkText, Modal, DisplayInput } from '@comps'
import { Layout, Http, Message } from '@utils'
import { ColumnProps } from '@comps/types'
import { Button, Form, Input, Checkbox } from 'antd'

const Item = Form.Item

const TenantList: FC = () => {
    const [form] = Form.useForm()
    let [isModalVisible, setModalVisible] = useState(false)
    let [selectedId, setSelectedId] = useState(-1)
    let [table, setTable] = useState<Table>()

    useEffect(() => {

    }, [])

    async function save() {
        await form.validateFields()
        let values = form.getFieldsValue()
        if (selectedId < 0) {
            await Http.post('/sys/tenants', values)
        } else {
            await Http.put('/sys/tenants', { ...values, id: selectedId })
        }
        Message.saveOk()
        setModalVisible(false)
        form.resetFields()
        table?.refresh()
    }

    const onAddOrEditClick = (roleId: number = -1, role?: any) => {
        setSelectedId(roleId)
        setModalVisible(true)
        if (roleId > 0) {
            form.setFieldsValue({ ...role })
        }
        table?.refresh()
    }

    const onCancel = () => {
        form.resetFields();
        setModalVisible(false)
    }

    const onTableRef = (tableRef: Table) => {
        table = tableRef
        setTable(tableRef)
    }

    const columns: ColumnProps[] = [
        {
            key: 'tenantCode',
            title: '租户编码',
        },
        {
            key: 'tenantName',
            title: '租户简称',
        },
        {
            key: 'tenantDesc',
            title: '租户全称',
        },
        {
            key: 'creationTime',
            title: '创建时间',
            format: 'datetime',
        },
        {
            key: 'enabled',
            title: '启用',
            render: (value) => (value ? '启用' : '禁用'),
        },
        {
            key: 'id',
            title: '操作',
            render: (id, row, index) => (<LinkText label='修改' onClick={() => onAddOrEditClick(id, row)}></LinkText>),
        },
    ]
    return (
        <Page>
            <Card title='租户管理' actions={<Button type='primary' onClick={() => onAddOrEditClick()}>新增</Button>}>
                <Table url='/sys/tenants' columns={columns} rowKey='id' onTableRef={onTableRef}>
                    <Input name='tenantName' placeholder='租户名称' />
                </Table>
            </Card>

            <Modal title='租户管理' visible={isModalVisible}
                onCancel={onCancel}
                onOk={save}>
                <Form name='form' form={form} {...Layout.modal_1c}>
                    {selectedId < 0 && <Item name='tenantCode' label='租户编码' rules={[{ required: true }]}><Input /></Item>}
                    {selectedId >= 0 && <Item name='tenantCode' label='租户编码' ><DisplayInput /></Item>}
                    <Item name='tenantName' label='租户简称' rules={[{ required: true }]}><Input /></Item>
                    <Item name='tenantDesc' label='租户全称'><Input /></Item>
                    <Item name='enabled' label='启用' valuePropName='checked' required><Checkbox /></Item>
                </Form>
            </Modal>
        </Page>
    )
}

export { TenantList }