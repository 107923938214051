import * as React from 'react';
import { Card, Button, Lookup } from '@comps';
import { Form, Space } from 'antd';
import { Http, Lang } from '@utils';

export interface Props extends React.Props<HTMLElement>,
  React.BaseHTMLAttributes<HTMLElement> {
  ids: number[],
  onClose: () => void,
};

const BatchDelete: React.FC<Props> = ({ onClose, ids }: Props) => {
  const [form] = Form.useForm();

  const handleConfirm = React.useCallback(() => Lang.noError(async () => {
    await form.validateFields();
    const formData = form.getFieldsValue();
    await Http.put(`/tax/outputInvoices/batchUpdateInvType`, {
      invoiceType: formData.invoiceType,
      invoiceIdList: ids,
    });
    onClose();
  }), [form, onClose, ids]);

  return (<Card title='请选择发票类型' style={{ marginBottom: '0' }}>
    <Form form={form} labelCol={{ span: 6 }}
      style={{ padding: '30px 60px' }}>
      <Form.Item label="发票类型"
        name="invoiceType"
        rules={[{ required: true, message: '请选择发票类型' }]}>
        <Lookup lookupType="TAX_INVOICE_TYPE" />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6 }}>
        <Space>
          <Button type="primary"
            clickCallback={handleConfirm}>确认修改</Button>
          <Button onClick={() => onClose()}>取消</Button>
        </Space>
      </Form.Item>
    </Form>
  </Card>);
};

export default BatchDelete;
