import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Row, Col, Button, DatePicker, Space, Input } from 'antd';

import { Card, DisplayInput, HiddenFormItem, LinkText } from '@comps';

import { Http, Message, DateUtils } from '@utils';

const { Item } = Form;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface Props {
  rowInfo: JSONObject,
  refreshTable: () => void,
  onClose: () => void
};

const ProcessNoteMD: FC<Props> = ({
  rowInfo,
  refreshTable,
  onClose
}) => {
  const { id, statusCodeDesc } = rowInfo;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormData()
  }, [])

  const setFormData = () => {

    form.setFieldsValue({ 
      ...rowInfo, 
      invoiceId: id,
    })
  };

  const handleRemark = () => {
    setLoading(true);

    const formData = form.getFieldsValue();
    
    Http.get('/input/invPool/dealExceInvoice', formData)
      .then(() => {
        refreshTable();
        onClose();
        Message.success('保存成功');
      })
      .finally(() => {
        setLoading(false);
      })
  };

  return (
    <Card title="处理备注">
      <Row justify="space-between" style={{ marginTop: 20 }}>
        <Col>
          <p>当前选择发票状态：{statusCodeDesc}</p>
        </Col>
        <Col>
          <Link to={`/income/query/${id}/detail`} onClick={onClose}>查看详情</Link>
        </Col>
      </Row>
      <Form 
        form={form} 
        onFinish={handleRemark}
      >
        <Row style={{ margin: '20px 0' }}>
          <Col span={24}>
            <HiddenFormItem name="invoiceId" />
            <Item name="processedRemark">
              <TextArea autoSize />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space>
              <Button 
                type="primary" 
                loading={loading}
                htmlType="submit"
              >
               保存
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ProcessNoteMD;