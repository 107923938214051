import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Row, Col, Space } from 'antd';

import { Page, Table, Card, DisplayInput, Modal, Button, PermissionButton, BackTag } from '@comps';
import TitleMgr from './addOrEditTitle.md';
import ContactMgr from './addOrEditContact.md';

import { Http, Context } from '@utils';
import { ColumnProps } from '@comps/types';

import './index.css';

const { Item } = Form;

const CustomerDetail: FC = (props: any) => {
  const [id, setId] = useState(-1);
  const orgId = Context.getPathVar(props);
  const originBaseInfo = React.useRef<JSONObject>({});

  const [baseIsEdit, setBaseIsEdit] = useState(false);

  const [infoForm] = Form.useForm();
  const [userForm] = Form.useForm();
  const [inputForm] = Form.useForm();
  const [voucherForm] = Form.useForm();
  const [mailForm] = Form.useForm();
  const [propsForm] = Form.useForm();

  const [model, modalHolder] = Modal.useModal();

  let [titleTable, setTitleTable] = useState<Table>();
  let [contactTable, setContactTable] = useState<Table>();

  useEffect(() => {
    getDetailInfo();
    initVoucherProps(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetailInfo = () => {
    Http.get(`/org/customers/${orgId}`)
      .then(data => {
        infoForm.setFieldsValue(data);
        setId(data.id);
        userForm.setFieldsValue(data);
        propsForm.setFieldsValue(data);
        inputForm.setFieldsValue(data);

        mailForm.setFieldsValue(data);
        propsForm.setFieldsValue({
          mergeProject: !data.mergeProject,
          mergeOrderNo: !data.mergeOrderNo,
          mergeRequestNo: !data.mergeRequestNo,
          mergeRemark: !data.mergeRemark
        });
      })
  };

  const onTitleTableRef = (tableRef: Table) => {
    titleTable = tableRef;
    setTitleTable(tableRef);
  };

  const onContactTableRef = (tableRef: Table) => {
    contactTable = tableRef;
    setContactTable(tableRef);
  };


  /**
   * 新增或更新发票抬头
   * @param title
   */
  const handleTitleAddOrEdit = async (title?: JSONObject) => {
    await model.open({
      width: '600px',
      content(resolve, reject) {
        return (
          <TitleMgr
            onConfirm={resolve}
            onCancel={reject}
            title={title}
          />
        );
      },
    });

    titleTable?.refresh();
  };

  /**
   * 新增或更新联系人
   * @param contact
   */
  const handleContactAddOrEdit = async (contact?: JSONObject) => {
    await model.open({
      width: '600px',
      content(resolve, reject) {
        return (
          <ContactMgr
            onConfirm={resolve}
            onCancel={reject}
            contact={contact}
          />
        );
      },
    });

    contactTable?.refresh();
  };

  const handleBaseUpdate = () => {
    originBaseInfo.current = infoForm.getFieldsValue();
    setBaseIsEdit(true);
  };

  const handleBaseCancel = () => {
    infoForm.setFieldsValue(originBaseInfo.current);
    setBaseIsEdit(false);
  };

  const handleBaseSave = async () => {
    await infoForm.validateFields();
    const baseInfo = infoForm.getFieldsValue();
    await Http.put('/org/customers', { id, ...baseInfo });

    setBaseIsEdit(false);
  };

  const initVoucherProps = (bizType: number) => {
    Http.get('/input/subject/get', { bizType, orgId, type: 1 })
      .then(data => {
        if (data.length === 0) {
          voucherForm.resetFields();
          voucherForm.setFieldsValue({ bizType });
        } else {
          const fieldsValue = data[0]
          fieldsValue.subjectCode2 = data[1].subjectCode;
          fieldsValue.subjectName2 = data[1].subjectName;
          fieldsValue.direction2 = data[1].direction;

          voucherForm.setFieldsValue(fieldsValue);
        }
      })
  };

  const headerColumns: ColumnProps[] = [
    {
      key: 'defaultFlag', title: '默认抬头', width: 80,
      render: (value) => (value ? '是' : '否')
    },
    { key: 'bankName', title: '开户行', width: 200, },
    { key: 'accountNo', title: '银行账号', width: 200, },
    { key: 'tel', title: '电话', width: 150, },
    { key: 'address', title: '地址', width: 250, },
    {
      key: 'id', title: '操作',
      render: (id, row) => (
        <Button type='link' onClick={() => { handleTitleAddOrEdit(row) }}>修改</Button>
      )
    }
  ];

  const contactsColumns: ColumnProps[] = [
    {
      key: 'defaultFlag', title: '默认联系人', width: 80,
      render: (value) => (value ? '是' : '否')
    },
    { key: 'fullName', title: '姓名', width: 100, },
    { key: 'phone', title: '电话', width: 100, },
    { key: 'email', title: '邮箱', width: 200, },
    { key: 'address', title: '地址', width: 250, },
    {
      key: 'id', title: '操作',
      render: (id, row) => (
        <Button
          type='link'
          onClick={() => { handleContactAddOrEdit(row) }}
        >
          修改
        </Button>
      )
    }
  ];

  return (
    <Page>
      {modalHolder}
      <Card
        title='基本信息'
        actions={
          <Space>
            {
              baseIsEdit
                ? <>
                  <PermissionButton
                    permissions="OrgCustomer.update"
                    clickCallback={handleBaseSave}
                  >
                    保存
                  </PermissionButton>
                  <PermissionButton
                    permissions="OrgCustomer.update"
                    onClick={handleBaseCancel}
                  >
                    取消
                  </PermissionButton>
                </>
                : <PermissionButton
                  permissions="OrgCustomer.update"
                  onClick={handleBaseUpdate}
                >
                  修改
                </PermissionButton>
            }
          </Space>
        }
      >
        <Form
          name="org"
          form={infoForm}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
        >
          <Row>
            <Col span={7}>
              <Item
                label="客户名称"
                name='customerName'
                rules={[{ required: baseIsEdit, message: '请输入公司名称' }]}
              >
                {baseIsEdit ? (<Input />) : (<DisplayInput />)}
              </Item>
            </Col>
            <Col span={6}>
              <Item
                label="税号"
                name="taxNo"
                rules={[{ required: baseIsEdit, message: '请输入税号' }]}
              >
                {baseIsEdit ? (<Input />) : (<DisplayInput />)}
              </Item>
            </Col>
            <Col span={4}>
              <Item
                label="编码"
                name="customerCode"
                rules={[{ required: baseIsEdit, message: '请输入编码' }]}
              >
                {baseIsEdit ? (<Input />) : (<DisplayInput />)}
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        title="发票抬头"
        actions={
          <Button
            type="primary"
            onClick={() => { handleTitleAddOrEdit({ orgId: orgId }) }}
          >
            新增
          </Button>
        }
      >
        <Table
          url={`/org/customers/${orgId}/pageInvoiceTitles`}
          onTableRef={onTitleTableRef}
          columns={headerColumns}>
        </Table>
      </Card>
      <Card
        title="联系人"
        actions={
          <Button
            type="primary"
            onClick={() => { handleContactAddOrEdit({ orgId: orgId }) }}
          >
            新增
          </Button>
        }
      >
        <Table
          url={`/org/customers/${orgId}/pageContacts`}
          onTableRef={onContactTableRef}
          columns={contactsColumns}>
        </Table>
      </Card>
    </Page>
  );
};

export { CustomerDetail };
