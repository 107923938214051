import React, { FC } from 'react'
import { Layout } from 'antd'
import { Context } from '@utils'

const { Content, Footer } = Layout

const PublicLayout: FC = (props: any) => {

    const ctx = Context.getContext()
    const beian = ctx['global.beian']
    const beianUrl = ctx['global.beian-url']
    const copyright = ctx['global.copyright']

    return (
        <Layout style={{ position: 'relative', minHeight: '100vh' }} >
            <Content>{props.children}</Content>
            <Footer className='public-layout-footer'>
                {copyright} <a href={beianUrl} target='_blank' rel="noopener noreferrer">{beian}</a>
            </Footer>
        </Layout>
    )
}

export default PublicLayout
