import React, { FC, useState } from 'react'
import { Input, Button, Space } from 'antd'
import { Page, Card, Table, Modal, LinkText, PermissionButton } from '@comps'
import { ColumnProps } from '@comps/types'
import Add from './addOrEdit'
import Stock from './stock'
import AuthMd from './auth.md';

const AgentList: FC = () => {
  const [model, modalHolder] = Modal.useModal();
  let [table, setTable] = useState<Table>()

  async function handleAddOrEdit(agent?: JSONObject) {
    await model.open({
      width: '600px',
      content(resolve, reject) {
        return (<Add onConfirm={resolve} onCancel={reject} agent={agent} />);
      },
    });
    table?.refresh()
  }

  async function queryStock(agent?: JSONObject) {
    await model.open({
      width: '960px',
      content(resolve, reject) {
        return (<Stock onConfirm={resolve} onCancel={reject} agent={agent} />);
      },
    });
    table?.refresh()
  }

  async function grantAuth(agent: JSONObject) {
    await model.open({
      width: '960px',
      content(resolve) {
        return (<AuthMd onCLose={resolve} agent={agent} />);
      },
    });
    table?.refresh()
  }

  const columns: ColumnProps[] = [
    { key: 'orgName', title: '企业名称', width: 200, },
    { key: 'taxNo', title: '税号', width: 150, },
    { key: 'agentType', title: '类型', width: 80, },
    { key: 'machineNo', title: '分机号', width: 80, },
    { key: 'online', title: '在线状态', width: 100, render: (value => (value ? <LinkText label='在线' onClick={() => { }} /> : '不在线')) },
    { key: 'endpoint', title: 'Endpoint', width: 150, ellipsis: true, },
    {
      key: 'id',
      title: '操作',
      width: 200,
      render: (_1, row) => (
        <Space size={0}>
          <Button type='link' onClick={() => handleAddOrEdit(row)}>修改</Button>
          <Button type='link' onClick={() => queryStock(row)}>查看库存</Button>
          <PermissionButton permissions="TaxAgent.grant" type='link'
            onClick={() => grantAuth(row)}>授权</PermissionButton>
        </Space>
      )
    }
  ]

  const onTableRef = (tableRef: Table) => {
    table = tableRef
    setTable(tableRef)
  }

  return (
    <Page>
      {modalHolder}

      <Card title='税盘管理' actions={<Button type='primary' onClick={() => { handleAddOrEdit() }}>新增</Button>}>
        <Table url='/tax/agents' columns={columns} onTableRef={onTableRef}>
          <Input name='key' placeholder='企业名称/税号' />
        </Table>
      </Card>
    </Page>
  )
}

export { AgentList }
