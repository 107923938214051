import React, { FC, useEffect, useState, Fragment } from 'react'
import { Form, Row, Col, Button } from 'antd'
import { Card, Page, DisplayInput, Invoice } from '@comps'
import { Http, Context, Message } from '@utils'

const Item = Form.Item

/**
 * 发票详情
 */
const InvoiceDetail: FC = (props: any) => {
    const requestNo = Context.getPathVar(props, 'requestNo')
    const [loading, setLoading] = useState(false)
    const [invForm] = Form.useForm()
    const [deliveryForm] = Form.useForm()
    const [invoice, setInvoice] = useState<any>()
    const [pdfUrl, setPdfUrl] = useState()
    const [title, setTitle] = useState('')
    const [allowReopen, setAllowReopen] = useState(false)
    const [allowPrint, setAllowPrint] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { fetch() }, []);

    async function fetch() {
        let [invoice, invoiceLinesResult] = await Promise.all([
            Http.get(`/tax/outputInvoices/${requestNo}`),
            Http.get(`/tax/outputInvoices/${requestNo}/lines?pageSize=500&pageNum=1`),
        ])
        invoice.lines = invoiceLinesResult.content;
        invForm.setFieldsValue(invoice)
        setInvoice(invoice)
        setPdfUrl(invoice.internalPdfUrl)
        let cardTitle = ''
        if (invoice.invoiceNo) {
            cardTitle = `${invoice.invoiceTypeDesc} | ${invoice.invoiceNo} | ${invoice.statusCodeDesc}`
        } else {
            cardTitle = `${invoice.invoiceTypeDesc} | ${invoice.statusCodeDesc}`
        }

        setTitle(cardTitle)
        setAllowReopen(invoice.failed)
        setAllowPrint(invoice.allowPrint)

        let delivery = await Http.get(`/tax/outputInvoices/${requestNo}/delivery`)
        deliveryForm.setFieldsValue(delivery)
    }

    /**
     * 对失败的发票尝试重开
     */
    const tryReopen = () => {
        setLoading(true)
        Http.post(`/tax/outputInvoices/retry?requestNo=${requestNo}`).then(data => {
            fetch()
            Message.success("重试成功,请检查发票状态...")
        }).finally(() => {
            setLoading(false)
        })
    }

    /**
     * 打印发票
     */
    const print = (printList: boolean) => {
        setLoading(true)
        Http.get(`/tax/outputInvoices/print?requestNo=${requestNo}&printList=${printList}`).then(data => {
            Message.success("发票打印中,请耐心等待...")
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Page backable>
            <Card title={title} loading={loading}
                actions={<Fragment>
                    {pdfUrl && <a href={pdfUrl} target='_blank' rel="noopener noreferrer">下载电子发票</a>}
                    {allowReopen && <Button type='link' onClick={tryReopen}>重试开票</Button>}
                    {allowPrint && <Button type='link' onClick={() => print(false)}>打印</Button>}
                    {allowPrint && <Button type='link' onClick={() => print(true)}>打印清单</Button>}
                </Fragment>}
            >
                {invoice ? (<Invoice invoice={invoice} />) : undefined}
                <Form form={invForm} layout='vertical'>
                    <br />

                    {/* 开票失败 */}
                    {invoice?.failed && <Item name='failureReason' label="开票失败原因" ><DisplayInput /></Item>}

                    {/* 已作废 */}
                    {invoice?.canceled && <Item name='lastUpdateTime' label="作废时间" ><DisplayInput /></Item>}

                    {/* 红字发票 */}
                    {invoice?.redFlag &&
                        <Row>
                            <Col span={4}><Item name='blueInvoiceCode' label="蓝字发票代码" ><DisplayInput /></Item></Col>
                            <Col span={4}><Item name='blueInvoiceNo' label="蓝字发票号码" ><DisplayInput /></Item></Col>
                            <Col span={8}><Item name='redNoticeNo' label="通知单号" ><DisplayInput /></Item></Col>
                        </Row>
                    }
                </Form>
            </Card>
        </Page>
    )
}

export default InvoiceDetail
