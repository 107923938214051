import React, { FC, useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Spin } from 'antd';

import { Context, Http } from '@utils';
import { UserContext } from '@models';

import cname from 'classnames';
import styles from './index.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 }
};

const Login: FC = (props: JSONObject) => {
  const [appName, setAppName] = useState();

  const [form] = Form.useForm();
  const [logining, setLogining] = useState(false);

  const logined = Context.getIsLogined();
  if (logined) {
    Context.route('/sys/users/profile');
  };

  useEffect(() => {
    getPublicOptions();
  });

  const setUserContext = async (token: JSONObject) => {
    const { accessToken } = token;

    const user = await Http.get<JSONObject>('/sys/users/self', {}, { Authorization: accessToken });

    const userCtx: UserContext = {
      ...user,
      logined: true,
      token: accessToken,
      fullName: user.lastName,
      email: user.emailAddress,
      panes: [
        { title: '首页', key: '/sys/users/profile', route: '/sys/users/profile' },
      ],
    };

    Context.dispatch({ type: 'ACTION_LOGIN', data: userCtx });
    Context.route('/sys/users/profile');
  };

  const getPublicOptions = () => {
    Http.get('/sys/profiles/publicOptions')
      .then(data => {
        Context.dispatch({ type: 'ACTION_PROFILE', data: data });
        setAppName(data['global.app-name']);
      })
  };

  const onFinish = async () => {
    await form.validateFields()
    const username = form.getFieldValue('username')
    const password = form.getFieldValue('password')

    if (username && password) {
      setLogining(true);

      const params = {
        grantType: 'password',
        clientId: Context.clientId,
        clientSecret: Context.clientSecret,
        username: username,
        password: password,
        scope: ''
      };

      const token: any = await Http.post('/auth/token', params)
        .finally(() => {
          setLogining(false)
        });

      setUserContext(token);
    }
  };

  return (
    <div className={styles.container}>
      <Spin spinning={logining}>
        <Row>
          <Col span={10} offset={7}>
            <div className={cname(styles.login)}>
              <div className={cname(styles.title)}>
                {appName}
              </div>
              <Form
                {...layout}
                form={form}
                name="login"
                onFinish={onFinish}
              >
                <Item
                  label="用户名"
                  name="username"
                  rules={[{ required: true, message: '请输入用户名' }]}
                >
                  <Input />
                </Item>
                <Item
                  label="密码"
                  name="password"
                  rules={[{ required: true, message: '请输入密码' }]}
                >
                  <Input.Password />
                </Item>
                <Item {...tailLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    登陆
                  </Button>
                </Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default Login;
