import { RouteProps } from '@models';
import InvoiceList from './invoice';
import Update from './update';
import Index from './index';
import Detail from './detail';

const changeRoutes: RouteProps[] = [
  { path: '/tax/change/invoice', component: InvoiceList, private: true },
  { path: '/tax/change/update/:id', component: Update, private: true },
  { path: '/tax/change', component: Index, private: true },
  { path: '/tax/change/detail/:id', component: Detail, private: true },
];

export default changeRoutes;
