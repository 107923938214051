import React, { FC, Fragment, useState } from 'react'

import { Input, Button, Form, Space, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import { ColumnProps } from '@comps/types'

import { Page, Card, Table, Modal, LinkText } from '@comps'
import { Http, Context, Validation, Layout, Message } from '@utils'
import FunctionEdit from './edit'

const FunctionList: FC = () => {
    let [visible, setVisible] = useState(false)
    let [table, setTable] = useState<Table>()

    const [addForm] = Form.useForm()

    const deleteFunction = (id: number) => {
        Http.del(`/sys/functions/${id}`).then(data => {
            Message.ok()
            table?.refresh()
        })
    }

    const onTableRef = (tableRef: Table) => {
        table = tableRef
        setTable(tableRef)
    }

    const columns: ColumnProps[] = [
        {
            title: '功能名称',
            key: "functionName",

        },
        {
            title: '路由',
            key: 'route',
        },
        {
            title: '描述',
            key: 'description',
        },
        {
            title: '操作',
            key: 'id',
            fixed: 'right',
            width: 100,
            render: (id: number, row: any) => (<Space>
                <Link to={`/sys/functions/${id}/edit`}>修改</Link>
                <Popconfirm placement='left' title={`您确认要删除功能[${row.functionName}]吗?`} onConfirm={() => deleteFunction(id)}><LinkText label='删除' /></Popconfirm>
            </Space>),
        },
    ];

    const actions = <Fragment><Button type='primary' onClick={() => { setVisible(true) }}>新增</Button></Fragment>

    const saveAdd = () => {
        addForm.validateFields().then(values => {
            Http.post('/sys/functions', values).then((data: any) => {
                addForm.resetFields()
                Message.saveOk()
                setVisible(false)
                Context.route(`/sys/functions/${data.id}/edit`)
            })
        })
    }

    return (
        <Page>
            <Card title='功能列表' actions={actions}>
                <Table columns={columns} url='/sys/functions' onTableRef={onTableRef}>
                    <Input name='functionName' placeholder='功能名称' />
                </Table>
            </Card>

            <Modal visible={visible} title='新增功能'
                onCancel={() => setVisible(false)}
                onOk={() => saveAdd()}
            >
                <Form {...Layout.modal_1c} name='addForm' form={addForm} validateMessages={Validation.formValidateMsg}
                >
                    <Form.Item name='functionName' label='功能名称' rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='route' label='路由' rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='description' label='描述'>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </Page >
    )
}

export { FunctionList, FunctionEdit }