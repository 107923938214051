import React, { Component, Fragment } from "react";
import { Table as AntdTable, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';

import Query from './Query';
import { Http, Message } from '@utils';
import { TableUtil } from './util';
import { TableProps } from './table.d';

/**
 * 表格组件, 功能特性如下:
 * - 1.支持表头字段进行[筛选、排序]
 * - 2.支持查询面板
 * - 3.支持 selectedRow, 即第一列提供复选框
 * - 4.支持列以[标签]样式显示, 可配置标签颜色
 * - 5.Column 支持自定义字段: filterkey[string], isTag[boolean]
 * - 6.支持字段自动格式化, 包含:金额字段自动千分位并右对齐, 日期字段格式化(需提供format)
 * - 7.支持将当前对象的引用传递给父组件, 父组件通过 props 传递 onTableRef 函数即可
 *
 * @see https://ant-design.gitee.io/components/table-cn/
 */

// 表格默认列宽
const DEFAULT_COL_WIDTH: number = 150;
// 默认分页参数
const FILED_PAGE_NUM: string = "pageNum";
const FILED_PAGE_SIZE: string = "pageSize";

interface State {
  // 选中行
  selectedRowKeys?: any[],
  hideOnSinglePage: boolean,
  mounted?: boolean | false,
  filterKeys: any,
  columns: any,
  dataSource: any,
  loading?: boolean | false,
  xScroll: any,
  pageSize?: number | 10,
  pageNum?: number | 1,
  pageSizeOptions?: string[] | ["5", "10", "20", "50", "100", "500"],
  total?: number | 0,
  queryParams?: object | {}
};

class Table extends Component<TableProps, State> {

  protected form: FormInstance | null = null;

  constructor(props: TableProps) {
    super(props);
    this.handOnTableChange = this.handOnTableChange.bind(this);
    this.handOnReset = this.handOnReset.bind(this);
    this.handOnQuery = this.handOnQuery.bind(this);
    this.fetchTableData = this.fetchTableData.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getFormParam = this.getFormParam.bind(this);

    let { 
      columns, 
      dataSource, 
      selectedRowKeys, 
      pagination, 
      getTableSummaryInfo, 
      querySpecialFormat, 
      clearQuerySpecialFormat 
    } = this.props;

    // 表头所有筛选和排序字段, 需要传递给查询接口的字段名
    let filterKeys: any = {};

    // 表格宽度,高度
    let xScroll = 0;

    // 解析 columns
    columns?.map(column => {
      const key = column.key;

      // 设置 Column 默认宽度
      if (!column.width) {
        column.width = DEFAULT_COL_WIDTH;
      }

      // 通过 React key 属性自动对 antd Column 的 dataIndex 赋值
      if (!column.dataIndex) {
        column.dataIndex = key;
      }

      // 存储筛选字段的实际查询key, 如: statusDesc 的实际查询字段为 stateCode
      if (column.filterKey) {
        filterKeys[key] = column.filterKey;
      }

      // 处理 tag 类型样式
      TableUtil.handleColumnTag(column)

      // 渲染特定数据格式
      TableUtil.handleColumnFormat(column)

      // 超过宽度将自动省略
      column.ellipsis = true;

      // 自动处理横向滚动条的宽度
      xScroll += column.width;
    });

    let actualPageSize = 10;
    let actualHideOnSinglePage = false;

    if (pagination) {
      let { pageSize, hideOnSinglePage } = pagination;
      if (pageSize) {
        actualPageSize = pageSize;
      };

      if (hideOnSinglePage !== undefined) {
        actualHideOnSinglePage = hideOnSinglePage;
      };
    };

    this.state = {
      mounted: false,
      filterKeys: filterKeys,
      columns: columns,
      dataSource: dataSource,
      loading: false,
      xScroll: xScroll,
      pageSize: actualPageSize,
      pageNum: 1,
      pageSizeOptions: ["5", "10", "20", "50", "100", "500"],
      total: 0,
      queryParams: {},
      selectedRowKeys: selectedRowKeys,
      hideOnSinglePage: actualHideOnSinglePage
    };
  }

  /**
   * 重新获取Table数据, 该方法提供给父组件使用
   */
  refresh() {
    this.fetchTableData(null, this.state.pageSize, this.state.pageNum);
  };

  getFormParam() {
    return this.form?.getFieldsValue();
  };

  resetDataSource() {
    this.setState({
      dataSource: [...this.state.dataSource]
    });
  };

  /**
   * 获取选中的rowKey, 该方法提供给父组件使用
   * @returns rowKey列表
   */
  getSelectedRowKeys() {
    return this.state.selectedRowKeys;
  };

  /**
   * 获取选中行的某个属性, 该方法提供给父组件使用
   * @returns rowKey列表
   */
  getSelectedRowAttr(attr: string = 'id') {
    const attrArr: any[] = [];
    const keyArr: any[] = this.getSelectedRowKeys() || [];
    const currentTableDataArr: JSONObject[] = this.getTableData();

    keyArr.forEach(key => {
      currentTableDataArr.forEach(item => {
        if (item.id === key) {;
          attrArr.push(item[attr]);
        }
      })
    })

    return attrArr;
  };

  /**
   * 获取查询面板有效参数，该方法提供给父组件使用
   * @returns 查询参数面板参数
   */
  getQueryPanelParams() {
    let queryPanelParams = this.form?.getFieldsValue();

    if (queryPanelParams) {
      const keys = Object.keys(queryPanelParams);
      for (var key of keys) {
        const val = queryPanelParams[key];
        if (val === undefined || val === null) {
          delete queryPanelParams[key];
        }
      }
    };

    return queryPanelParams;
  }

   /**
   * 获取表格选中行数据
   * @returns 表格所有数据
   */
  getSelectedRowData() {
    const selectedIDs: any[] = this.getSelectedRowKeys() || [];
    const currentTableData: JSONObject[] = this.getTableData();
    const filteredArr = currentTableData.filter(invoice => selectedIDs.includes(invoice.id));
    return filteredArr;
  }

  /**
   * 获取表格所有的数据
   * @returns 表格所有数据
   */
  getTableData() {
    return this.state.dataSource ?? [];
  }

  /**
   * 新增临时行数据
   * @param data 要新增的行数据
   */
  addTemporaryData(data: JSONObject) {
    const oldTableData: JSONObject[] = this.getTableData() ?? [];
    data.id = oldTableData.length;

    this.setState({
      dataSource: [...oldTableData, data]
    });

    Message.success('新增成功');
  };

  /**
   * 修改临时行数据
   * @param data 新行数据
   * @param id 要修改行的 ID
   */
  editTemporaryData(data: JSONObject, id: number ) {
    const newTableData: JSONObject[] = [...this.state.dataSource];
    
    const editRowIndex = newTableData.findIndex(row => row.id === id);
    data.id = id;
    newTableData[editRowIndex] = data;

    this.setState({
      dataSource: newTableData
    });

    Message.success('修改成功');
  };

  /**
   * 删除临时行数据
   * @param ids 要删除的临时行的 IDs
   */
  deleteTemporaryData(ids: number[]) {
    const oldTableData: JSONObject[] = [...this.state.dataSource];
    const newTableData = oldTableData.filter(row => !ids.includes(row.id));

    this.setState({
      dataSource: newTableData
    });

    Message.success('删除成功');
  };

  setDataSource(dataSource: JSONObject[]) {
    this.setState({ dataSource })
  };

  componentDidMount() {
    // 将当前对象通过 callback 父组件函数传递给父组件
    if (this.props.onTableRef) {
      this.props.onTableRef(this);
    };

    const { url } = this.props;
    url && this.fetchTableData(null, this.state.pageSize, this.state.pageNum);
  }

  /** checkbox 变化 */
  onSelectChange(selectedRowKeys: [], selectedRows: []) {
    this.setState({ selectedRowKeys });
  };

  /**
   * @description 表格[分页、排序、筛选]变化时触发
   * @param pagination 分页选项, https://ant.design/components/pagination-cn/
   * @param filters 表头的筛选菜单项,结构:{key1:[v1,v2], key2:[v1,v2]} https://ant.design/components/table-cn/#Column
   * @param sorter 排序选项
   * @param extr 额外信息, 仅有 currentDataSource, [[row1], [row2]]
   */
  handOnTableChange(pagination: any, filters: any, sorter: any, extr: any) {
    let queryParams: any = {};
    let { current, pageSize } = pagination;

    // 收集表头 [筛选值] 参数值
    if (filters) {
      for (let name in filters) {
        const valueArr = filters[name];
        if (valueArr) {
          const filterKey = this.state.filterKeys[name];
          queryParams[filterKey] = valueArr.join(",");
        }
      };
    };

    // 收集表头 [排序字段] 参数值, 每次仅支持一个排序字段. antd order 可选值:[descend, ascend]
    if (sorter && sorter.column) {
      const field = sorter.field;
      const order = sorter.order === "descend" ? "desc" : "asc";
      queryParams["sort"] = field + "_" + order;
    } else {
      queryParams["sort"] = ""
    }


    this.setState({ pageSize: pageSize });

    this.fetchTableData(queryParams, pageSize, current);
  }

  /**
   * 发送请求查询数据
   * @param tableHeaderParams Table Header 上查询参数, 主要为分页、状态筛选两种
   * @param pageSize
   * @param pageNum
   */
  fetchTableData(tableHeaderParams?: any, pageSize: number = 10, pageNum: number = 1) {
    const { 
      url = '',
      querySpecialFormat = {},
      getTableSummaryInfo = () => {},
      pagination = true,
      method = 'get',
      formAdditionalParameters,
      setTableSummaryData,
      setDataSourceHierarchy
    } = this.props;
    
    // 删除查询面板参数中的空值
    let queryPanelParams = this.form?.getFieldsValue() || {};

    if (queryPanelParams) {
      const keys = Object.keys(queryPanelParams);
      for (var key of keys) {
        const val = queryPanelParams[key];
        if (typeof val === 'undefined') {
          delete queryPanelParams[key];
        };

        if (val === null) {
          delete queryPanelParams[key];
        };
      };
    };

    // 处理特殊查询面板格式
    if (Object.keys(querySpecialFormat).length !== 0) {
      queryPanelParams = { ...queryPanelParams, ...querySpecialFormat};
    };

    // 查询参数 = 表头查询参数 + 查询面板参数 + 分页参数
    let queryParams = { ...tableHeaderParams, ...queryPanelParams };
    queryParams[FILED_PAGE_SIZE] = pageSize;
    queryParams[FILED_PAGE_NUM] = pageNum;

    const _this = this;
    this.setState({ loading: true });

    // 获取表格汇总数据
    getTableSummaryInfo({ ...queryPanelParams });

    Http[method](url, queryParams)
    .then((data: any) => {
      pagination
        ? _this.setState({
            dataSource: data.content,
            pageNum: data.pageNum,
            total: data.total
          })
        : _this.setState({
          dataSource: setDataSourceHierarchy ? setDataSourceHierarchy(data) : data
        })

      setTableSummaryData && setTableSummaryData(data);
    }).finally(() => {
      _this.setState({ loading: false })
    });
  };

  /**
   * 点击查询按钮发起查询
   */
  handOnQuery() {
    this.fetchTableData(null, this.state.pageSize, 1);
  }

  /**
   * 重置所有查询条件
   */
  handOnReset() {
    this.form?.resetFields();
    this.fetchTableData(null, 10, 1);
  }

  render() {
    const {
      pageNum, 
      total, 
      pageSizeOptions, 
      pageSize, 
      columns, 
      hideOnSinglePage,
      dataSource, 
      loading, 
      xScroll,
      selectedRowKeys
    } = this.state;

    const {
      isExpandAllItem = false,
      children, 
      pagination = true, 
      rowKey = 'id',
      scroll = {},
      queryProps = {},
      rowSelection,
      summary,
      ...others
    } = this.props;

    let currentRowSelection: any = {};
    let defaultRowSelection: any = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    currentRowSelection = 
      rowSelection 
        ? { ...defaultRowSelection, ... rowSelection} 
        : defaultRowSelection;

    // 如果 selectedRowKeys 未提供任意值, 则不展示 checkbox
    if (!selectedRowKeys) {
      currentRowSelection = undefined
    };

    return (
      <Fragment>
        {this.props.children &&
          <Form 
            {...queryProps}
            name='form'
            onFinish={this.handOnQuery}
            ref={form => this.form = form}>
            <Query isExpandAllItem={isExpandAllItem} onReset={this.handOnReset}>
              {children}
            </Query>
          </Form>
        }

        <AntdTable 
          {...others} 
          rowSelection={currentRowSelection}
          rowKey={rowKey}
          columns={columns}
          dataSource={dataSource}
          onChange={this.handOnTableChange}
          loading={loading}
          pagination={
            pagination
              ? {
                  // 只有一页时是否隐藏分页器
                  hideOnSinglePage: hideOnSinglePage,
                  // 	是否可以快速跳转至某页
                  showQuickJumper: true,
                  // 展示 pageSize 切换器
                  showSizeChanger: true,
                  current: pageNum,
                  total,
                  pageSize,
                  pageSizeOptions,
                  // 用于显示数据总量和当前数据顺序
                  showTotal: (total) => (`共 ${total} 条`)
                }
              : false
          }
          summary={
            summary 
              ? (data) => summary(data, selectedRowKeys)
              : undefined
          }
          scroll={{
            ...scroll,
            x: xScroll
          }}
        />
      </Fragment>
    )
  }
}

export { Table }
