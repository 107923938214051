import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';

import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zh_CN from 'antd/lib/locale-provider/zh_CN';

import GC from '@grapecity/spread-sheets';
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css';
import '@grapecity/spread-sheets-resources-zh';

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { devToolsEnhancer, EnhancerOptions } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
// 默认 localStorage for web, sessionStorage 可使用 "import storageSession from 'redux-persist/lib/storage/session'"
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { rootReducer } from './redux/reducer';

import initState from './redux/initState';
import App from './App';

moment.locale('zh-cn');
GC.Spread.Common.CultureManager.culture('zh-cn');

const persistConfig = {
  key: 'root',
  storage: storage,
  //manualPersist: true, // 使用手工持久化, 使用后需要手工执行 persistor.persist()
}

const enhancerOptions: EnhancerOptions = {}
const myPersistReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(myPersistReducer, initState, devToolsEnhancer(enhancerOptions))
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={zh_CN}>
        <App />
      </ConfigProvider>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('root')
);

export { store }
