import React, { FC } from 'react'

import { Input } from 'antd'
import { ColumnProps } from '@comps/types'

import { Page, Card, Table } from '@comps'

const EmployeeList: FC = () => {

    const columns: ColumnProps[] = [
        { title: '员工编码', key: "employeeNo", },
        { title: '姓名', key: "fullName", },
        { title: '主岗部门', key: "deptName", },
        { title: '主岗', key: "positionName", },
        { title: '用户名', key: "userName", },
    ];

    return (
        <Page>
            <Card title='员工列表'>
                <Table columns={columns} url='/hr/employees'>
                    <Input name='employeeNo' placeholder='员工编码' />
                    <Input name='fullName' placeholder='姓名' />
                    <Input name='deptName' placeholder='主岗部门' />
                    <Input name='positionName' placeholder='主岗' />
                </Table>
            </Card>
        </Page >
    )
}

export { EmployeeList }