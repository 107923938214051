import { F_ACTION_LOGIN, F_ACTION_LOGOUT, F_ACTION_TOKEN_EXPIRE, F_ACTION_PROFILE, F_ACTION_SET_PANES } from '../actions'
import { ReduxAction } from '@models'

/**
 * context 相关, 包含用户登陆, token管理
 */
export default function contextReducer(prevState = {}, action: ReduxAction) {
    const type = action.type
    const data = action.data
    switch (type) {
        // 通过登陆拿到的 token 获取用户上下文
        case F_ACTION_LOGIN:
            //const token = action.token

            return Object.assign({}, prevState, { ...data })

        // logout    
        case F_ACTION_LOGOUT:
            return Object.assign({}, prevState, { logined: false })

        // token 失效
        case F_ACTION_TOKEN_EXPIRE:
            return Object.assign({}, prevState, { logined: false, token: null })

        // 全局配置
        case F_ACTION_PROFILE:
            return Object.assign({}, prevState, { ...data })

        // 设置 Panes
        case F_ACTION_SET_PANES:
            return Object.assign({}, prevState, { ...data })

        default:
            return prevState;
    }
}