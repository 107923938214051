import * as React from 'react';
import { ModalFuncProps } from 'antd/es/modal/Modal';
import ConfirmDialog from '../ConfirmDialog';
import defaultLocale from 'antd/es/locale/default';
import LocaleReceiver from 'antd/es/locale-provider/LocaleReceiver';

export interface HookModalProps {
  afterClose: () => void;
  config: ModalFuncProps;
}

export interface HookModalRef {
  destroy: () => void;
  update: (config: ModalFuncProps) => void;
}

interface ModalLocale {
  okText: string;
  cancelText: string;
  justOkText: string;
}

const HookModal: React.RefForwardingComponent<HookModalRef, HookModalProps> = (
  { afterClose, config },
  ref,
) => {
  const [visible, setVisible] = React.useState(true);
  const [innerConfig, setInnerConfig] = React.useState(config);

  function close() {
    innerConfig?.onCancel?.();
    setVisible(false);
  }

  React.useImperativeHandle(ref, () => ({
    destroy: close,
    update: (newConfig: ModalFuncProps) => {
      setInnerConfig(originConfig => ({
        ...originConfig,
        ...newConfig,
      }));
    },
  }));

  const child: any = (modalLocale: ModalLocale) => (
    <ConfirmDialog
      {...innerConfig}
      close={close}
      visible={visible}
      afterClose={afterClose}
      okText={
        innerConfig.okText ||
        (innerConfig.okCancel ? modalLocale.okText : modalLocale.justOkText)
      }
      cancelText={innerConfig.cancelText || modalLocale.cancelText}
    />
  );

  return (
    <LocaleReceiver componentName="Modal" defaultLocale={defaultLocale.Modal}>
      {child}
    </LocaleReceiver>
  );
};

export default React.forwardRef(HookModal);
