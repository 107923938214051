import React, { FC } from 'react';
import { Upload, Select } from 'antd';

import { Context, Message, Env } from '@utils';

interface UploadFileProps {
  label: string,
  acceptFileType: string,
  sourceType: number,
  refreshTable: () => void,
  setLoading: (isLoading: boolean) => void
};

const UploadFile:FC<UploadFileProps> = ({
  label,
  acceptFileType,
  sourceType,
  refreshTable,
  setLoading
}) => {
  return (
    <Upload
      action={`${Env.getBaseUrl()}/inputInvManagement/uploadInvoice`}
      data={{ sourceType }}
      name="files"
      multiple={true}
      showUploadList={false}
      accept={acceptFileType}
      headers={{
        Authorization: Context.getToken()
      }}
      onChange={async ({ file: { status, response = {} } }) => {
        setLoading(true);
        
        if (!['done', 'error'].includes(status as string)) {
          return;
        }

        const { data = {}, code, msg } = response;
        setLoading(false);

        // 上传失败
        if (String(code) !== '200') {
          Message.error(msg || '未知错误, 请联系管理员');
          return;
        }

        // 上传成功
        refreshTable();
        Message.success('录入成功');

      }}
    >
      <span>{label}</span>
    </Upload>
  )
}

export default UploadFile;
