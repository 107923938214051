import * as React from 'react';
import { Select, Space, Form, Input, InputNumber } from 'antd';
import { Card, Button } from '@comps'
import { Http } from '@utils'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

interface CategoryKV {
  k: number,
  v: string,
}

export interface Props {
  onCancel: () => void,
  onConfirm: () => void,
}

const AddCategory: React.FC<Props> = ({
  onCancel, onConfirm
}) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = React.useState<CategoryKV[]>([]);

  React.useEffect(() => {
    ((async () => {
      const _categories = await Http.get<CategoryKV[]>('/tax/goods/categories/kv')
      setCategories(_categories || []);
    })());
  }, []);

  async function handleSave() {
    await form.validateFields();
    const submitData = form.getFieldsValue();
    await Http.post('/tax/goods/categories', submitData);
    onConfirm();
  }

  return (
    <Card title='新增商品类别' style={{ marginBottom: '0' }}>
      <Form {...layout} form={form} name="register" initialValues={{}}>
        <Form.Item label="上级类别" name='parentId'>
          <Select options={categories.map(({ k, v }) => ({ label: v, value: k }))} />
        </Form.Item>
        <Form.Item label="类别名称" name='goodsName' rules={[{
          required: true, message: '请输入类别名称',
        }]}>
          <Input />
        </Form.Item>
        <Form.Item label="序号" name='seq' rules={[{
          required: true, message: '请输入序号',
        }]}>
          <InputNumber step={1} min={0} precision={0} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Space>
            <Button type="primary" clickCallback={handleSave}>保存</Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddCategory;
