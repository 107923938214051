import * as React from 'react';
import { Select, Space, Form, Input, InputNumber, Row, Col, Radio } from 'antd';
import { Card, Button, Kv } from '@comps'
import { Http } from '@utils'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export interface Props {
  onCancel: () => void,
  onConfirm: () => void,
  good?: JSONObject,
}

const Add: React.FC<Props> = ({
  onCancel, onConfirm, good = {},
}) => {
  const [form] = Form.useForm();
  const [categories, setCategories] = React.useState<KV[]>([]);
  const [rates, setRates] = React.useState<KV[]>([]);
  const [taxCodes, setTaxCodes] = React.useState<KV[]>([]);
  const [policies, setPolicies] = React.useState<KV[]>([]);

  React.useEffect(() => {
    ((async () => {
      const [_categories, _rates, _taxCodes, _policies] = await Promise.all([
        Http.get<KV[]>('/tax/goods/categories/kv'),
        Http.get<KV[]>('/tax/rates/kv'),
        Http.get<KV[]>('/tax/taxCodes/kv'),
        Http.get<KV[]>('/tax/policies/discountsKv')
      ]);
      setCategories(_categories || []);
      setRates(_rates);
      setTaxCodes(_taxCodes);
      setPolicies(_policies);
    })());
  }, []);

  async function handleSave() {
    await form.validateFields();
    const submitData = {
      ...good,
      ...form.getFieldsValue()
    };
    submitData.leafFlag = true;
    if (submitData.id) {
      await Http.put('/tax/goods', submitData);
    } else {
      await Http.post('/tax/goods', submitData);
    }
    onConfirm();
  }

  return (
    <Card title={good.id ? '修改商品' : '新增商品'} style={{ marginBottom: '0' }}>
      <Form {...layout} form={form} initialValues={good}>
        <Row>
          <Col span={12}>
            <Form.Item label="类别" name='parentId' rules={[{
              required: true, message: '请选择类别',
            }]}>
              <Select showSearch optionFilterProp="label"
                options={categories.map(({ k, v }) => ({ label: v, value: k }))} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="商品编码" name='goodsCode'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="商品名称" name='goodsName' rules={[{
              required: true, message: '请输入商品名称',
            }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="规格型号" name='spec'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="计量单位" name='unit'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="单价" name='unitPrice'>
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="税率(%)" name='taxRate' rules={[{
              required: true, message: '请选择税率',
            }]}>
              <Select showSearch optionFilterProp="label"
                options={rates.map(({ k, v }) => ({ label: v, value: k }))} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="单价是否含税" name='includeTaxFlag' rules={[{
              required: true, message: '请选择单价是否含税',
            }]}>
              <Radio.Group
                options={[
                  { label: '含税', value: true },
                  { label: '不含税', value: false },
                ]} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="享受税收优惠政策" name='hasDiscountPolicy' rules={[{
              required: true, message: '请选择享受税收优惠政策',
            }]}>
              <Radio.Group
                options={[
                  { label: '享受', value: true },
                  { label: '不享受', value: false },
                ]} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="税收分类编码" name='taxCode' labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} rules={[{
              required: true, message: '请选择税收分类编码',
            }]}>
              <Kv url='/tax/taxCodes/kv' />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item shouldUpdate noStyle>
            {() => {
              return form.getFieldValue('hasDiscountPolicy') ? (<Col span={12}>
                <Form.Item label="税收优惠政策类型" name='policyId' rules={[{
                  required: true, message: '请选择税收优惠政策类型',
                }]}>
                  <Select showSearch optionFilterProp="label"
                    options={policies.map(({ k, v }) => ({ label: v, value: k }))} />
                </Form.Item>
              </Col>) : undefined;
            }}
          </Form.Item>
        </Row>
        <div style={{ textAlign: 'center' }}>
          <Space>
            <Button type="primary" clickCallback={handleSave}>保存</Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default Add;
