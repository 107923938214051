import * as React from 'react';
import { Card, Button } from '@comps';
import { Form, Space, Input } from 'antd';
import { Http, Lang } from '@utils';

export interface Props extends React.Props<HTMLElement>,
  React.BaseHTMLAttributes<HTMLElement> {
  id: string,
  onConfirm: () => void,
  onClose: () => void,
};

const LinesMd: React.FC<Props> = ({ id, onClose, onConfirm }: Props) => {
  const [form] = Form.useForm();

  const handleConfirm = React.useCallback(() => Lang.noError(async () => {
    await form.validateFields();
    const formData = form.getFieldsValue();
    await Http.put(`/tax/changes/${id}/reject`, {
      approveRemarks: formData.approveRemarks,
    });
    onConfirm();
  }), [form, id, onConfirm]);

  return (<Card title='请输入拒绝原因' style={{ marginBottom: '0' }}>
    <Form form={form} labelCol={{ span: 6 }}
      style={{ padding: '30px 60px' }}>
      <Form.Item label="拒绝原因"
        name="approveRemarks"
        rules={[{ required: true, message: '请输入拒绝原因' }]}>
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6 }}>
        <Space>
          <Button type="primary"
            clickCallback={handleConfirm}>确认拒绝</Button>
          <Button onClick={() => onClose()}>取消</Button>
        </Space>
      </Form.Item>
    </Form>
  </Card>);
};

export default LinesMd;
