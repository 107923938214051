import React, { Component, Fragment } from 'react'
import { Row, Col, Space, Spin } from 'antd'
import './index.css'

interface Props extends React.HTMLAttributes<any> {
    title?: string,
    actions?: React.ReactNode,
    loading?: boolean,
}

export default class Card extends Component<Props> {

    static defaultProps = {
        loading: false,
    }

    render() {
        return (
            <Spin spinning={this.props.loading}>
                <div className='card' style={this.props.style}>
                    <div className='card-header'>
                        <Row>
                            <Col span={8}>
                                {this.props.title &&
                                    <Fragment>
                                        <span className='card-header-logo'>|</span>
                                        <span className='card-header-title'> {this.props.title}</span>
                                    </Fragment>
                                }
                            </Col>
                            <Col span={16}>
                                {this.props.actions &&
                                    <Space style={{ float: 'right' }}>
                                        {this.props.actions}
                                    </Space>}
                            </Col>
                        </Row>
                    </div>
                    <div className='card-content'>
                        {this.props.children}
                    </div>
                </div>
            </Spin>
        )
    }
}
