import React, { FC, useState, useEffect, Fragment } from 'react'
import { Page, Table, Card, LinkText, Modal, DisplayInput, Kv } from '@comps'
import { Layout, Http, Message, Api } from '@utils'
import { ColumnProps } from '@comps/types'
import { Button, Space, Form, Input, Checkbox, Select } from 'antd'
import { Link } from 'react-router-dom'

const Item = Form.Item

const RoleList: FC = () => {
    const [form] = Form.useForm()
    let [isModalVisible, setModalVisible] = useState(false)
    let [selectedRoleId, setSelectedRoleId] = useState(-1)
    const [menuOptions, setMenuOptions] = useState<any>()
    let [table, setTable] = useState<Table>()

    useEffect(() => {
        Api.getKv('/sys/menus/rootMenusKv').then(data => {
            setMenuOptions(data)
        })
    }, [])

    async function saveRole() {
        await form.validateFields()
        let values = form.getFieldsValue()
        if (selectedRoleId < 0) {
            await Http.post('/sys/roles', values)
        } else {
            await Http.put('/sys/roles', { ...values, id: selectedRoleId })
        }
        Message.saveOk()
        setModalVisible(false)
        form.resetFields()
        table?.refresh()
    }

    const onAddOrEditClick = (roleId: number = -1, role?: any) => {
        setSelectedRoleId(roleId)
        setModalVisible(true)
        if (roleId > 0) {
            form.setFieldsValue({ ...role })
        }
        table?.refresh()
    }

    const onCancel = () => {
        form.resetFields();
        setModalVisible(false)
    }

    const onTableRef = (tableRef: Table) => {
        table = tableRef
        setTable(tableRef)
    }

    const columns: ColumnProps[] = [
        {
            key: 'tenantName',
            title: '租户名称',
        },
        {
            key: 'roleCode',
            title: '角色编码',
        },
        {
            key: 'roleName',
            title: '角色名称',
        },
        {
            key: 'enabled',
            title: '启用',
            render: (value) => (value ? '启用' : '禁用'),
        },
        {
            key: 'id',
            title: '操作',
            render: (id, row, index) => (
                <Space size='large'>
                    <Link to={`/sys/menus/${row.pcMenuId}/roles/${id}`}>PC菜单</Link>
                    {/* <Link to={`/sys/menus/${row.wxMenuId}/roles/${id}`}>公众号菜单</Link> */}
                    <LinkText label='修改' onClick={() => onAddOrEditClick(id, row)}></LinkText>
                </Space>),
        },
    ]
    return (
        <Page>
            <Card title='角色管理' actions={<Button type='primary' onClick={() => onAddOrEditClick()}>新增</Button>}>
                <Table url='/sys/roles' columns={columns} rowKey='id' onTableRef={onTableRef}>
                    <Item name='tenantId'>
                        <Kv url='/sys/tenants/kv' placeholder='租户' style={{ width: '100%' }} />
                    </Item>
                    <Input name='roleName' placeholder='角色名称' />
                </Table>
            </Card>

            <Modal title='角色管理' visible={isModalVisible}
                onCancel={onCancel}
                onOk={saveRole}>
                <Form name='form' form={form} {...Layout.modal_1c}>
                    {selectedRoleId < 0 &&
                        <Fragment>
                            <Item name='tenantId' label='租户' rules={[{ required: true }]}><Kv url='/sys/tenants/kv' /></Item>
                            <Item name='roleCode' label='角色编码' rules={[{ required: true }]}><Input /></Item>
                        </Fragment>
                    }
                    {selectedRoleId >= 0 &&
                        <Fragment>
                            <Item name='roleCode' label='角色编码'><DisplayInput /></Item>
                            <Item name='tenantName' label='租户'><DisplayInput /></Item>
                        </Fragment>
                    }
                    <Item name='roleName' label='角色名称' required><Input /></Item>

                    {selectedRoleId < 0 && <Item name='pcMenuId' label='PC端菜单树' rules={[{ required: true }]}><Select options={menuOptions} /></Item>}
                    <Item name='enabled' label='启用' valuePropName='checked' rules={[{ required: true }]}><Checkbox /></Item>
                </Form>
            </Modal>
        </Page>
    )
}

export { RoleList }