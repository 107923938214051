import React from "react";
import { Tag } from 'antd';

import { ColumnProps } from './table.d';
import { DateUtils, Math } from '@utils';

/** 处理 Column 格式 */
const handleColumnFormat = (column: ColumnProps) => {
  if (column.format && !column.render) {
    switch (column.format) {
      case 'amount':
          column.align = 'right';
          column.render = (value: number) => Math.num2money(value);
          break;
      case 'quantity':
          column.align = 'right';
          column.render = (value: number) => Math.numThousandth(value);
          break;
      case 'date':
          column.render = (value: string) => DateUtils.date2str(value);
          break;
      case 'datetime':
          column.render = (value: string) => DateUtils.datetime2str(value);
          column.width = 200;
          break
      case 'percent':
          column.render = (value: number) => Math.num2Percent(value);
          break;
      default:
    }
  }
};

/** 处理 Tag 字段 */
const DEFAULT_TAG_COLOR: string = 'geekblue';
const handleColumnTag = (column: ColumnProps) => {
  const tag = column.tag;
  
  if (tag) {
    const color = 
      tag.color 
        ? tag.color 
        : DEFAULT_TAG_COLOR;

    const tagColors = 
      tag.colors 
        ? tag.colors 
        : {};

    column.render = (value: string) => (
      <span>
        <Tag color=
          {
            tagColors[value] 
              ? tagColors[value] 
              : color
          }
        >
          {value}
        </Tag>
      </span>
    )
  }
};

const TableUtil = {
  handleColumnFormat,
  handleColumnTag
}

export { TableUtil };