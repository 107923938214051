import React, { FC } from 'react';
import { Modal as AdModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

const Modal: FC<ModalProps> = (props) => {
    return (
        <AdModal {...props} >
            {props.children}
        </AdModal>
    )
}

export default Modal
