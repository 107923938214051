import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Select, Button } from 'antd';
import './index.css';

interface Props {
  ref: any,
  totalPage: number,
  pageSizeOptions?: number[], 
  fetchTableData: (paramObj: JSONObject) => void
}

const defaultPageSizeOptions: number[] = [100, 300, 500, 1000];

const SheetPagination: React.FC<Props> = forwardRef(({ 
  totalPage, 
  pageSizeOptions = defaultPageSizeOptions, 
  fetchTableData 
}: Props, ref) => {
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const refPageSize = useRef(pageSizeOptions[0]);

  const [pageNum, setPageNum] = useState(1);
  const refPageNum = useRef(1);

  const handlePageSizeChange = (_pageSize: any) => {
    setPageSize(_pageSize);
    refPageSize.current = _pageSize;
    fetchTableData({ pageNum, pageSize: _pageSize })
  }

  const handleTurnPage = (turnPage: number) => {
    const currentPageNum = pageNum + turnPage;
    if (currentPageNum === 0) {
      return;
    }

    setPageNum(currentPageNum);
    refPageNum.current = currentPageNum;

    fetchTableData({ pageNum: currentPageNum, pageSize })
  }

  useImperativeHandle(ref, () => ({
    pageNum,
    pageSize
  }))

  return (
    <div className="pagination">
      <span style={{ marginRight: 20 }}>共 {totalPage} 条</span>
      <Select style={{ width: '110px' }}
              value={pageSize}
              onSelect={handlePageSizeChange}
              options={pageSizeOptions.map(pageSizeOption => ({ label: `${pageSizeOption} 条/页`, value: pageSizeOption }))} />
      <div style={{ marginLeft: 20 }}>
        <Button onClick={() => handleTurnPage(-1)}>&lt;</Button>
        <span style={{ paddingLeft: 10, paddingRight: 10 }}>{pageNum}</span>
        <Button onClick={() => handleTurnPage(1)}>&gt;</Button>
      </div>
    </div>
  )
})

export default SheetPagination
