import * as React from 'react';
import { Http } from '@utils';
import Select, { Props as SelectProps } from '../Select';

export interface Prop extends SelectProps<string> {
  lookupType: string,
  showOptionTitle?: boolean
}

const Lookup: React.FC<Prop> = ({
  showOptionTitle = false,
  lookupType,
  ...resetProps
}: Prop) => {
  const [kvList, setKvList] = React.useState<KV[]>([]);

  React.useEffect(() => {
    ((async () => {
      const kvs = await Http.get<KV[]>('/sys/lookups/kv', {
        lookupType,
      });
      setKvList(kvs);
    })());
  }, [lookupType]);
  return (<Select {...resetProps} options={kvList.map(kv => ({ label: kv.v, value: kv.k, title: showOptionTitle ? kv.v : '' }))} />);
};

export default Lookup;
