import React, { FC, useEffect, useState } from 'react';
import { Form, Row, Col, Button, DatePicker, Space, Input } from 'antd';

import { Card, DisplayInput, HiddenFormItem } from '@comps';

import { Http, Message, DateUtils } from '@utils';
import moment from 'moment';

const { Item } = Form;

interface Props {
  rowData: JSONObject,
  refreshTable: () => void,
  onClose: () => void
};

const BookkeepingMD: FC<Props> = ({
  rowData,
  refreshTable,
  onClose
}) => {
  
  const [form] = Form.useForm();

  const [bookKeepingLoading, setBookKeepingLoading] = useState(false);

  useEffect(() => {
    setFieldsVal();
  }, []);
 
  const dateStrToMoment = (dateStr: string) : moment.Moment => {
    let validDateStr = dateStr.split(' ')[0];

    validDateStr = validDateStr.replace(/\//g, '');

    return moment(validDateStr);
  };

  const setFieldsVal = () => {
    const { reimburseBy, reimburseTime } = rowData;

    let momentDate = null;
    if (reimburseTime) {
      momentDate = dateStrToMoment(reimburseTime);
    }

    form.setFieldsValue({ 
      ...rowData, 
      reimburseUser: reimburseBy, 
      reimburseTime: momentDate,
    });
  };

  const handleBookKeeping = () => {
    setBookKeepingLoading(true);

    const formData = form.getFieldsValue();
    const { reimburseTime } = formData;
    if (reimburseTime) {
      formData.reimburseTime = DateUtils.momentToStr(reimburseTime, 'YYYY-MM-DD');
    } 

    Http.post('/input/invPool/keepAccounts', formData)
      .then(() => {
        refreshTable();
        onClose();
        Message.success('记账成功');
      })
      .finally(() => {
        setBookKeepingLoading(false);
      })
  }

  return (
    <Card title="记账">
      <Form 
        form={form} 
        labelCol={{ span: 5 }}
        onFinish={handleBookKeeping}
      >
        <Row>
          <Col span={24}>
            <HiddenFormItem name="id" />
            <Item label="发票代码" name="invoiceCode">
              <DisplayInput />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item label="发票号码" name="invoiceNo">
              <DisplayInput />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item label="发票类型" name="invoiceTypeDesc">
              <DisplayInput />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item 
              label="报销人" 
              name="reimburseUser" 
              rules={[{ required: true }]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item label="凭证号" name="voucherNo">
              <Input />
            </Item>
          </Col>
        </Row> 
        <Row>
          <Col span={24}>
            <Item 
              label="报销日期" 
              name="reimburseTime"
              rules={[{ required: true }]}
            >
              <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space>
              <Button 
                type="primary" 
                loading={bookKeepingLoading}
                htmlType="submit"
              >
                确定
              </Button>
             <Button onClick={onClose}>取消</Button>
           </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  )
};

export default BookkeepingMD;