import React, { FC, useEffect } from "react";
import { Page } from "@comps";
import { Context, Http } from "@utils";

const InvLogin:FC = () => {

  useEffect(() => {
    getUrl()
    Context.closeTabByTitle("发票开具")
  })

  const getUrl = () => {
    Http.get("/tax/outputInvoices/getBillingUrl").then(res => {
      window.open(res, "_blank")
    })
  }

  return (
    <div></div>
  )
}

export default InvLogin