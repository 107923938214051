import React, { Component, Fragment } from 'react'
import { Input, Space } from 'antd'
import { EditOutlined } from '@ant-design/icons'

interface Props {
    dvalue?: String,
    editable?: boolean,
}

interface State {
    editMode?: boolean,
}

class DisplayItem extends Component<Props, State> {
    readonly state: State = {
        editMode: false,
    }

    render() {
        const { dvalue, editable } = this.props
        const { editMode } = this.state
        let displayComps =
            <Fragment>
                <Space>
                    <span>{dvalue}</span>
                    {editable && <EditOutlined onClick={() => { this.setState({ editMode: true }) }} />}
                </Space>
            </Fragment>

        let editComps =
            <Fragment>
                <Space>
                    <Input />
                    <span style={{ color: '#006eff' }}>保存</span>
                    <span style={{ color: '#006eff' }} onClick={() => { this.setState({ editMode: false }) }}>取消</span>
                </Space>
            </Fragment>

        let renderComps
        if (editable) {
            renderComps = !editMode ? displayComps : editComps
        } else {
            renderComps = displayComps
        }

        return (
            renderComps
        )
    }
}

export { DisplayItem }