import * as React from 'react';
import { useSelector } from 'react-redux';
import { UserContext } from '@models'
import Button, { Props as ButtonProps } from '../Button';

export interface Props extends ButtonProps {
  permissions: string | string[],
  /**
   * 禁止权限验证, 用于开发时
   */
  disabledPermission?: boolean,
};

/**
 * 按钮组件
 */
const PermissionButton: React.FC<Props> = ({
  permissions, children,
  disabledPermission = false,
  ...other
}) => {
  const { permissions: userPermissions = [] } = useSelector<JSONObject, UserContext>((state) => {
    return state.ctx;
  });

  const hasPermissions = React.useMemo(() => {
    const _permissions = Array.isArray(permissions) ? permissions : [permissions];
    return _permissions
      .findIndex(permission => userPermissions.includes(permission)) > -1;
  }, [permissions, userPermissions]);

  if (!hasPermissions && !disabledPermission) {
    return (<span></span>);
  }

  return (<Button {...other}>
      {children}
    </Button>);
};

export default PermissionButton;
