import React, { FC, Fragment } from 'react';
import CacheRoute from 'react-router-cache-route';
import { Route } from 'react-router-dom';
import { routes } from './metadata/routes';
import { connect } from 'react-redux';

import { PrivateRoute, BrowserRouter } from './routes';
import MainLayout from './components/Layout/index';
import PublicLayout from './components/Layout/PublicLayout';

import { MessageQueue } from '@utils';

import 'antd/dist/antd.less';
import './styles/global.css';

const RouteComp: FC = () => {
  return (
    <Fragment>
      {
        routes.map((route, index) => (
          route.private 
            ? <PrivateRoute 
                key={index} 
                path={route.path} 
                component={route.component} 
                isCancelCache={route.isCancelCache}
              /> 
            : route.isCancelCache
                ? <Route 
                    exact 
                    key={index} 
                    path={route.path} 
                    component={route.component} 
                  />
                
                :  <CacheRoute 
                    cacheKey={route.path} 
                    exact 
                    key={index} 
                    path={route.path} 
                    component={route.component} 
                  />

                
        ))
      }
    </Fragment>
  );
};

const App: FC = (props: any) => {

  const { logined } = props;
  const startListenMQ = MessageQueue.useListen([]);

  React.useEffect(() => {
    startListenMQ();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      {
        logined
          ? <MainLayout><RouteComp /></MainLayout>
          : <PublicLayout><RouteComp /></PublicLayout>
      }
    </BrowserRouter>
  );
};

const mapStateToProps = (state: any) => {
  return {
    logined: state.ctx.logined,
  };
};

export default connect(() => mapStateToProps)(App);
