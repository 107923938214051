import React, { FC, useEffect, useState } from 'react'

import { Table } from 'antd'
import { Page, Card, Download } from '@comps'
import { Http } from '@utils'
import { ColumnsType } from 'antd/es/table';

const AppLogList: FC = () => {
    const [ds, setDs] = useState<JSONObject[]>([]);
    useEffect(() => {
        Http.get('/sys/admin/logFiles').then(data => {
            setDs(data)
        })
    }, []);

    const columns: ColumnsType<any> = [
        { title: '序号', dataIndex: 'fileId', render: (value, rec, index) => (index + 1) },
        { title: '日志文件', dataIndex: 'fileName' },
        { title: '文件大小', dataIndex: 'friendlySize' },
        { title: '最后更新时间', dataIndex: 'lastUpdateTime' },
        { title: '下载', dataIndex: 'fileId', render: (id) => (<Download url={`/sys/admin/downloadLog?fileId=${id}`} />) },
    ];

    return (
        <Page>
            <Card title='应用日志'>
                <Table columns={columns} dataSource={ds} rowKey='fileId' pagination={false} />
            </Card>
        </Page >
    )
}

export { AppLogList };