import React from 'react';
import { Form, Input, Checkbox, Space } from 'antd';

import { Card, Button } from '@comps'

import { Http, Message } from '@utils'

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 }
};

export interface Props {
  onCancel: () => void,
  onConfirm: () => void,
  contact?: JSONObject
};

const Add: React.FC<Props> = ({
  onCancel,
  onConfirm,
  contact = {}
}) => {

  const [form] = Form.useForm();

  const handleSave = async () => {
    await form.validateFields();

    const submitData = {
      ...contact,
      ...form.getFieldsValue()
    };

    if (submitData.id) {
      await Http.put('/org/customers/contacts', submitData);
    } else {
      await Http.post('/org/customers/contacts', submitData);
    }

    Message.ok();
    onConfirm();
  }

  return (
    <Card title='联系人' style={{ marginBottom: '0' }}>
      <Form
        {...layout}
        form={form}
        name='contact'
        initialValues={contact}
      >
        <Item
          label="姓名"
          name='fullName'
          rules={[{ required: true }]}
        >
          <Input />
        </Item>
        <Item
          label="电话"
          name='phone'
          rules={[{ required: true }]}
        >
          <Input />
        </Item>
        <Item
          label="邮箱"
          name='email'
          rules={[{ required: true }]}
        >
          <Input />
        </Item>
        <Item label="地址" name='address'>
          <Input />
        </Item>
        <Item
          label="默认联系人"
          name='defaultFlag'
          valuePropName='checked'
        >
          <Checkbox />
        </Item>
        <div style={{ textAlign: 'center' }}>
          <Space>
            <Button type="primary" clickCallback={handleSave}>保存</Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default Add;
