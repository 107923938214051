import React, { FC, Fragment, useState } from 'react';
import { Space, Form, DatePicker, Input, Button, Popconfirm, Select } from 'antd';
import { Link } from 'react-router-dom'

import { Page, Card, Table, Modal, PermissionButton, Lookup, Download, Upload, LinkText, HighBeatMeterUpload } from '@comps';
import { UploadFile } from './component';
import ManualAdd from './modal/ManualAdd';
import CheckResMD from './modal/CheckRes.md';
import CheckRemainingTimesMD from './modal/CheckRemainingTimes.md';
import EditRowMD from './modal/EditRow.md';
 
import { Http, Message, FormUtil } from '@utils';
import { ColumnProps } from '@comps/types';
import { EntryMethod } from './interface';

const { Item } = Form;
const { RangePicker } = DatePicker;
const { Option } = Select;

const RegisterList: FC = () => {

  const [form] = Form.useForm();

  const [modal, modalHolder] = Modal.useModal();

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
    table = tableRef; 
  };

  const [checkLoading, setCheckLoading] = useState(false);
  const [checkRemainingTimesLoading, setCheckRemainingTimesLoading] = useState(false);
  const [batchDelLoading, setBatchDelLoading] = useState(false);
  const [entryLoading, setEntryLoading] = useState(false);

  const showManualAddMD = async () => {
    await modal.open({
      width: '40%',
      content(resolve, reject) {
        return (<ManualAdd onConfirm={resolve} onCancel={reject} />);
      }
    })
      
    table?.refresh();
  };

  const handleDelete = (id: number) => {
    Http.del('/input/invoices/' + id)
      .then(() => {
        table?.refresh();
        Message.success('删除成功');
      })
  };

  const handleCheck = () => {
    const ids = table?.getSelectedRowKeys();

    if (ids?.length === 0) {
      Message.warn('请至少选择一条数据');
      return;
    }

    setCheckLoading(true);

    Http.post('/inputInvManagement/checkedInv', { ids })
      .then((data) => {
        modal.open({
          width: 400,
          content: (_1, reject) => (
            <CheckResMD checkResObject={data} onClose={reject} />
          )
        })
        table?.refresh();
      })
      .finally(() => {
        setCheckLoading(false);
      })
  };

  const showCheckRemainingTimesMD = () => {
    setCheckLoading(true);

    Http.get('/input/invoices/getCheckedTime')
      .then((data) => {
        modal.open({
          width: 400,
          content: (_1, reject) => (
            <CheckRemainingTimesMD 
              checkResObject={data} 
              onClose={reject} 
            />
          )
        })
        table?.refresh();
      })
      .finally(() => {
        setCheckLoading(false);
      })
  };

  const renderCheckStatus = (checkType: number) => {
    if (checkType === 0) {
      return <span style={{ color: '#333333' }}>未查验</span>
    } else if (checkType === 1) {
      return <span style={{ color: '#04d919' }}>已查验</span>
    } else if (checkType === 2) {
      return <span style={{ color: '#3facdf' }}>查验中</span>
    } else if (checkType === 3) {
      return <span style={{ color: '#d9001b' }}>查验失败</span>
    }
  }

  const handleBatchDel = () => {
    const ids = table?.getSelectedRowKeys();
    if (ids?.length === 0) {
      Message.warn('请至少勾选一条数据');
      return;
    }

    setBatchDelLoading(true);
    Http.del('/input/biz/delInvoice', { ids: ids?.join() })
      .then(() => {
        table?.refresh();
        Message.success('删除成功');
      })
      .finally(() => {
        setBatchDelLoading(false);
      })
  }

  const handleSummaryExport = () => {
    const ids = table?.getSelectedRowKeys();

    if (ids?.length === 0) {
      Message.warn('请至少选择一条数据');
      return;
    }
    
    FormUtil.exportExcelFile('/input/invoices/bizCollectExport', { ids });
  };

  const refreshTable = () => {
    table?.refresh();
  };

  const showEditMD = (rowDate: JSONObject) => {
    modal.open({
      width: 400,
      content: (_1, reject) => (
        <EditRowMD 
          rowData={rowDate} 
          refreshTable={refreshTable}
          onClose={reject} 
        />
      )
    });
  };

  const showHighBeatMeterUploadMD = () => {
    modal.open({
      width: 560,
      content: (_1, reject) => (
        <HighBeatMeterUpload refreshTable={refreshTable} onClose={reject} />
      )
    });
  };

  const resetSelect = () => {
    form.resetFields();
  };

  const BlankSpace: FC = () => {
    return (
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    );
  };

  const columns: ColumnProps[] = [
    { key: 'checked', title: '查验状态', width: 110, fixed: 'left',
      render: renderCheckStatus
    },
    { key: 'checkFailReason', title: '验证描述', width: 240 },
    { key: 'sourceDesc', title: '数据来源', width: 130 },
    { key: 'entryBy', title: '录入人', width: 130 },
    { key: 'arrivalStatus', title: '到票状态', width: 130,
      render: (arrivalStatus: boolean) => {
        return arrivalStatus ? '已到票' : '未到票'
      }
    },
    { key: 'invoiceCode', title: '发票代码', width: 160 },
    { key: 'invoiceNo', title: '发票号码', width: 130 },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 160 },
    { key: 'statusCodeDesc', title: '发票状态', width: 130, },
    { key: 'invoiceDate', title: '开票日期', width: 120 },
    { key: 'amount', title: '金额', width: 130, format: 'amount' },
    { key: 'taxAmount', title: '税额', width: 130, format: 'amount' },
    { key: 'withTaxAmount', title: '价税合计', width: 130, format: 'amount' },
    { key: 'sellerBank', title: '销方银行账号', width: 400 }, 
    { key: 'sellerName', title: '销方名称', width: 300 },
    { key: 'sellerTaxNo', title: '销方税号', width: 200 },
    { key: 'sellerAddrTel', title: '销方地址电话', width: 400 },
    {
      key: 'id', title: '操作', width: 150, fixed: 'right', align: 'center',
      render(id: number, row: JSONObject) {
        return (
          <Space size="large"> 
            {(row.checked === 1) 
              ? <Link to={`/income/register/${id}/detail`}>详情</Link>
              : <BlankSpace />
            }
            {(row.checked === 0) || (row.checked === 3) 
              ? <LinkText label="编辑" onClick={() => showEditMD(row)} />
              : <BlankSpace />
            }
            {(row.checked !== 1) 
              ? <Popconfirm 
                  title='您确认要删除吗?' 
                  onConfirm={() => handleDelete(id)}
                >
                  <LinkText label="删除" />
                </Popconfirm>
              : <BlankSpace />
            }
          </Space>
        )
      }
    }
  ];

  return (
    <Page>
      {modalHolder}
      <Card title="发票登记" actions={
        <Space>
          <Form form={form}>
            <Item name="entryMethod" style={{ marginBottom: 0 }}>
              <Select 
                placeholder="发票录入" 
                loading={entryLoading}
              >
               <Option value={EntryMethod.IMAGE}>
                 <UploadFile 
                   label="图片录入" 
                   acceptFileType=".jpg,.jpeg,.png" 
                   sourceType={11}
                   refreshTable={refreshTable} 
                   setLoading={setEntryLoading}
                 />
               </Option>
               <Option value={EntryMethod.PDF}>
                 <UploadFile 
                   label="PDF 导入" 
                   acceptFileType=".pdf" 
                   sourceType={6} 
                   refreshTable={refreshTable} 
                   setLoading={setEntryLoading}
                 />
               </Option>
               <Option value={EntryMethod.OFD}>
                 <UploadFile 
                   label="OFD 导入" 
                   acceptFileType=".ofd" 
                   sourceType={8} 
                   refreshTable={refreshTable} 
                   setLoading={setEntryLoading}
                 />
               </Option>
            </Select>
            </Item>
          </Form>
          <Download 
            type="button"
            url="/input/invoices/importTemplate" 
            label='下载录入模板' 
          />
          <Button
            type="primary"
            onClick={showHighBeatMeterUploadMD}
          >
            高拍仪录入
          </Button>
           <Upload 
            label="Excel 录入" 
            url="/input/invoices/import" 
            onFinish={() => {table?.refresh()}}
          />
          <PermissionButton 
            permissions="InputBizInvoice.createByManual" 
            type="primary" 
            onClick={showManualAddMD}
          >
            手工录入
          </PermissionButton>
          <Button
            type="primary"
            loading={checkLoading}
            onClick={handleCheck}
          >
            查验
          </Button>
          {/* <Button
            type="primary"
            loading={checkRemainingTimesLoading}
            onClick={showCheckRemainingTimesMD}
          >
            验伪剩余次数
          </Button> */}
          <Button
            type="primary"
            onClick={handleSummaryExport}
          >
            汇总导出
          </Button>
          <Popconfirm 
            title='删除后数据无法复原，是否继续？' 
            onConfirm={handleBatchDel}
          >
           <Button
             type="primary"
             loading={batchDelLoading}
            >
              批量删除
            </Button>
          </Popconfirm>
        </Space>
      }>
        <Table 
          onTableRef={onTableRef}
          url='/input/invoices' 
          columns={columns} 
          selectedRowKeys={[]}
        >
          <Item name="key">
            <Input placeholder="发票代码/号码/购方/销方" />
          </Item>
          <Item name="invoiceDateFromTo">
            <RangePicker 
              placeholder={['开票日期从', '开票日期至']} 
              format='YYYY-MM-DD'
            />
          </Item>
          <Item name="invoiceType">
            <Lookup placeholder="发票类型" lookupType="INPUT_INVOICE_TYPE" />
          </Item>
          <Item name="statusCode">
            <Lookup placeholder="发票状态" lookupType="input_invoice_status" />
          </Item>
          <Item name="source">
            <Lookup placeholder="来源" lookupType="INPUT_SOURCE_TYPE" />
          </Item>
          <Item name="entryBy">
            <Input placeholder="录入人" />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default RegisterList;