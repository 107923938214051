import * as React from 'react';
import { Space, Row, Table, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Card, Button } from '@comps';
import { RedNoticeNo } from '../interface';

export interface Props {
  redNoticeNos: RedNoticeNo[],
  onConfirm: (redNoticeNos: RedNoticeNo[]) => void;
  onClose: () => void;
};

const PreviewMd: React.FC<Props> = ({
  redNoticeNos, onConfirm, onClose,
}: Props) => {
  const handleConfirm = React.useCallback(async () => {
    onConfirm(redNoticeNos);
  }, [onConfirm, redNoticeNos]);

  const columns: ColumnsType<RedNoticeNo> = [
    {
      dataIndex: 'actionId', title: '序号', width: 60,
      render(_1, _2, index: number) {
        return String(index + 1);
      },
    },
    { dataIndex: 'invoiceCode', title: '发票代码', width: 150, },
    { dataIndex: 'invoiceNo', title: '发票号码', width: 150, },
    {
      dataIndex: 'redNoticeNo', title: '红字通知单号', width: 120,
      render(_1, redNoticeNo: RedNoticeNo) {
        return (<Input onChange={(event) => {
          redNoticeNo.redNoticeNo = event.target.value;
        }} />);
      },
    },
  ];
  return (
    <Card title='请您录入红字申请单号' style={{ marginBottom: '0' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Table style={{ minHeight: '200px' }}
          pagination={false}
          columns={columns}
          dataSource={redNoticeNos}
          rowKey="actionId" />
        <Row justify="center">
          <Space>
            <Button type="primary" clickCallback={handleConfirm}>确认开票</Button>
            <Button onClick={() => onClose()}>取消</Button>
          </Space>
        </Row>
      </Space>
    </Card>
  );
};

export default PreviewMd;
