import React, { useState, FC } from 'react';
import { Form, Input, Space } from 'antd';
import { Link } from 'react-router-dom';

import { Page, Table, Card, Modal, PermissionButton } from '@comps';
import { OrgDetail } from './detail';

import { Layout, Http, Message } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;

const OrgList: FC = (props: any) => {
  const [modal, modalHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [isModalVisible, setModalVisible] = useState(false)

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const createOrg = () => {
    form.validateFields()
      .then(values => {
        Http.post('/org/orgs', values)
          .then(() => {
            Message.saveOk();
            setModalVisible(false);
            form.resetFields();
            table?.refresh();
          })
      })
  };

  const enterAddModal = () => {
    setModalVisible(true);
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible(false)
  };

  const columns: ColumnProps[] = [
    {
      key: 'orgName',
      title: '公司名称',
      width: 300,
      fixed: 'left',
      render: (orgName, row) => (<Link to={`/org/orgs/${row.id}`}>{orgName}</Link>)
    },
    {
      key: 'orgCode',
      title: '公司编码',
      width: 220
    },
    { key: 'taxNo', title: '税号', width: 220 },
    { key: 'addrTel', title: '地址电话', width: 240 },
    { key: 'bankAccount', title: '银行账号', width: 240 },
  ];

  return (
    <Page>
      {modalHolder}
      <Card
        title={'公司管理'}
        actions={
          <Space>
            <PermissionButton
              type='primary'
              permissions="Org.create"
              onClick={enterAddModal}
            >
              创建公司
            </PermissionButton>
          </Space>
        }
      >
        <Table
          url='/org/orgs'
          columns={columns}
          rowKey='id'
          onTableRef={onTableRef}
        >
          <Item name='key'>
            <Input placeholder='公司名称/税号/编码' />
          </Item>
        </Table>
      </Card>

      <Modal
        title='新增'
        visible={isModalVisible}
        onCancel={onCancel}
        onOk={createOrg}
      >
        <Form name='form' form={form} {...Layout.modal_1c}>
          <Item name='orgName' label='公司名称' required>
            <Input />
          </Item>
          <Item name='taxNo' label='税号' required>
            <Input />
          </Item>
          <Item name='orgCode' label='编码' >
            <Input placeholder='未提供时系统将自动分配' />
          </Item>
        </Form>
      </Modal>
    </Page>
  );
};

export { OrgList, OrgDetail };
