import React, { FC, useState, useImperativeHandle, forwardRef, CSSProperties, useEffect } from 'react';
import { dropByCacheKey } from 'react-router-cache-route'; 

import { Context } from '@utils';
import { PaneProps } from '@models';

import './index.css';

interface Props {
  ref: any,
  style?: CSSProperties
};

const Tabs: FC<Props> = forwardRef(({ 
  style 
}, ref) => {

  const [tabsActiveKey, setTabsActiveKey] = useState('0');

  const _panes = Context.getPanes();
  const _tabsActiveKey = Context.getTabsActiveKey();
  const [panes, setPanes] = useState<PaneProps[]>([])

  useEffect(() => {
    setPanes([..._panes]);
    setTabsActiveKey(_tabsActiveKey);
    dispatchSetPanesAndActiveKeyFn();
  }, [])

  useImperativeHandle(ref, () =>({
    handleTabAdd
  }));

  const dispatchSetPanesAndActiveKeyFn = () => {
    Context.dispatch({ 
      type: 'ACTION_SET_PANES', 
      data: {
        setComponentPanes: setPanes,
        setComponentTabsActiveKey: setTabsActiveKey
      }
    });
  };

  const handleTabClick = (pane: JSONObject) => {
    setTabsActiveKey(pane.key);
    Context.route(pane.route);

    Context.dispatch({ 
      type: 'ACTION_SET_PANES', 
      data: {
        tabsActiveKey: pane.route
      }
    }); 
  };

  const handleTabClose = (targetPane: PaneProps) => {      
    let activeKey = tabsActiveKey;
    let lastIndex = 0;
    const newPanes = panes.filter(
      pane => pane.key !== targetPane.key
    );

    panes.forEach((pane, i: number) => {
      if (pane.key === targetPane.key) {
        lastIndex = i - 1;
      }
    });

    if (newPanes.length && activeKey === targetPane.key) {
      if (lastIndex >= 0) {
        activeKey = newPanes[lastIndex].key;
        Context.route(newPanes[lastIndex].route);
      } else {
        activeKey = newPanes[0].key;
        Context.route(newPanes[0].route);
      }
    }

    setPanes(newPanes);
    setTabsActiveKey(activeKey);

    Context.dispatch({ 
      type: 'ACTION_SET_PANES', 
      data: {
        panes: newPanes,
        tabsActiveKey: activeKey
      }
    });

    dropByCacheKey(targetPane.route);
  };

  const handleTabAdd = (menuObj: JSONObject) => {
    // 最多开 10 个页面
    // if (panes.length === 10) {
    //   alert('请先尝试关闭一些页面，再打开新页面');
    //   Context.route(getCurrentActiveTab().route);
    //   return;
    // }

    // 判断要打开的页面是否已打开
    const menuTabIndex = panes.findIndex(
      pane => pane.key === menuObj.route
    );

    if (menuTabIndex > 0) {
      setTabsActiveKey(menuObj.route);
      return;
    }

    const newPanes = [
      ...panes, 
      {
        title: menuObj.menuName,
        key: menuObj.route,
        route: menuObj.route
      }
    ]

    setPanes(newPanes);
    setTabsActiveKey(menuObj.route);

    Context.dispatch({ 
      type: 'ACTION_SET_PANES', 
      data: {
        panes: newPanes,
        tabsActiveKey: menuObj.route
      }
    });
  };
 
  // const getCurrentActiveTab = (): PaneProps => {
  //   return panes.find((pane) => pane.key === tabsActiveKey) 
  //     ?? { title: '', key: -1, route: '' };
  // };

  return (
    <div className="tabs" style={style}>
      {
        panes.map((pane, index: number) => (
          <div 
            key={pane.key}
            className={ 
              pane.key === tabsActiveKey 
                ? 'tab tab-active' 
                : 'tab'
            } 
          >
            <span 
              className="tab-title"
              onClick={() => handleTabClick(pane)}
            >
              {pane.title}
            </span>
            {
              index !== 0 &&
                <span 
                  className="tab-close"
                  onClick={() => handleTabClose(pane)}
                >
                  X
                </span>
            }
          </div>
        ))
      }
    </div>
  );
});

export default Tabs;