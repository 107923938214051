import * as React from 'react';
import { Form, Row, Col } from 'antd';
import { Card, PermissionButton, Button, Kv, Table } from '@comps'
import { Lang, Http } from '@utils';
import { ColumnProps } from '@comps/types';

export interface Props {
  agent: JSONObject;
  onCLose: () => void;
};

const AuthMd: React.FC<Props> = ({ agent, onCLose }: Props) => {
  const [form] = Form.useForm();
  const tableRef = React.useRef<Table>();

  const grant = React.useCallback(() => Lang.noError(async () => {
    await form.validateFields();
    const formData = await form.getFieldsValue();
    await Http.post('/tax/agents/grant', {
      agentId: agent.id,
      userId: formData.userId,
    });
    tableRef?.current?.refresh();
  }), [agent.id, form]);

  const withdrawalGrant = React.useCallback((id: number) => Lang.noError(async () => {
    await Http.del('/tax/agents/unGrant', {
      grantId: id,
    });
    tableRef?.current?.refresh();
  }), []);

  const columns: ColumnProps[] = [
    { key: 'fullName', title: '姓名', width: 200 },
    { key: 'creationTime', title: '商品名称', width: 200, },
    {
      key: 'id', title: '操作', width: 200,
      render: (id) => <PermissionButton
        permissions={'TaxAgent.unGrant'}
        type='link'
        clickCallback={() => withdrawalGrant(id)}>收回授权</PermissionButton>
    },
  ];
  return (<Card title='税盘授权' style={{ marginBottom: '0' }}>
    <Form form={form}>
      <Row gutter={20}>
        <Col span={5}>{' '}</Col>
        <Col span={12}>
          <Form.Item label="用户"
            name='userId'
            rules={[{ required: true, message: '请选择用户' }]}>
              <Kv url={`/sys/users/invoicersKv?orgId=${agent.orgId}`} />
            </Form.Item>
        </Col>
        <Col span={7}>
          <Button type="primary"
            clickCallback={grant}>确认授权</Button>
        </Col>
      </Row>
      <Table url={`/tax/agents/grant?agentId=${agent.id}`}
        onTableRef={(ref) => {
          tableRef.current = ref;
        }}
        columns={columns} />
    </Form>
  </Card>);
};

export default AuthMd;
