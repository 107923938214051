import React, { FC, Fragment } from 'react'
const Line: FC = () => {
    return (<Fragment><span style={{ fontSize: 'small', color: '#888' }}>|  </span></Fragment>)
}

/**
 * 中间带 | 的Space
 * @param props 
 */
const Space: FC = (props) => {
    let children: any = props.children;
    let size = children.length - 1
    return (<Fragment>
        {children.map((e: any, k: any) => (<Fragment>{e} {size === k ? '' : <Line />}</Fragment>))}
    </Fragment>)
}

export default Space;