import React, { FC, useState, useEffect } from 'react'

import { Input, Button, Form, Space, Row, Col, Select } from 'antd'

import { Page, Card, Table, BackButton, LinkText } from '@comps'
import { Http, Message, Api } from '@utils'
import { ColumnProps } from '@comps/types'

const Item = Form.Item

const FunctionEdit: FC = (props: any) => {

    let [table, setTable] = useState<Table>()
    let [permissionKvs, setPermissionKvs] = useState<any[]>()
    let [selectedPermissionId, setSelectedPermissionId] = useState(-1)
    const [form] = Form.useForm()
    const functionId = props.match.params.id

    useEffect(() => {
        Http.get(`/sys/functions/${functionId}`).then(data => {
            form.setFieldsValue({ ...data })
        })

        Api.getKv('/sys/permissions/kv').then(data => {
            setPermissionKvs(data)
        })

    }, [functionId, form])

    const onTableRef = (tableRef: Table) => {
        table = tableRef
        setTable(tableRef)
    }

    const refreshTable = () => {
        table?.refresh()
    }

    const handOnSave = () => {
        form.validateFields().then(values => {
            let data = { id: functionId, ...values }
            Http.put('/sys/functions', data).then(data => {
                Message.updateOk()
            })
        })
    }

    const addPermission = () => {
        if (selectedPermissionId < 0) {
            Message.warn('请选择权限')
        } else {
            Http.post('/sys/functions/permissions',
                {
                    functionId: functionId,
                    permissionId: selectedPermissionId,
                }
            ).then(data => {
                Message.saveOk()
                refreshTable()
            });
        }
    }

    const deletePermission = (permissionId: number) => {
        Http.del(`/sys/functions/permissions?functionId=${functionId}&permissionId=${permissionId}`).then(data => {
            Message.ok()
            refreshTable()
        })
    }

    const columns: ColumnProps[] = [
        {
            title: '序号',
            key: "id",
            width: 60,
            render: (id, row, index) => (index + 1),
        },
        {
            title: '权限编码',
            key: "permissionCode",
        },
        {
            title: '权限名称',
            key: 'permissionName'
        },
        {
            title: '请求方式',
            key: 'requestMethod',
        },
        {
            title: '虚拟权限',
            key: 'isVirtual',
            render: (isVirtual) => (isVirtual ? '是' : '否'),
        },
        {
            title: '操作',
            key: 'id',
            fixed: 'right',
            width: 100,
            render: (id, row) => (<LinkText label='删除' onClick={() => { deletePermission(row.id) }} />),
        },
    ];

    return (
        <Page title='功能管理' backable>
            <Card title='基本信息'>
                <Form name='functionForm' form={form} >
                    <Row>
                        <Space>
                            <Item name='functionName' label='功能名称' rules={[{ required: true }]}>
                                <Input />
                            </Item>
                            <Item name='route' label='路由' rules={[{ required: true }]}>
                                <Input />
                            </Item>
                            <Item name='description' label='描述'>
                                <Input />
                            </Item>
                            <Item>
                                <Space>
                                    <Button type='primary' onClick={handOnSave}>保存</Button>
                                    <BackButton />
                                </Space>
                            </Item>
                        </Space>
                    </Row>
                </Form>
            </Card>

            <Card title='权限列表'>
                <Row>
                    <Col span={12} offset={2}>
                        <Item name='permissionId' label='权限' required={true}>
                            <Select options={permissionKvs}
                                showSearch
                                optionFilterProp="label"
                                placeholder='请选择需添加的权限'
                                onChange={(value: number, o: any) => setSelectedPermissionId(value)}
                            />
                        </Item>
                    </Col>
                    <Col span={4} offset={1}>
                        <Button type='primary' onClick={addPermission}>添加权限</Button>
                    </Col>
                </Row>

                <Table url={`/sys/functions/${functionId}/permissions`} columns={columns} onTableRef={onTableRef}></Table>
            </Card>
        </Page>
    )
}

export default FunctionEdit
