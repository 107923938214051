import * as React from 'react';
import { ModalStaticFunctions as AdModalStaticFunctions } from 'antd/es/modal/confirm';
import { ModalFuncProps } from 'antd/es/modal';
import cname from 'classnames';
import flatPromise from './flatPromise';
import './open.css';

export interface OpenProps extends ModalFuncProps {
  ignoreError?: boolean,
  content: (resolve: (data?: any) => void, reject: (error?: any) => void) => React.ReactNode,
};


export type OpenFn = (props: OpenProps) => Promise<any>;

export interface ModalStaticFunctions extends Omit<AdModalStaticFunctions, 'warn'> {
  open: OpenFn;
}

export default function open(props: OpenProps,
  modalIns: Omit<AdModalStaticFunctions, 'warn'>): Promise<any> {
  const { promise, reject: promiseReject, resolve: promiseResolve } = flatPromise();

  const { ignoreError = true, content, centered = true, className, ...resetProps } = props;

  const _resetProps: any = {
    ...resetProps,
  };

  const contentNode = content((data: any) => {
    modal.destroy();
    promiseResolve(data);
  }, (error: any) => {
    modal.destroy();
    promiseReject(error);
  });
  const modal = modalIns.info({
    title: '',
    icon: '',
    content: contentNode,
    onCancel() {
      setTimeout(() => promiseReject(new Error('cancel')), 100);
    },
    className: cname(className, 'modal-open'),
    centered,
    ..._resetProps,
  });

  if (ignoreError) {
    return promise.catch(() => {});
  }
  return promise;
}

