/**
 * 验证工具
 */

/** Form 默认提示信息 */
const formValidateMsg = {
    // eslint-disable-next-line
    required: '请输入 ${label}',
}

const isValidPhone = (val: string) => {
const reg = /^1[3-9]\d{9}$/
  return reg.test(val)
}

const Validation = {
    formValidateMsg,
    isValidPhone,
}

export default Validation