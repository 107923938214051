import * as React from 'react';
import { Button as AdButton } from 'antd';
import { ButtonProps } from 'antd/es/button';

export interface Props extends ButtonProps {
  clickCallback?: () => Promise<void>;
};

export type Loading = boolean | {
  delay?: number;
};

/**
 * 按钮组件
 */
const Button: React.FC<Props> = ({
  onClick, clickCallback, loading = false, children,
  ...other
}) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const [buttonLoading, setButtonLoading] = React.useState<Loading>(loading);

  let isDestroy: boolean = false;
  React.useEffect(()=> {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isDestroy = true;
    };
  }, []);

  function _onClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    onClick && onClick(event);
    if (clickCallback) {
      setButtonLoading(true);
      clickCallback().finally(() => {
        !isDestroy && setButtonLoading(loading);
      });
    }
  }

  return (<AdButton ref={ref} {...other} onClick={_onClick}
    loading={clickCallback ? buttonLoading : loading}>
      {children}
    </AdButton>);
};

export default Button;
