import React, { FC } from 'react';

import { InvDetail } from '../../component/index';

const RegisterInvDetail: FC = () => {
  return (
    <InvDetail url="/input/invoices/details" />
  );
};

export default RegisterInvDetail;