import * as React from 'react';
import cname from 'classnames';
import styles from './index.module.scss';


export interface Props extends React.Props<HTMLElement>,
  React.BaseHTMLAttributes<HTMLElement> {
  invoice: JSONObject,
};

const Invoice: React.FC<Props> = ({
  invoice,
}: Props) => {
  return (<div className={styles.invoice}>
    <nav className={cname(styles.header, styles.titleText)}>
      <span className={cname(styles.text)}>{invoice.fullInvoiceTypeDesc}</span>
      <div className={cname(styles.meta)}>
        <div className={cname(styles.line)}>
          <div className={cname(styles.label)}>
            <span>发</span><span>票</span><span>代</span><span>码</span>
          </div>
          <div className={cname(styles.value)}>
            {invoice.invoiceCode}
          </div>
        </div>
        <div className={cname(styles.line)}>
          <div className={cname(styles.label)}>
            <span>发</span><span>票</span><span>号</span><span>码</span>
          </div>
          <div className={cname(styles.value)}>
            {invoice.invoiceNo}
          </div>
        </div>
        <div className={cname(styles.line)}>
          <div className={cname(styles.label)}>
            <span>开</span><span>票</span><span>日</span><span>期</span>
          </div>
          <div className={cname(styles.value)}>
            {invoice.invoiceDate}
          </div>
        </div>
        <div className={cname(styles.line)}>
          <div className={cname(styles.label)}>
            <span>检</span><span>验</span><span>码</span>
          </div>
          <div className={cname(styles.value)}>
            {invoice.checkCode}
          </div>
        </div>
      </div>
    </nav>
    <section className={styles.body}>
      <div className={cname(styles.row, styles.infos)}>
        <div className={cname(styles.group, styles.titleText, styles.cell)}>
          <span>购</span><span>买</span><span>方</span>
        </div>
        <div className={cname(styles.dataset, styles.cell)}>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>名</span><span>称</span>
            </div>
            <div className={cname(styles.value)}>
              {invoice.buyerName}
            </div>
          </div>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>纳</span><span>税</span><span>人</span><span>识</span><span>别</span><span>号</span>
            </div>
            <div className={cname(styles.value)}>
              {invoice.buyerTaxNo}
            </div>
          </div>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>地</span><span>址</span><span>、</span><span>电</span><span>话</span>
            </div>
            <div className={cname(styles.value)}>
              {invoice.buyerAddrTel}
            </div>
          </div>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>开</span><span>户</span><span>行</span><span>及</span><span>账</span><span>号</span>
            </div>
            <div className={cname(styles.value)}>
              {invoice.buyerBank}
            </div>
          </div>
        </div>
        <div className={cname(styles.group, styles.titleText, styles.cell)}>
          <span>密</span><span>码</span><span>区</span>
        </div>
        <div className={cname(styles.dataset, styles.cell)} style={{ wordBreak: 'break-all' }}>
          {invoice.pwdCode}
        </div>
      </div>
      <table className={cname(styles.table)}>
        <thead>
          <tr>
            {/* origin 27 */}
            <th className={cname(styles.titleText)} style={{width: '40.3%'}}>
              货物或应税劳务、服务名称
            </th>
            <th className={cname(styles.titleText)} style={{width: '10.1%' }}>
              规格型号
            </th>
            <th className={cname(styles.titleText)} style={{width: '5.6%' }}>
              单位
            </th>
            {/* origin 11.3 */}
            <th className={cname(styles.titleText)} style={{width: '10%' }}>
              数量
            </th>
            {/* origin 11.8 */}
            <th className={cname(styles.titleText)} style={{width: '10%' }}>
              单价
            </th>
            {/* origin 15.2 */}
            <th className={cname(styles.titleText)} style={{width: '10%' }}>
              金额
            </th>
            <th className={cname(styles.titleText)} style={{width: '4%' }}>
              税率
            </th>
            {/* origin 15 */}
            <th className={cname(styles.titleText)} style={{width: '10%' }}>
              税额
            </th>
          </tr>
        </thead>
        <tbody>
          {(invoice.lines || []).map((line: JSONObject) => {
            return (<tr style={{ height: '30px', maxHeight: '30px' }} key={line.id}>
              <td style={{textAlign: 'left'}}>{line.invoiceGoodsName}</td>
              <td>{line.spec}</td>
              <td style={{textAlign: 'center'}}>{line.unit}</td>
              <td>{line.quantity}</td>
              <td>{line.withTaxPrice}</td>
              <td>{line.amount}</td>
              <td>{line.taxRate}%</td>
              <td>{line.taxAmount}</td>
            </tr>);
          })}

          <tr style={{ height: `${Math.max(200 - (invoice.lines || []).length * 30, 0)}px`}}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td className={cname(styles.titleText, cname(styles.totalTitle))}>合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>¥{invoice.invoiceAmount}</td>
            <td></td>
            <td>¥{invoice.taxAmount}</td>
          </tr>
        </tfoot>
      </table>
      <div className={cname(styles.row)} style={{ height: '40px', lineHeight: '40px' }}>
        <div className={cname(styles.titleText, styles.cell)}
          style={{ textAlign: 'center', width: '27%' }}>
          价税合计(大写)
        </div>
        <div className={cname(styles.grow, styles.cell, styles.data)}>
          <div className={cname(styles.row)}>
            <div style={{ width: '65%' }}>
              {invoice.totalInvoiceAmountUpper}
            </div>
            <div style={{ width: '35'}}>
              <span className={cname(styles.titleText)}>(小写)</span>
              ¥{invoice.totalAmount}
            </div>
          </div>
        </div>
      </div>
      <div className={cname(styles.row, styles.infos)}>
        <div className={cname(styles.group, styles.titleText, styles.cell)}>
          <span>销</span><span>售</span><span>方</span>
        </div>
        <div className={cname(styles.dataset, styles.cell)}>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>名</span><span>称</span>
            </div>
            <div className={cname(styles.value)}>
              {invoice.sellerName}
            </div>
          </div>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>纳</span><span>税</span><span>人</span><span>识</span><span>别</span><span>号</span>
            </div>
            <div className={cname(styles.value)}>
              {invoice.sellerTaxNo}
            </div>
          </div>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>地</span><span>址</span><span>、</span><span>电</span><span>话</span>
            </div>
            <div className={cname(styles.value)}>
              {invoice.sellerAddrTel}
            </div>
          </div>
          <div className={cname(styles.line)}>
            <div className={cname(styles.label)}>
              <span>开</span><span>户</span><span>行</span><span>及</span><span>账</span><span>号</span>
            </div>
            <div className={cname(styles.value)}>
              {invoice.sellerBank}
            </div>
          </div>
        </div>
        <div className={cname(styles.group, styles.titleText, styles.cell)}>
          <span>备</span><span>注</span>
        </div>
        <div className={cname(styles.dataset, styles.cell)}>
          {invoice.remark}
        </div>
      </div>
    </section>
    <div className={cname(styles.footer)}>
      <div className={cname(styles.item)}>
        <div className={cname(styles.label)}>
          收&nbsp;款&nbsp;人
        </div>
        <div className={cname(styles.value)}>
          {invoice.cashier}
        </div>
      </div>
      <div className={cname(styles.item)}>
        <div className={cname(styles.label)}>
          复&nbsp;核
        </div>
        <div className={cname(styles.value)}>
          {invoice.checker}
        </div>
      </div>
      <div className={cname(styles.item)}>
        <div className={cname(styles.label)}>
          开&nbsp;票&nbsp;人
        </div>
        <div className={cname(styles.value)}>
          {invoice.invoicer}
        </div>
      </div>
    </div>
  </div>);
};

export default Invoice;
