import { RouteProps } from '@models';
import DocManage from './docManage/index'
import OutputInvoice from './index';
import InvoiceList from './list';
import InvoiceDetail from './detail';
import WaitInvoiceList from './waitInvoice'
import WaitInvoiceDetail from './docManage/detail'
import InvLogin from './invLogin'

const taxRoutes: RouteProps[] = [
    { path: '/tax/outputInvoice/docManage', component: DocManage, private: true },
    { path: '/tax/outputInvoice', component: InvoiceList, private: true },
    { path: '/tax/outputInvoice/wait', component: WaitInvoiceList, private: true },
    { path: '/tax/outputInvoice/wait/:requestNo/detail', component: WaitInvoiceDetail, private: true },
    { path: '/tax/outputInvoice/:requestNo/detail', component: InvoiceDetail, private: true },
    { path: '/tax/outputInvoice/smkp', component: OutputInvoice, private: true },
    { path: '/tax/outputInvoice/invLogin', component: InvLogin, private: true },
];

export default taxRoutes;
