import React, { FC, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Layout, Menu, Dropdown, Row, Col, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import cname from 'classnames';

import { Modal } from '@comps';
import DeptTree from './deptTree';
import SwitchRole from './switchRole';
import Tabs from './tabs';

import { Context, Http, Lang } from '@utils';
import { UserContext } from '@models';

import './index.css';
import styles from './index.module.scss';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

interface Props {
  logined: boolean,
  lastName?: string,
  roleName?: string,
  orgId?: number,
  deptName?: string,
  multiDeptFlag?: boolean,
  multiRoleFlag?: boolean,
  children: any,
  logout: Function
}

const MainLayout: FC<Props> = ({ 
  logined, 
  lastName, 
  roleName, 
  orgId, 
  deptName, 
  multiDeptFlag,
  multiRoleFlag, 
  logout, 
  children 
}) => {

  const tabsRef: any = useRef();

  const ctx = Context.getContext();
  const appName = ctx['global.app-name'];
  const userInfo = useSelector<JSONObject, UserContext>((state) => state.ctx);
  const userUnderOrg = orgId && orgId > 0;

  const [model, modalHolder] = Modal.useModal();
  let [menuTree, setMenuTree] = useState<any[]>();
  
  const minHeigh = Context.getScreenHeigh() - 60;

  useEffect(() => {
    refreshMenu()
  }, []);

  const refreshMenu = async () => {
    const menus = await Http.get('/sys/menus/roleMenus');
    setMenuTree(menus);
  } 

  const handleSwitchDept = async () => {
    await model.open({
      width: '520px',
      content(resolve, reject) {
        return (<DeptTree onConfirm={resolve} onCancel={reject} />);
      }
    });
  }

  const handleSwitchRole = async () => {
    await model.open({
      width: '520px',
      content(resolve, reject) {
        return (<SwitchRole onConfirm={resolve} onCancel={reject} />);
      }
    });

    await refreshMenu();
    Context.route('/sys/users/profile');
  }

  const userOptionsOnClick = (e: any) => {
    const key = e.key;

    switch (key) {
      case '10':
        Context.route('/sys/users/profile');
        break;
      case '20':
        Context.route(`/org/${orgId}/edit`);
        break;
    }
  }

  const _handleTabAdd = (tab: JSONObject) => {
    const handleTabAdd = tabsRef.current.handleTabAdd;
    handleTabAdd(tab);
  }

  const userOptions = (
    <Menu onClick={userOptionsOnClick}>
      <Menu.Item key="10">账号信息</Menu.Item>
      {userUnderOrg && <Menu.Item key="20">公司信息</Menu.Item>}
      {multiDeptFlag && <Menu.Item key="30" onClick={handleSwitchDept}>切换部门</Menu.Item>}
      {multiRoleFlag && <Menu.Item key="40" onClick={handleSwitchRole}>切换角色</Menu.Item>}
    </Menu>
  )

  return (
    <Layout>
      <Header>
        <Row gutter={0} className={cname(styles.header)} align="middle">
          <Col style={{display: "flex", alignItems: "center"}}>
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="" style={{width: 30, height: 30}}/>
            <span style={{ color: '#fff', fontSize: '20px' }}>{appName}</span>
          </Col>
          <Col className={cname(styles.null)}>
          </Col>
          <Col>
            <Space size='large' className={cname(styles.navList)}>
              {/* eslint-disable-next-line */}
              <a 
                className={cname(styles.link)} 
                href="https://docs.qq.com/doc/DTUdPUmhHU0RleFdK" 
                target="_blank"
              >
                使用手册
              </a>
              <a 
                className={cname(styles.link)} 
                href="https://support.qq.com/products/371853" 
                target="_blank"
              >
                反馈中心
              </a>
              <a className={cname(styles.link)}>{deptName}</a>
              <Dropdown overlay={userOptions}>
                <a className="ant-dropdown-link" href='/' style={{ color: '#fff', fontSize: '13px' }} onClick={e => e.preventDefault()}>
                  {lastName} <DownOutlined />
                </a>
              </Dropdown>
              <div className={cname(styles.navItem)}>
                {/* eslint-disable-next-line */}
                <span className={cname(styles.link)}>{roleName}</span>
              </div>
              <div className={cname(styles.navItem)}>
                {/* eslint-disable-next-line */}
                <a className={cname(styles.link)}
                  onClick={async () => {
                    Context.route('/sys/message/')
                  }}>
                  未读消息({userInfo.unreadMsgCount ?? 0})
                </a>
              </div>
              <div className={cname(styles.navItem)}>
                {/* eslint-disable-next-line */}
                <a className={cname(styles.link)}
                  onClick={() => Lang.noError(async () => {
                    Modal.confirm({
                      title: '信息',
                      content: '确定要退出系统吗?',
                      async onOk() {
                        await logout();
                        Context.route('/login');
                      },
                    });
                  })}>
                  退出
                </a>
              </div>
            </Space>
          </Col>
        </Row>
      </Header>
      <Layout>
        {logined &&
          <Sider collapsible style={{ minHeight: minHeigh }} width={'15%'} className="site-layout-background">
            <Menu
              mode="inline"
              style={{ height: '100%', borderRight: 0 }}
            >
              {menuTree?.map((menu: any) => (
                <SubMenu key={menu.menuId} title={menu.menuName}>
                  {menu.subMenus.map((subMenu: any) => (
                    <Menu.Item key={subMenu.menuId}>
                      <Link 
                        to={subMenu.route}
                        onClick={() => _handleTabAdd(subMenu)} 
                      >
                        {subMenu.menuName}
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ))}
            </Menu>
          </Sider>
        }
        <Layout>
          {modalHolder}
          <Content style={{ margin: 0, minHeight: 600, position: 'relative' }}>
            <Tabs ref={tabsRef} style={{ position: 'absolute', left: 10 }} />
            <div style={{ height: 10 }}></div>
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  return {
    lastName: state.ctx.lastName,
    roleName: state.ctx.roleName,
    logined: state.ctx.logined,
    orgId: state.ctx.orgId,
    deptName: state.ctx.deptName,
    multiDeptFlag: state.ctx.multiDeptFlag,
    multiRoleFlag: state.ctx.multiRoleFlag,
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => {
      dispatch({ type: 'ACTION_LOGOUT' })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
