/**
 * 环境变量工具
 */
import dev from '../config/dev'
import test from '../config/test'
import prod from '../config/prod'

const NODE_ENV = process.env.NODE_ENV;

/**
 * 从各环境配置文件中获取变量值
 * @param name 变量名称
 * @returns 变量值
 */
function getValue(name: string) {
    switch (NODE_ENV) {
        case 'development':
            return dev[name]
        case 'test':
            return test[name]
        case 'production':
            return prod[name]
    }
}

/**
 * 获取baseUrl(结尾无'/'), eg: http://localhost:8080
 * @returns url
 */
function getBaseUrl() {
    return getValue('ACC_API_URL')
}

function clientId() {
    return getValue('ACC_CLIENT_ID')
}

function clientSecret() {
    return getValue('ACC_CLIENT_SECRET')
}

/**
 * 登陆地址URL
 */
function getLoginPath() {
    return getValue('ACC_LOGIN_PATH')
}

export {
    getBaseUrl,
    clientId, clientSecret,
    getLoginPath,
}

let Env = {
    getLoginPath: getLoginPath,
    getBaseUrl: getBaseUrl,
    clientId: clientId,
    clientSecret: clientSecret,
}

export default Env