import React, { FC, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Space, Form, DatePicker, Input, Button } from 'antd';

import { Page, Card, Table, Lookup } from '@comps';

import { Message, FormUtil } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;
const { RangePicker } = DatePicker;

const ArchivesList: FC = () => {

  const [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    setTable(tableRef);
  };

  const handleExport = () => {
    const ids = table?.getSelectedRowKeys();

    if (ids?.length === 0) {
      Message.warn('请至少选择一条数据');
      return;
    }
    
    FormUtil.exportFileByPost('/input/invPool/collectExport', { ids }); 
  };

  const renderCheckStatus = (checkType: number) => {
    if (checkType === 0) {
      return <span style={{ color: '#333333' }}>未查验</span>
    } else if (checkType === 1) {
      return <span style={{ color: '#04d919' }}>已查验</span>
    } else if (checkType === 2) {
      return <span style={{ color: '#3facdf' }}>查验中</span>
    } else if (checkType === 3) {
      return <span style={{ color: '#d9001b' }}>查验失败</span>
    }
  };

  const columns: ColumnProps[] = [
    { key: 'checked', title: '查验状态', width: 110, fixed: 'left',
      render: renderCheckStatus
    },
    { key: 'sourceDesc', title: '数据来源', width: 130 },
    { key: 'reimburseBy', title: '报销人', width: 130 },
    { key: 'voucherNo', title: '凭证号', width: 130 },
    { key: 'reimburseTime', title: '报销日期', width: 130,
      render: (reimburseTime: string) => reimburseTime ? reimburseTime.split(' ')[0] : ''
    },
    { key: 'reimburseStatus', title: '报销状态', width: 130,
      render: (reimburseStatus: any) => {
        return reimburseStatus ? '已报销' : '未报销'
      }
    },
    { key: 'invoiceCode', title: '发票代码', width: 160 },
    { key: 'invoiceNo', title: '发票号码', width: 160 },
    { key: 'invoiceTypeDesc', title: '发票类型', width: 130 },
    { key: 'statusCodeDesc', title: '发票状态', width: 130, },
    { key: 'invoiceDate', title: '开票日期', width: 120 },
    { key: 'totalAmount', title: '金额', width: 130, align: 'right' },
    { key: 'taxAmount', title: '税额', width: 130, align: 'right' },
    { key: 'totalAmount', title: '价税合计', width: 130, align: 'right' },
    { key: 'sellerBank', title: '销方银行账号', width: 400 }, 
    { key: 'sellerName', title: '销方名称', width: 300 },
    { key: 'sellerTaxNo', title: '销方税号', width: 200 },
    { key: 'sellerAddrTel', title: '销方地址电话', width: 400 },
    {
      key: 'id', title: '操作', width: 100, fixed: 'right', align: 'center',
      render(id) {
        return (
          <Space>
            <Link to={`/income/archives/${id}/detail`}>详情</Link>
          </Space>
        )
      }
    }
  ];

  return (
    <Page>
      <Card title="发票档案管理" actions={
        <Fragment>
          <Button
            type="primary"
            onClick={handleExport}
          >
            导出
          </Button>
        </Fragment>
      }>
        <Table 
          onTableRef={onTableRef}
          url='/getInvoiceRecords' 
          columns={columns} 
          selectedRowKeys={[]}
        >
          <Item name="invoiceCono">
            <Input placeholder="发票号码/代码/报销人/凭证号" />
          </Item>
          <Item name="invoiceDateFromTo">
            <RangePicker 
              placeholder={['开票日期从', '开票日期至']} 
              format='YYYY-MM-DD'
            />
          </Item>
          <Item name="reimburseTimeFromTo">
            <RangePicker 
              placeholder={['报销日期从', '报销日期至']} 
              format='YYYY-MM-DD'
            />
          </Item>
          <Item name="invoiceType">
            <Lookup placeholder='发票类型' lookupType='INPUT_INVOICE_TYPE' />
          </Item>
          <Item name="statusCode">
            <Lookup placeholder='发票状态' lookupType='input_invoice_status' />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export default ArchivesList;