/**
 * JSON 富文本编辑器
 * @see https://www.npmjs.com/package/react-json-view
 * @see https://github.com/mac-s-g/react-json-view
 * @see https://www.cnblogs.com/soyxiaobi/p/9713814.html
 */
import React, { FC } from 'react'
import ReactJson from 'react-json-view'

interface JsonEditorProps {
    datasource: object,
}

const JsonEditor: FC<JsonEditorProps> = (props) => {
    let { datasource } = props
    return (
        <ReactJson src={datasource}
            name={null}
            theme="google"
            iconStyle="square"
            collapseStringsAfterLength={40}
            displayDataTypes={false}
        />
    )
}

export default JsonEditor