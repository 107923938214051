import * as React from 'react';
import { Space, Form, Input, InputNumber, Table as AdTable, Row } from 'antd';
import { Page, Card, Table, Lookup, Button, PermissionButton, Modal } from '@comps';
import { Http, Math, Context, Lang } from '@utils';
import { ColumnProps } from '@comps/types';
import * as Router from 'react-router';
import PreviewMd from './preview.md';
import { Change, Status, ChangeLine } from '../interface';
import Adjustment from '../modal/adjustment.md';
import OrderImportBorrowMd from "../modal/orderImportBorrow.md";

export interface Props { };

const Update: React.FC<Props> = () => {
  const tableRef = React.useRef<Table>();
  const [model, modalHolder] = Modal.useModal();
  const { id } = Router.useParams();
  const [change, setChange] = React.useState<Change>();
  const changeRef: any = React.useRef()

  const loadDetail = React.useCallback(async () => {
    const changeResult = await Http.get<Change>(`/tax/changes/${id}`);
    setChange(changeResult);
    changeRef.current = changeResult
  }, [id]);

  React.useEffect(() => {
    loadDetail();
  }, [loadDetail]);

  React.useEffect(() => {
    refreshTable()
  }, [changeRef]);

  // 刷新表格
  function refreshTable() {
    tableRef.current?.refresh()
  }

  //导入借贷项
  const importBorrow = React.useCallback((changeLineId) => Lang.noError(async () => {
    await model.open({
      width: '1150px',
      ignoreError: false,
      content(resolve, reject) {
        return (<OrderImportBorrowMd onRefresh={refreshTable} onClose={reject} onConfirm={resolve} lineId={changeLineId} buyerCode={changeRef.current.buyerCode} isChange={true} />);
      },
    });
  }), [model]);

  const handleLineSplit = React.useCallback(async (id: number) => {
    await Http.post('/tax/changes/lines/split', {
      id,
    });
    tableRef.current?.refresh();
    loadDetail();
  }, [loadDetail]);

  const handleLineConfirm = React.useCallback(async (changeLine: ChangeLine) => {
    await Http.put('/tax/changes/lines', {
      id: changeLine.id,
      quantity: changeLine.__data?.quantity || changeLine.quantity,
      splitAmount: changeLine.__data?.splitAmount || changeLine.withTaxAmount,
    });
    tableRef.current?.refresh();
    loadDetail();
  }, [loadDetail]);

  const handleLineDelete = React.useCallback(async (id: number) => {
    await Http.del(`/tax/changes/lines/${id}`);
    tableRef.current?.refresh();
    loadDetail();
  }, [loadDetail]);

    // 调整借贷项
    async function handleAdjustment(id : number) {
      await model.open({
        width: '600px',
        content(resolve, reject) {
          return (<Adjustment onConfirm={resolve} onCancel={reject} id={id} source='C' />);
        },
      });
      tableRef.current?.refresh();
      loadDetail();
    }

  // 切换到编辑模式
  const switchToEditModel = React.useCallback(async (changeLine: ChangeLine) => {
    console.log(changeLine)
    changeLine._isEdit = true
    changeLine._groupNo = changeLine.groupNo
    changeLine._goodsName=changeLine.goodsName
    changeLine._taxCode=changeLine.taxCode
    changeLine._unit=changeLine.unit
    changeLine._spec=changeLine.spec
    tableRef.current?.resetDataSource();
  }, []);

  // 取消编辑
  const cancelEditModel = React.useCallback(async (changeLine: ChangeLine) => {
    changeLine._isEdit = false
    tableRef.current?.resetDataSource();
  }, []);

  // 保存行
  const saveLine = React.useCallback(async (changeLine: ChangeLine) => {
    await Http.put('/tax/changes/lines/goods', {
      id: changeLine.id,
      groupNo: changeLine._groupNo,
      goodsName:changeLine._goodsName,
      spec:changeLine._spec,
      unit:changeLine._unit,
      taxCode:changeLine._taxCode
    });
    tableRef.current?.refresh();
  }, []);

  async function changeInvoiceType(changeLine: ChangeLine, invoiceType: string) {
    await Http.put('/tax/changes/lines/invoiceType', {
      id: changeLine.id,
      invoiceType,
    });
    changeLine.invoiceType = invoiceType;
    loadDetail();
  }

  const handleSubmit = React.useCallback(async () => {
    await model.open({
      width: '1100px',
      content(resolve) {
        return (<PreviewMd id={id}
          onConfirm={resolve}
          onClose={resolve} />);
      },
    });
  }, [id, model]);

  const handleCancelApply = React.useCallback(async () => {
    await model.open({
      width: '600px',
      ignoreError: false,
      content(resolve, reject) {
        return (<Card title='取消后需要重新提交改票申请，您确认取消吗？'
          style={{ marginBottom: '0' }}>
          <Row justify="center" style={{ padding: "20px" }}>
            <Space>
              <Button type="primary" onClick={() => resolve()}>确认取消</Button>
              <Button onClick={() => reject()}>我再想想</Button>
            </Space>
          </Row>
        </Card>);
      },
    });
    await Http.put(`/tax/changes/${id}/cancel`);
    Context.route('/tax/change/invoice');
  }, [id, model]);

  const columns: ColumnProps[] = [
    { key: 'labelSeq', title: '序号', width: 60 },
    {
      key: 'groupNo', title: '票号', width: 60,
      render(value, changeLine: ChangeLine) {
        if (changeLine._isEdit) {
          return (<InputNumber defaultValue={value} precision={0} min={1} style={{ width: '100%' }}
            onChange={(inputValue: any) => {
              changeLine._groupNo = inputValue;
            }} />);
        }
        return value;
      },
    },
    { key: 'goodsName', title: '商品名称', width: 200,
      render(value, changeLine: ChangeLine) {
        if (changeLine._isEdit) {
          return (<Input defaultValue={value}
                               onChange={(event) => {
                                 changeLine._goodsName = event.target.value;
                               }} />);
        }
        return value;
      },
    },
    { key: 'spec', title: '规格型号', width: 100,
      render(value, changeLine: ChangeLine) {
        if (changeLine._isEdit) {
          return (<Input defaultValue={value}
                         onChange={(event) => {
                           changeLine._spec = event.target.value;
                         }} />);
        }
        return value;
      },
    },
    { key: 'unit', title: '单位', width: 60,
      render(value, changeLine: ChangeLine) {
        if (changeLine._isEdit) {
          return (<Input defaultValue={value}
                         onChange={(event) => {
                           changeLine._unit = event.target.value;
                         }} />);
        }
        return value;
      },
    },
    { key: 'taxCode', title: '税收分类编码', width: 200,
      render(value, changeLine: ChangeLine) {
        if (changeLine._isEdit) {
          return (<Input defaultValue={value}
                         onChange={(event) => {
                           changeLine._taxCode = event.target.value;
                         }} />);
        }
        return value;
      },
    },
    {
      key: 'quantity', title: '数量', width: 110,
      render(value, changeLine: ChangeLine) {
        if (changeLine.allowUpdate) {
          return (<InputNumber defaultValue={value}
            min={0}
            onChange={(inputValue) => {
              changeLine.__data = changeLine.__data || {};
              changeLine.__data.quantity = inputValue;
            }} />);
        }
        return value;
      },
    },
    { key: 'price', title: '不含税单价', width: 110 },
    { key: 'taxRate', title: '税率', width: 60 },
    { key: 'amount', title: '不含税金额', format: 'amount', width: 120 },
    { key: 'taxAmount', title: '税额', width: 120, format: 'amount' },
    {
      key: 'withTaxAmount', title: '含税金额',
      align: 'right',
      width: 130, format: 'amount',
      render(value, changeLine: ChangeLine) {
        if (changeLine.allowUpdate) {
          return (<InputNumber defaultValue={value}
            onChange={(inputValue) => {
              changeLine.__data = changeLine.__data || {};
              changeLine.__data.splitAmount = inputValue;
            }}
            min={0} />);
        }
        return Math.num2money(value);
      },
    },
    { key: 'oriInvoiceNo', title: '原发票号码', width: 120, },
    { key: 'oriInvoiceTypeDesc', title: '原类型', width: 80, },
    {
      key: 'invoiceType', title: '新类型', width: 90, fixed: "right",
      render(value, changeLine: ChangeLine) {
        return (<Lookup style={{ width: '100%' }}
          value={`${value}`}
          lookupType="TAX_INVOICE_SIMPLE_TYPE"
          placeholder="发票类型"
          onChange={(value) => changeInvoiceType(changeLine, value)}></Lookup>);
      },
    },
    {
      key: 'id', title: '操作', fixed: "right", width: 220,
      render(id, changeLine: ChangeLine) {
        return (<>
          {changeLine.allowSplit
            ? (<Button type="link" size="small"
              clickCallback={() => handleLineSplit(id)}>拆分</Button>)
            : undefined}
          {changeLine.allowUpdate
            ? (<Button type="link" size="small"
              clickCallback={() => handleLineConfirm(changeLine)}>确认</Button>)
            : undefined}
          {changeLine.allowDelete
            ? (<Button type="link" size="small"
              clickCallback={() => handleLineDelete(id)}>删除</Button>)
            : undefined}
          {changeLine.confirmed && !changeLine.discountLineFlag && !changeLine._isEdit &&
            <React.Fragment>
              <Button type="link" size="small" clickCallback={() => switchToEditModel(changeLine)}>修改</Button>
              { changeRef.current?.statusCode === Status.SUBMIT && changeLine.withTaxAmount > 0 ? (<PermissionButton type='link' permissions='ChangeRequest.importBorrow' onClick={() => importBorrow(changeLine.id)} >添加借贷项</PermissionButton>):undefined }
            </React.Fragment>
          }
          {changeLine._isEdit &&
            (<Button type="link" size="small" clickCallback={() => saveLine(changeLine)}>保存</Button>)}
          {changeLine._isEdit &&
            (<Button type="link" size="small" clickCallback={() => cancelEditModel(changeLine)}>取消</Button>)}
        </>);
      },
    },
  ];
  const summary = (<>
    {[
      ['totalStats', '合计'],
      ['specialStats', '专票合计'],
      ['normalStats', '普票合计'],
    ].map(([propName, label]) => {
      return (<AdTable.Summary.Row key={propName}>
        <AdTable.Summary.Cell index={1} colSpan={6}>{label}</AdTable.Summary.Cell>
        <AdTable.Summary.Cell index={2}>
          {(change as JSONObject)?.[propName]?.quantity}
        </AdTable.Summary.Cell>
        <AdTable.Summary.Cell index={3} colSpan={3}>
          <div style={{ textAlign: 'right' }}>
            {Math.num2money((change as JSONObject)?.[propName]?.amount)}
          </div>
        </AdTable.Summary.Cell>
        <AdTable.Summary.Cell index={4}>
          <div style={{ textAlign: 'right' }}>
            {Math.num2money((change as JSONObject)?.[propName]?.taxAmount)}
          </div>
        </AdTable.Summary.Cell>
        <AdTable.Summary.Cell index={5}>
          <div style={{ textAlign: 'right' }}>
            {Math.num2money((change as JSONObject)?.[propName]?.withTaxAmount)}
          </div>
        </AdTable.Summary.Cell>
        <AdTable.Summary.Cell index={6} colSpan={20}></AdTable.Summary.Cell>
      </AdTable.Summary.Row>);
    })}
  </>);

  return (
    <Page backable>
      {modalHolder}
      <Card title={`改票申请 ${change?.requestNo}`}
        actions={change?.statusCode === Status.SUBMIT ? (<Space>
          <PermissionButton type="primary"
            onClick={handleSubmit}
            permissions="ChangeRequest.submit">
            提交审批
          </PermissionButton>
          <PermissionButton type="primary"
            onClick={handleCancelApply}
            permissions="ChangeRequest.cancel">
            取消改票
          </PermissionButton>
        </Space>) : undefined}>
        <Table url={`/tax/changes/${id}/lines?goodsName=`}
          pagination={false}
          columns={columns}
          onTableRef={(ref) => {
            tableRef.current = ref;
          }}
          summary={() => summary}>
          <Form.Item name="goodsName">
            <Input placeholder="请输入商品名称查询" />
          </Form.Item>
        </Table>
      </Card>
    </Page>
  );
};

export default Update;
