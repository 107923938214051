import { RouteProps } from '@models';

import { authRoutes } from '../pages/auth/routes';
import { sysRoutes } from '../pages/sys/routes';
import { orgRoutes } from '../pages/org/routes';
import { outputRoutes } from 'pages/output/routes';
import { incomeRoutes } from '../pages/income/routes';

/**
 * 组合应用所有路由信息
 */
const routes: RouteProps[] = [];
routes.push.apply(routes, authRoutes);
routes.push.apply(routes, sysRoutes);
routes.push.apply(routes, orgRoutes);
routes.push.apply(routes, outputRoutes);
routes.push.apply(routes, incomeRoutes);

export { routes };