import React, { FC, useEffect, useState } from 'react'
import { Form, Row, Col, Button } from 'antd'
import { Context, Http } from '@utils';

import { Page, Card, DisplayInput, Download } from '@comps'
import TextArea from 'antd/lib/input/TextArea';

const Item = Form.Item

const AsyncTaskDetail: FC = (props: any) => {
    const taskId = Context.getPathVar(props)
    const [form] = Form.useForm()
    const [dataFileUrl, setDataFileUrl] = useState<string>()
    const [statusCode, setStatusCode] = useState<string>()

    useEffect(() => {
        refresh()
        // eslint-disable-next-line
    }, [])

    const refresh = () => {
        Http.get(`/sys/tasks/${taskId}`).then(data => {
            form.setFieldsValue({ ...data })
            setDataFileUrl(data.dataFileUrl)
            setStatusCode(data.statusCode)
        })
    }

    return (
        <Page backable>
            <Card title='任务详情' actions={statusCode === 'RUNNING' && <Button type='primary' onClick={refresh}>刷新</Button>}>
                <Form form={form}>
                    <Row>
                        <Col span={6}>
                            <Item name='id' label='任务ID'><DisplayInput /></Item>
                        </Col>
                        <Col span={6}>
                            <Item name='taskName' label='任务名称'><DisplayInput /></Item>
                        </Col>
                        <Col span={6}>
                            <Item name='statusDesc' label='运行状态'><DisplayInput /></Item>
                        </Col>
                        <Col span={6}>
                            <Item name='submitter' label='提交人'><DisplayInput /></Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Item name='submitTime' label='提交时间'><DisplayInput /></Item>
                        </Col>
                        <Col span={6}>
                            <Item name='finishTime' label='结束时间'><DisplayInput /></Item>
                        </Col>

                        <Col span={6}>
                            <Item name='costTime' label='耗时(毫秒)'><DisplayInput /></Item>
                        </Col>
                        {dataFileUrl &&
                            <Col span={6}>
                                <Item name='dataFileUrl' label='下载文件'><Download url={dataFileUrl} /></Item>
                            </Col>
                        }

                    </Row>
                    <Row>
                        <Col span={24}>
                            <Item name='log' label='日志文件' labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                                <TextArea readOnly rows={10} />
                            </Item>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col span={11}>

                    </Col>
                </Row>
            </Card>
        </Page >
    )
}

export { AsyncTaskDetail }
