import * as React from 'react';
import { Card, Button } from '@comps';
import { Form, Space, Input } from 'antd';
import { Http, Lang } from '@utils';

export interface Props {
  invoice: JSONObject,
  onClose: () => void,
};

const LinesMd: React.FC<Props> = ({ invoice, onClose }: Props) => {
  const [form] = Form.useForm();
  const isSpecialInvoice = invoice.invoiceType === 0

  const handleUpdate = React.useCallback(() => Lang.noError(async () => {
    await form.validateFields();
    const formData = form.getFieldsValue();
    await Http.put('/tax/changes/actions', {
      ...formData,
      id: invoice.id,
    });
    onClose();
  }), [form, onClose, invoice]);

  return (<Card title='修改客户信息' style={{ marginBottom: '0' }}>
    <Form form={form} labelCol={{ span: 6 }}
      initialValues={invoice}
      layout="vertical"
      style={{ padding: '30px 60px' }}>

      <Form.Item label="客户名称"
        name="buyerName"
        rules={[{ required: true, message: '请输入客户名称' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="客户税号"
        name="buyerTaxNo"
        rules={[{ required: isSpecialInvoice, message: '请输入客户名称' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="地址电话"
        name="buyerAddrTel"
        rules={[{ required: isSpecialInvoice, message: '请输入客户名称' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="银行账号"
        name="buyerBankAccount"
        rules={[{ required: isSpecialInvoice, message: '请输入客户名称' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="备注"
        name="remark">
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6 }}>
        <Space>
          <Button type="primary"
            clickCallback={handleUpdate}>确认修改</Button>
          <Button onClick={() => onClose()}>取消</Button>
        </Space>
      </Form.Item>
    </Form>
  </Card>);
};

export default LinesMd;
