import React, { FC } from 'react'
import {Input, Form, DatePicker, Space, Alert} from 'antd'

import { Page, Card, Table, Lookup,Button,Modal } from '@comps'
import { ColumnProps } from '@comps/types'
import {DateUtils, Http} from '@utils'
import { Link } from 'react-router-dom'

const Item = Form.Item
const { RangePicker } = DatePicker;

/**
 * 待开票记录
 */
const WaitInvoiceList: FC = () => {
  const [modal, modalHolder] = Modal.useModal();

  const columns: ColumnProps[] = [
        { key: 'invoiceTypeDesc', title: '发票类型', width: 130, },
        { key: 'requestDate', title: '申请日期', width: 120, },
        { key: 'buyerName', title: '购买方', width: 200, },
        { key: 'invoiceAmount', title: '不含税金额', format: 'amount', width: 120 },
        { key: 'taxAmount', title: '税额', format: 'amount', width: 120 },
        { key: 'totalAmount', title: '价税合计', format: 'amount' },
        { key: 'sellerName', title: '销售方', width: 200, },
        { key: 'generateWayDesc', title: '来源', width: 90, tag: {} },
        {
            key: 'requestNo',
            title: '操作',
            width: 150,
            fixed: 'right',
            render: (value) => (
              <Space>
                <Button type="link" onClick={()=>openTwoPic(value)} >二维码</Button>
                <Link to={`/tax/outputInvoice/wait/${value}/detail`}>详情</Link>
              </Space>

            ),
        },
    ]

  /**
   *
   * 开票二维码
   */
  const  openTwoPic = async (value:string) => {
    const QRCodeInfo = await Http.get<JSONObject>('/tax/outputInvoices/qrcodeUrl', {requestNo: value,});
    await modal.open({
      width: '780px',
       content(resolve, reject) {
        return(<Card title="创建成功" style={{ marginBottom: '0' }}>
          <Alert
          message="提示"
          description={(<Space direction="vertical">
          <div>
          1.您可将二维码提供给客户，客户通过微信或支付宝扫一扫提交发票抬头信息，提交后将自动开票。
          </div>
          <div>
          2.若开具了电子发票，客户可保存至微信或支付宝卡包。开具成功后系统将自动发送至客户邮箱。
          </div>
          </Space>)}
          type="success"
          />
          <div style={{ padding: '36px 0', textAlign: 'center' }}>
            <img src={QRCodeInfo.url}
                 style={{ width: '200px', height: '200px' }}
                 alt="二维码" />
          </div>
        </Card>);
      },
    });
  }

    return (
        <Page>
          {modalHolder}
            <Card title='待开记录'>
                <Table url='/tax/outputInvoices?statusCode=1' columns={columns} pagination={{ hideOnSinglePage: false }}>
                    <Input name='key' placeholder='购方名称/购方税号' />
                    <Item name='requestDateFromTo' >
                        <RangePicker placeholder={['申请日期从', '申请日期至']} format='YYYYMMDD'
                            defaultValue={[DateUtils.monthFirstDay(), DateUtils.monthLastDay()]} />
                    </Item>
                    <Item name='invoiceType'>
                        <Lookup lookupType='TAX_INVOICE_TYPE' placeholder="发票类型" />
                    </Item>
                </Table>
            </Card>

        </Page>
    )
}

export default WaitInvoiceList;
