import React, { FC, useEffect, useState } from 'react';
import { Form, Row, Col, Button, DatePicker, Space } from 'antd';

import { Card, DisplayInput } from '@comps';

import { Http, Message, DateUtils } from '@utils';

const { Item } = Form;
const { RangePicker } = DatePicker;

interface Props {
  onClose: () => void
};

const InvoiceCollectMD: FC<Props> = ({
  onClose
}) => {

  const [form] = Form.useForm();

  const [invCollectLoading, setInvCollectLoading] = useState(false);

  const handleInvCollect = () => {
    setInvCollectLoading(true);

    const { date } = form.getFieldsValue();
    const starTime = DateUtils.momentToStr(date[0], 'YYYY-MM-DD');
    const endTime = DateUtils.momentToStr(date[1], 'YYYY-MM-DD');

    Http.get('/input/invPool/ZzsInvoices', {
      starTime,
      endTime
    }).then(() => {
      Message.success('采集成功');
      onClose();
    }).finally(() => {
      setInvCollectLoading(false);
    })
  };

  return (
    <Card title="进项发票采集">
      <Form form={form} onFinish={handleInvCollect}>
        <Row style={{ margin: '20px 0' }}>
          <Col span={24}>
            <Item label="开票日期" name="date" rules={[{ required: true }]}>
              <RangePicker 
                placeholder={['过账日期起', '过账日期止']} 
                format='YYYY-MM-DD' 
                style={{ width: '100%' }} 
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space>
              <Button 
                type="primary" 
                loading={invCollectLoading}
                htmlType="submit"
              >
               确定
              </Button>
              <Button onClick={onClose}>取消</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  )
};

export default InvoiceCollectMD;