import React, { FC, useEffect, useState, useRef } from 'react';
import { Space, Button } from 'antd';

import { Card } from '@comps';

import { Http, Message } from '@utils';

interface Props {
  refreshTable: () => void,
  onClose: () => void
};

const HighBeatMeterUpload: FC<Props> = ({
  refreshTable,
  onClose
}) => {

  let ws: any = null;
  let m_isConnectWS = false;
  let m_splitTag = "$*$";
  let m_closed = false; //是否被关闭了
  let retCapture = 1;
  let AutoCaptureTime = 0;

  const [loading, setLoading] = useState(false);

  const dynamicImgRef: any = useRef();

  useEffect(() => {
    SingleCamera.InitSDKAndInitUI();

    return () => {
      unload();
    }
  }, []);

  //开启webSocket
  function sendWsMessage(jsonObj: any) {
  	const jsonStr = JSON.stringify(jsonObj);
  	ws && ws.send(jsonStr);
  }

  function unload() {
  	if(m_closed){
  		return;
  	}

  	//反初始化
  	const jsonObj = {
      FuncName: 'camUnInitCameraLib'
    };

  	sendWsMessage(jsonObj);
  }

  //初始化
  function sendInitMsg() {
  	const jsonObj = {
      FuncName: 'camInitCameraLib'
    }
  	sendWsMessage(jsonObj);
  }

  //显示每一帧
  function setImageWithBase64(base64: string) {
  	const dynamicImg: any = dynamicImgRef.current;

    if (dynamicImg) {
      dynamicImg.src = 'data:image/png;base64,' + base64;
    }
  	//ResizeImage(myimg, myimg.width, myimg.height);
  }

  //获取电脑中的特殊路径setImageWithBase64
  function sendGetPath() {
  	const jsonObj = {
      FuncName: 'GetSpecialFolderPath',
      argument: { 
        nFolder: 5
      }};

  	sendWsMessage(jsonObj);
  }

  //打开设备
  function openDev() {
  	if(m_closed){
  		StartWebSocket();
  	}

  	//打开摄像头
  	let jsonObj: any = {
      FuncName: 'camOpenDev',
      argument: { 
        devIndex: SingleCamera.devIndex, 
        subtype: 0,
        width: 0,
        height: 0
      }
    };

  	sendWsMessage(jsonObj);

  	//开始预览
  	jsonObj = {
      FuncName: 'camStartPreview'
    };

  	sendWsMessage(jsonObj);
  }

  //处理回调
  function AutoCaptureBack(re: string) {
  	const progress0: any = document.getElementById("autoCaptureProgress");
  
  	if(re === '-1000'){
  		progress0.value = '0';
  	} else {
  		progress0.value = '100';

  		setTimeout(() => {
  			progress0.value = "0";
  		}, AutoCaptureTime / 2  * 1000);
  	}
  }

  //录音声音反馈
  function GetVoice() {
  	const objSelect: any = document.getElementById("voice");

  	const jsonObj = {
      FuncName:'camGetMicrophoneVolumeLevel',
      argument: {
        devIndex: objSelect.selectedIndex
      }
    };

  	sendWsMessage(jsonObj);
  } 

  function ShowVioce(volume: any){
  	const objSelect: any = document.getElementById("voice");
  	objSelect.setAttribute('Value', volume);
  }

  function handleJsonStrMessage(str: any){
  	const jsonOBJ = JSON.parse(str);
  	const name = jsonOBJ.FuncName;
  	const re = jsonOBJ.result;

  	//初始化
  	if (name === 'camInitCameraLib') {
		openDev();

		//获取设备名
		let jsonObj = {
      FuncName: 'camGetDevName'
    };

		sendWsMessage(jsonObj);

		//获得麦克风
		jsonObj = {
      FuncName: 'camGetAudioDevName'
    };
		sendWsMessage(jsonObj);

		//获得录像格式
		jsonObj = {
      FuncName: 'camGetVideoEncodeName'
    };

		sendWsMessage(jsonObj);
  	}	else if (name == "camOpenDev") { //打开设备
  		if (re === 0) {

			//获取分辨率
			const jsonObj1 = { FuncName: 'camGetResolution' };
			sendWsMessage(jsonObj1);

			//获取视频格式
			let jsonObj: any = { FuncName: 'camGetMediaTypeName' };
			sendWsMessage(jsonObj);

			jsonObj = {
        FuncName: 'camSetImageAutoCrop',
        argument: {
          CropType: 1
        }
      };
			sendWsMessage(jsonObj);

			jsonObj = { 
        FuncName: 'camSetLivingBodyState',
        argument: { 
          bOpen: 1
        }
      };
			sendWsMessage(jsonObj);

			jsonObj = {
        FuncName: 'camSetImageAdjust',
        argument: {
          Type: 2
        }
      };
			sendWsMessage(jsonObj);
  		} else { // 打开失败
  			
  		}
  	} else if (name === 'camGetDevName') { //获取设备名

  	} else if(name === "camGetMediaTypeName") { // 视频格式
      // configureVideoFormate(re);
  		// configureVideoStyle(re);
  	} else if(name === "camGetResolution") { //获取分辨率
      // configureRestionInfo(re);
  	} else if(name === "camSetResolution") { //设置分辨率

  	} else if (name === "camCaptureImageFile") { //拍照
  		if(re !== 0){
  			Message.warn("拍照失败");
  		} else {
  			retCapture = re;
  		}
  	} else if (name === "camSetImageAutoCrop") { //自动裁切
  		if (re !== 0) {
      // 自动裁切失败
  		}
  	} else if (name === "camZooIn") {
  		if (re === 0) {
  			Message.success("视频放大成功");
  		}
  	} else if (name === "CaptureEncodeBase64") { //Base64
      setLoading(true);

      const formData = new FormData;
      formData.append('fileUrl', 'data:image/png;base64,' + re)
     
      Http.post('/input/invoices/imageTextDistinguish', formData)
        .then(data => {
          refreshTable();
          onClose();
          Message.success('录入成功');
        })
        .finally(() => {
          setLoading(false);
        })

  	} else if (name === "coderRecognizeBarcode") { // 条码识别
  		Message.info(re);
  	} else if (name === "camStartAutoCapture") { // 连拍
  		if(re === '0'){
  			Message.success("连拍开启成功");
  		}
  	} else if (name === "camStopAutoCapture") { // 停止连拍
  		if(re === '0'){
  			Message.success("停止连拍成功");
  			AutoCaptureBack("-1000");
  		}
  	} else if (name === "AutoCaptureBack") { // 连拍回调
  		if (re === "0") {
  			AutoCaptureBack(re);
  		} else {
  			AutoCaptureBack("-1000");
  			Message.warn("连拍回调出错" + String(re));
  		}
  	} else if (name === "camGetAudioDevName") { 	// 选择麦克风
      // MicrophoneName(re);
  	} else if (name === "camGetVideoEncodeName") { //录像格式
      // VideoName(re);
  	} else if (name === "camStartRecord") { // 开始录像
  		if(re === 0) {
  			Message.info("开始录像");
  			GetVoice();
  		} else {
  			Message.warn("录像失败");
  		}
  	} else if (name === "camStopRecord") { //关闭录像
  		if (re === 0) {
        Message.success("录制成功")
      } else {
        Message.warn("录制失败")
      }
  	} else if (name === "camGetMicrophoneVolumeLevel"){
  		ShowVioce(re);
  	}
  }

  function StartWebSocket() {
    const url = "ws://localhost:9000/";

    if ('WebSocket' in window) {
       ws = new WebSocket(url);
    } else {
      Message.warn('浏览器版本过低，请升级您的浏览器。浏览器要求：IE10+/Chrome14+/FireFox7+/Opera11+');
    }

    ws.onopen = function() {
      m_isConnectWS = true;
      unload();
      sendInitMsg();//初始化
      sendGetPath(); //获取电脑中的路径
      m_closed = false;
    };

    ws.onmessage = function (evt: any) {
      if (typeof(evt.data) === "string") {
        const str = evt.data;

        if (str.length <= 0){
          return;
        }

        if (str.indexOf("FileEncodeBase64") >= 0) {
          return;
        }

        if (str.indexOf(m_splitTag) >= 0) {
          //视频的每一帧
          let strs = new Array();
          strs = str.split(m_splitTag);
          setImageWithBase64(strs[1]);
        } else {
          //处理其他请求
          handleJsonStrMessage(str);
        }
      }
    };

    ws.onclose = function() {
      m_isConnectWS = false;
      const dynamicImg: any = dynamicImgRef.current;
      dynamicImg.src = "images/load1.gif";
      // StartWebSocket();
    }
  }

  function CaptureBase64Ex(){
    const jsonObj = {
      FuncName: 'CaptureEncodeBase64'
    };

    sendWsMessage(jsonObj);
  }

  const SingleCamera = {
    first: 1,
    devIndex : 0,
    comparePath: '',
    compareBase64: '',
    previewDevIndex: 0,
    realPicPath: '',
    encodeBase64Tag:0,

    //初始化UI
    InitSDKAndInitUI() {
      if (this.first === 1) {
        StartWebSocket();
        this.first = 0;
      }
    },
    //反初始化
    UInitCamera() {
      unload();
    }
  };

  const handleCloseMD = () => {
    unload();
    setTimeout(() => {
      onClose();
    }, 500);
  };

  return (
    <Card title="高拍仪录入">
      <div style={{ margin: '30px 0' }}>
        <img ref={dynamicImgRef} style={{ width: 506, height: 380 }} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Space>
          <Button 
            type="primary" 
            loading={loading}
            onClick={CaptureBase64Ex}
          >
            保存
          </Button>
          <Button onClick={handleCloseMD}>关闭</Button>
        </Space>
      </div>
    </Card>
  )
};

export default HighBeatMeterUpload;