import React, { FC } from 'react'
import { Input, DatePicker, Form } from 'antd'
import { ColumnProps } from '@comps/types'

import { Page, Card, Table, Lookup, Download } from '@comps'
import { Link } from 'react-router-dom';

const Item = Form.Item;
const AsyncTasks: FC = () => {
    const columns: ColumnProps[] = [
        { title: '任务名称', key: "taskName", width: 200, },
        { title: '状态', key: "statusDesc", width: 100, },
        { title: '提交时间', key: "submitTime", width: 200 },
        { title: '结束时间', key: "finishTime", width: 200 },
        { title: '时间消耗(毫秒)', key: "costTime", },
        { title: '提交人', key: "submitter", },
        { title: '数据文件', key: "dataFileUrl", width: 100, render: (url) => (url && <Download url={url} />) },
        { title: '详情', key: "id", width: 100, render: (id) => (<Link to={`/sys/asyncTasks/${id}`}>详情</Link>) },
    ];

    return (
        <Page>
            <Card title='异步任务' >
                <Table columns={columns} url='/sys/tasks' pagination={{hideOnSinglePage:false}}>
                    <Input name='taskName' placeholder='任务名称' />
                    <Item name='statusCode'>
                        <Lookup lookupType='SYS_ASYNC_TASK_STATUS' placeholder='运行状态' />
                    </Item>
                    <Item name='submitTimeFrom'>
                        <DatePicker showTime placeholder='提交时间从' />
                    </Item>
                    <Item name='submitTimeTo'>
                        <DatePicker showTime placeholder='提交时间至' />
                    </Item>
                </Table>
            </Card>
        </Page >
    )
}

export { AsyncTasks };
