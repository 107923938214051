import * as React from 'react';
import { Space, Form, Input, InputNumber } from 'antd';
import { Card, Button } from '@comps'
import { Http, Message } from '@utils'

const Item = Form.Item

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

export interface Props {
    onCancel: () => void,
    onConfirm: () => void,
    id: number,
    source: string, // 来源, I为发票, C为改票
}

const Adjustment: React.FC<Props> = ({
    onCancel, onConfirm, id, source
}) => {
    const [form] = Form.useForm();
    async function handleSave() {
        await form.validateFields()
        let values = form.getFieldsValue()
        let url = source === 'I' ? '/tax/outputInvoices/adjustment' : '/tax/changes/adjustment'
        await Http.put(url, {
            lineId: id,
            ...values
        })
        Message.saveOk()
        onConfirm();
    }

    return (
        <Card title='借贷项调整' style={{ marginBottom: '0' }}>
            <Form {...layout} form={form}>
                <Item label="SAP发票号" name='invoiceNo' rules={[{ required: true }]}><Input /></Item>
                <Item label="借贷项金额" name='amount' rules={[{ required: true }]}><InputNumber precision={2} style={{ width: '100%' }} /></Item>

                <div style={{ textAlign: 'center' }}>
                    <Space>
                        <Button type="primary" clickCallback={handleSave}>保存</Button>
                        <Button onClick={onCancel}>取消</Button>
                    </Space>
                </div>
            </Form>
        </Card>
    );
};

export default Adjustment;
