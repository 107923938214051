import React, { useState, FC } from 'react';
import { Form, Input, Space, Upload, message, Row, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';

import { Page, Table, Card, Modal, PermissionButton, Button, Download, LinkText } from '@comps';
import { CustomerDetail } from './customerDetail';

import { Layout, Http, Message, Context, Env } from '@utils';
import { ColumnProps } from '@comps/types';

const { Item } = Form;
const CustomerList: FC = (props: any) => {

  const [form] = Form.useForm();
  const [modal, modalHolder] = Modal.useModal();

  const [isModalVisible, setModalVisible] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false);

  let [table, setTable] = useState<Table>();
  const onTableRef = (tableRef: Table) => {
    table = tableRef;
    setTable(tableRef);
  };

  const delCustomer = (id: number) => {
    Http.del(`/org/customers/${id}`)
      .then(() => {
        Message.ok();
        table?.refresh();
      })
  };

  const createCustomer = () => {
    form.validateFields()
      .then(values => {
        Http.post('/org/customers', values)
          .then(() => {
            Message.saveOk();
            setModalVisible(false);
            form.resetFields();
            table?.refresh();
          })
      })
  };

  const enterAddModal = () => {
    setModalVisible(true);
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible(false)
  };

  const columns: ColumnProps[] = [
    {
      key: 'customerName',
      title: '客户名称',
      width: 300,
      fixed: 'left',
      render: (orgName, row) => (<Link to={`/org/customers/${row.id}`}>{orgName}</Link>
      )
    },
    {
      key: 'customerCode',
      title: '客户编码',
      width: 220
    },
    { key: 'taxNo', title: '税号', width: 220 },
    { key: 'addrTel', title: '地址电话', width: 240 },
    { key: 'bankAccount', title: '银行账号', width: 240 },
    {
      title: '操作',
      key: 'id',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (id: number, row: any) => (
        <Space>
          <Popconfirm
            title={`确认将[ ${row.customerName} ]删除?`}
            placement='left'
            onConfirm={() => delCustomer(id)}
            okText="确认"
            cancelText="取消"
          >
            <LinkText label='删除' />
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <Page>
      {modalHolder}
      <Card
        title='客户管理'
        actions={
          <Space>
            {/* <Download
              label='下载模板'
              url='/org/customers/exportCustomersTemplate'
              type='button'
            /> */}
            <PermissionButton permissions="OrgCustomer.exportCustomers" type="primary">
              <a
                href={`${Env.getBaseUrl()}/org/customers/exportCustomers?accessToken=${Context.getToken()}`}
                target='_blank'
                rel="noopener noreferrer"
              >
                导出
              </a>
            </PermissionButton>

            <Upload
              accept=".xlsx"
              action={`${Env.getBaseUrl()}/org/customers/importCustomers`}
              showUploadList={false}
              headers={{
                Authorization: Context.getToken(),
              }}
              onChange={async ({ file: { status, response = {} } }) => {
                setUploadLoading(true);

                if (!['done', 'error'].includes(status as string)) {
                  return;
                };

                setUploadLoading(false);

                const { data = {}, code, msg } = response;

                if (String(code) !== '200') {
                  message.error(msg || '未知错误, 请联系管理员');
                  return;
                }

                const { hasError, total, successSize, errorSize, errorFileUrl } = data;

                if (!hasError) {
                  message.success(`全部导入成功,共${total}家客户`);
                  return;
                };

                await modal.open({
                  width: '800px',
                  content(_1, reject) {
                    return (
                      <Card title="导入结果" style={{ marginBottom: '0' }}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <Row justify="center">
                            {`总数量: ${total} 条 导入成功: ${successSize} 条 导入失败${errorSize} 条`}
                          </Row>
                          <Row justify="center">
                            <a
                              href={`${errorFileUrl}?accessToken=${Context.getToken()}`}
                              target='_blank'
                              rel="noopener noreferrer"
                            >
                              点击下载失败记录
                            </a>
                          </Row>
                          <Row justify="center">
                            <Button onClick={() => reject()}>关闭</Button>
                          </Row>
                        </Space>
                      </Card>
                    );
                  }
                });
              }}
            >
              <PermissionButton permissions="OrgCustomer.importCustomer"
                type="primary"
                loading={uploadLoading}>
                导入
              </PermissionButton>
            </Upload>

            <PermissionButton
              type='primary'
              permissions="OrgCustomer.create"
              onClick={enterAddModal}
            >
              创建客户
            </PermissionButton>
          </Space>
        }
      >
        <Table
          url='/org/customers'
          columns={columns}
          rowKey='id'
          onTableRef={onTableRef}
        >
          <Item name='key'>
            <Input placeholder='公司名称/税号/编码' />
          </Item>
        </Table>
      </Card>

      <Modal
        title='创建客户'
        visible={isModalVisible}
        onCancel={onCancel}
        onOk={createCustomer}
      >
        <Form name='form' form={form} {...Layout.modal_1c}>
          <Item name='customerName' label='公司名称' rules={[{ required: true }]}>
            <Input />
          </Item>
          <Item name='taxNo' label='税号' rules={[{ required: true }]}>
            <Input />
          </Item>
          <Item name='customerCode' label='客户编码' >
            <Input placeholder='未提供时系统将自动分配' />
          </Item>
        </Form>
      </Modal>
    </Page>
  );
};

export { CustomerList, CustomerDetail };
