import TaxCode from './taxCode';
import Good from './good';
import { AgentList } from './agent';
import changeRouters from './change/routes';
import { RouteProps } from '@models';
import outputInvoiceRouters from './outputInvoice/routes';

const outputRoutes: RouteProps[] = [
  { path: '/tax/taxCodes', component: TaxCode, private: true },
  { path: '/tax/good', component: Good, private: true },
  { path: '/tax/agents', component: AgentList, private: true },
  ...outputInvoiceRouters,
  ...changeRouters,
]

export {
  outputRoutes 
}
