import React, { FC, Fragment, ReactNode, CSSProperties } from 'react';
import { Button } from 'antd';

import './index.css';

interface Props {
  title?: string,
  backable?: boolean,
  children?: ReactNode,
  style?: CSSProperties
};

interface BackPros {
  backable: boolean
};

const Back: FC<BackPros> = ({ backable }) => {
  
  return (
    backable 
      ? <Button 
          type='link' 
          size='small' 
          onClick={() => { window.history.back() }}
        > 
          返回
        </Button> 
      : <Fragment />
  );
};

const Page: FC<Props> = ({ 
  title, 
  children, 
  style,
  backable = false
}) => {

  return (
    <div className='page' style={style}>
      {
        title 
          ? <div className='page-header'>
              <Back backable={backable} />
              <span>{title}</span>
            </div> 
          : <Back backable={backable} />
      }
      <div className='page-content'>{children}</div>
    </div>
  )
};

export default Page;