import { RouteProps } from '@models'
import Message from './index';
import MessageDetail from './detail';

const messageRoutes: RouteProps[] = [
  { path: '/sys/message', component: Message, private: true },
  { path: '/sys/message/:id', component: MessageDetail, private: true },
]

export default messageRoutes;
