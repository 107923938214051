import * as React from 'react';
import { Card, Button } from '@comps';
import {Form, Space, Input, InputNumber} from 'antd';
import { Http, Lang } from '@utils';

export interface Props {
    line: JSONObject,
    onClose: () => void,
};

const Item = Form.Item

const LinesMd: React.FC<Props> = ({ line, onClose }: Props) => {
    const [form] = Form.useForm();

    const handleUpdate = React.useCallback(() => Lang.noError(async () => {
        await form.validateFields();
        const formData = form.getFieldsValue();
        await Http.put('/tax/changes/lines/goods', {
            ...formData,
            id: line.id,
        });
        onClose();
    }), [form, onClose, line]);

    return (<Card title='修改商品信息' style={{ marginBottom: '0' }}>
        <Form form={form} labelCol={{ span: 6 }}
            initialValues={line}
            layout="vertical"
            style={{ padding: '30px 60px' }}>

            <Item label="商品名称" name="goodsName" rules={[{ required: true, message: '请输入商品名称' }]}><Input /></Item>
            <Item label="税收分类编码" name="taxCode" rules={[{ required: true, message: '请输入税收分类编码' }]}><Input /></Item>
            <Item label="规格型号" name="spec"><Input /></Item>
            <Item label="单位" name="unit"><Input /></Item>
            <Item label="数量" name="quantity" ><InputNumber min={0}/></Item>

            <Form.Item wrapperCol={{ offset: 6 }}>
                <Space>
                    <Button type="primary"
                        clickCallback={handleUpdate}>确认修改</Button>
                    <Button onClick={() => onClose()}>取消</Button>
                </Space>
            </Form.Item>
        </Form>
    </Card>);
};

export default LinesMd;
