import React from 'react'
import { FilePdfTwoTone, FolderTwoTone, FileWordTwoTone, FileZipTwoTone, FileExcelTwoTone, FilePptTwoTone, FileMarkdownTwoTone, FileTextTwoTone, FileUnknownTwoTone, FileImageTwoTone } from '@ant-design/icons'
import { Context, Message } from '@utils'
import copy from 'copy-to-clipboard'

const getFileLogo = (type: string) => {
    switch (type) {
        case 'FOLDER':
            return <FolderTwoTone />
        case 'PDF':
            return <FilePdfTwoTone />
        case 'WORD':
            return <FileWordTwoTone />
        case 'ZIP':
            return <FileZipTwoTone />
        case 'EXCEL':
            return <FileExcelTwoTone />
        case 'PPT':
            return <FilePptTwoTone />
        case 'IMAGE':
            return <FileImageTwoTone />
        case 'MD':
            return <FileMarkdownTwoTone />
        case 'TXT':
            return <FileTextTwoTone />
        default:
            return <FileUnknownTwoTone />
    }
}

const getDownloadUrl = (url: string) => {
    if (url.indexOf('?') === -1) {
        return `${url}?accessToken=${Context.getToken()}`
    } else {
        return url
    }
}

const copyToClipboard = (url: string) => {
    copy(api.getDownloadUrl(url))
    Message.success("拷贝成功")
}

const api = {
    getFileLogo,
    getDownloadUrl,
    copyToClipboard
}

export default api 