import React, { FC, useEffect } from 'react'

import { Input, Button, Form, Tabs, InputNumber, Select } from 'antd'

import { Page, Card } from '@comps'
import { Http, Message } from '@utils'
import { FormInstance } from 'antd/lib/form';

import './index.css'

const { TabPane } = Tabs;
const Item = Form.Item;

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 10 }
}

const buttonLayout = {
    wrapperCol: { span: 10, offset: 4 }
}

/**
 * 全局Profile
 */
const GlobalProfile: FC = () => {
    const [appForm] = Form.useForm()
    const [mailForm] = Form.useForm()
    const [secForm] = Form.useForm()
    const [outputForm] = Form.useForm()
    const [smkpForm] = Form.useForm()

    const mailProps = [
        'mail.smtp-host', 'mail.smtp-port', 'mail.smtp-user', 'mail.smtp-password', 'mail.default-address'
    ].join(',')

    const secuityProps = [
        'global.default-password'
    ].join(',')

    const appProps = [
        'global.app-name', 'global.base-uri', 'global.web-base-uri', 'global.h5-base-uri', 'global.copyright',
        'global.beian', 'global.beian-url'
    ].join(',')

    const outputProps = [
        'invoice.invoicer-source', 'global.jgMacList', 'global.eInvReceiver', 'global.deviceIdList'
    ].join(',')

    const smkpProps = [
        'tax.qrcode-timeout', 'global.app-code', 'global.leshuiUrl'].join(',')

    const initAppProps = () => {
        Http.get(`/sys/profiles/options?configKeys=${appProps}`).then(data => {
            appForm.setFieldsValue(data)
        })
    }

    const initSecuirtyProps = () => {
        Http.get(`/sys/profiles/options?configKeys=${secuityProps}`).then(data => {
            secForm.setFieldsValue(data)
        })
    }

    const initMailProps = () => {
        Http.get(`/sys/profiles/options?configKeys=${mailProps}`).then(data => {
            mailForm.setFieldsValue(data)
        })
    }

    const initOutputProps = () => {
        Http.get(`/sys/profiles/options?configKeys=${outputProps}`).then(data => {
            outputForm.setFieldsValue(data)
        })
    }

    const initsmkpProps = () => {
        Http.get(`/sys/profiles/options?configKeys=${smkpProps}`).then(data => {
            smkpForm.setFieldsValue(data)
        })
    }

    useEffect(() => {
        initAppProps();
    }, [appProps])

    const handleOnChange = (activeKey: string) => {
        switch (activeKey) {
            case 'mail':
                initMailProps()
                break;
            case 'security':
                initSecuirtyProps();
                break;
                break;
            case 'output':
                initOutputProps();
                break;
            case 'smkp':
                initsmkpProps();
                break;
            default:
                initAppProps();
        }
    }

    async function saveOptions(form: FormInstance) {
        await form.validateFields()
        await Http.put('/sys/profiles/batchUpdate', form.getFieldsValue())
        Message.saveOk()
    }


    return (
        <Page>
            <Card title='全局配置'>
                <Tabs defaultActiveKey="app" tabPosition='left' onChange={handleOnChange}>
                    <TabPane tab='应用信息' key='app'>
                        <Form form={appForm} {...layout}>
                            <Item label='应用名称' name='global.app-name' rules={[{ required: true }]}><Input /></Item>
                            <Item label='应用域名前缀' name='global.base-uri' rules={[{ required: true }]}><Input type='url' /></Item>
                            <Item label='Web应用域名前缀' name='global.web-base-uri' rules={[{ required: true }]}><Input type='url' /></Item>
                            <Item label='H5应用域名前缀' name='global.h5-base-uri' rules={[{ required: true }]}><Input type='url' /></Item>
                            <Item label='Copyright声明' name='global.copyright' rules={[{ required: true }]}><Input /></Item>
                            <Item label='网站备案号' name='global.beian' rules={[{ required: true }]}><Input /></Item>
                            <Item label='网站备案号链接' name='global.beian-url' rules={[{ required: true }]}><Input type='url' /></Item>
                            <Item {...buttonLayout}>
                                <Button type='primary' onClick={() => { saveOptions(appForm) }}>保存</Button>
                            </Item>
                        </Form>
                    </TabPane>

                    <TabPane tab='安全信息' key='security'>
                        <Form form={secForm} {...layout}>
                            <Item label='用户默认密码' name='global.default-password' rules={[{ required: true }]}><Input /></Item>
                            <Item {...buttonLayout}>
                                <Button type='primary' onClick={() => { saveOptions(secForm) }}>保存</Button>
                            </Item>
                        </Form>
                    </TabPane>

                    <TabPane tab='邮件信息' key='mail'>
                        <Form form={mailForm} {...layout}>
                            <Item label='SMTP 服务器' name='mail.smtp-host' rules={[{ required: true }]}><Input /></Item>
                            <Item label='SMTP 端口' name='mail.smtp-port' rules={[{ required: true }]}><InputNumber /></Item>
                            <Item label='邮箱用户名' name='mail.smtp-user' rules={[{ required: true }]}><Input /></Item>
                            <Item label='邮箱密码' name='mail.smtp-password' rules={[{ required: true }]}><Input type='password' /></Item>
                            <Item label='默认发件人' name='mail.default-address' rules={[{ required: true }]}><Input /></Item>
                            <Item {...buttonLayout}>
                                <Button type='primary' onClick={() => { saveOptions(mailForm) }}>保存</Button>
                            </Item>
                        </Form>
                    </TabPane>

                    <TabPane tab='扫码开票' key='smkp'>
                        <Form form={smkpForm} {...layout}>
                            <Item label='二维码超时时间(天)' name='tax.qrcode-timeout' rules={[{ required: true }]}><InputNumber /></Item>
                            <Item label='系统编码' name='global.app-code' rules={[{ required: true }]}><Input /></Item>
                            <Item label='乐税开票系统URL' name='global.leshuiUrl' rules={[{ required: true }]}><Input /></Item>
                            <Item {...buttonLayout}>
                                <Button type='primary' onClick={() => { saveOptions(smkpForm) }}>保存</Button>
                            </Item>
                        </Form>
                    </TabPane>

                    <TabPane tab='销项配置' key='output'>
                        <Form form={outputForm} {...layout}>
                            <Item label='电票默认收件人' name='global.eInvReceiver' rules={[{ required: true }]}><Input /></Item>
                            <Item label='机柜Mac地址列表(逗号分割)' name='global.jgMacList' rules={[{ required: true }]}><Input /></Item>
                            <Item label='机柜机身编号列表(逗号分割)' name='global.deviceIdList' rules={[{ required: true }]}><Input /></Item>
                            <Item label='开票人来源' name='invoice.invoicer-source' rules={[{ required: true }]}>
                                <Select options={[
                                    { label: '系统默认(来自销方开票配置信息)', value: '1' },
                                    { label: '当前用户姓名', value: '2' }]} />
                            </Item>
                            <Item {...buttonLayout}>
                                <Button type='primary' onClick={() => { saveOptions(outputForm) }}>保存</Button>
                            </Item>
                        </Form>
                    </TabPane>

                </Tabs>
            </Card>

        </Page >
    )
}

export default GlobalProfile