import * as React from 'react';
import { PermissionButton, Button, Modal, Card } from '@comps';
import { Form, Space } from 'antd';
import { Http, Message, Lang } from '@utils';

export interface Props {
  getIds: () => string[] | undefined;
  onTimerEnd: () => void;
  permissions: string | string[],
};

const BatchPrint: React.FC<Props> = ({ getIds, onTimerEnd, permissions }: Props) => {
  const [model, modalHolder] = Modal.useModal();

  const handleBatchPrint = React.useCallback(() =>Lang.noError(async () => {
    const ids = getIds();
    const invoiceStock = await Http.post<{
      orgName: string,
      firstInvoiceCode: string,
      firstInvoiceNo: string,
      quantity: number,
    }>('/tax/orders/batchPrintPreview', { ids });
    const labelCol = { span: 11 };
    await model.open({
      width: '450px',
      ignoreError: false,
      content(resolve, reject) {
        return(<Card title="请确认第一张发票信息" style={{ marginBottom: '0' }}>
          <Form.Item style={{marginTop: '24px'}}
            labelAlign="right"
            labelCol={labelCol}
            label="销方名称"> {invoiceStock.orgName} </Form.Item>
          <Form.Item labelAlign="right"
            labelCol={labelCol}
            label="发票代码"> {invoiceStock.firstInvoiceCode} </Form.Item>
          <Form.Item labelAlign="right"
            labelCol={labelCol}
            label="发票号码"> {invoiceStock.firstInvoiceNo} </Form.Item>
          <Form.Item labelAlign="right"
            labelCol={labelCol}
            label="打印张数"> {invoiceStock.quantity} </Form.Item>
          <div style={{ textAlign: 'center' }}>
            <Space>
              <Button type="primary" onClick={() => resolve()}>确认打印</Button>
              <Button onClick={() => reject()}>取消</Button>
            </Space>
          </div>
        </Card>);
      },
    });
    await Http.post('/tax/orders/batchPrint', {
      ids,
    });
    Message.success('正在打印,请耐心等待...');
  }), [getIds, model]);

  return (<PermissionButton permissions={permissions}
    type="primary"
    clickCallback={handleBatchPrint}>
    批量打印
    {modalHolder}
  </PermissionButton>);
};

export default BatchPrint;
