import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Popconfirm, Form } from 'antd';

import { Card, Page, Table, Space, LinkText, Modal, Kv } from '@comps';
import Add from './add';
import Edit from './edit';
import Profile from './profile';

import { Http, Message } from '@utils';

import { ColumnProps } from '@comps/types';

const { Item } = Form;

const resetPassword = (userId: number) => {
  Http.put(`/sys/password/reset?userId=${userId}`)
    .then(data => {
      Message.ok();
    })
};

const columns: ColumnProps[] = [
  {
    title: '登录名',
    key: "userName",
    fixed: 'left'
  },
  {
    title: '员工编号',
    key: "employeeNo"
  },
  {
    title: '姓名',
    width: 100,
    key: 'lastName'
  },
  {
    title: '角色名称',
    key: 'roleName',
    width: 300
  },
  {
    title: '性别',
    key: 'genderDesc',
    width: 60
  },
  {
    title: '手机号',
    key: 'mobile',
    width: 150,
  },
  {
    title: '邮箱',
    key: 'emailAddress',
    width: 220
  },
  {
    title: '状态',
    key: 'statusDesc',
    width: 100,
    fixed: "right",
    tag: { color: "geekblue", colors: { "已失效": "red", "有效": "green", "冻结中": "orange" } },
    filterKey: "statusList",
    filters: [
      { text: '已失效', value: 'INACTIVE' },
      { text: '有效', value: 'ACTIVE' },
      { text: '冻结中', value: 'FROZEN' },
    ]
  },
  {
    title: '操作',
    key: 'id',
    fixed: 'right',
    width: 150,
    render: (id: number, row: any) => (
      <Space>
        <Link to={{ pathname: `/sys/users/${id}/edit` }}>修改</Link>
        <Popconfirm
          title={`确认将[${row.lastName}]重置为默认密码?`}
          placement='left'
          onConfirm={() => resetPassword(id)}
          okText="确认"
          cancelText="取消"
        >
          <LinkText onClick={() => { }} label='重置密码' />
        </Popconfirm>
      </Space>
    )
  }
];

const UserList: FC = () => {
  const actions =
    <Fragment>
      <Button type='primary'>
        <Link to={{ pathname: '/sys/users/add' }}>新增用户</Link>
      </Button>
    </Fragment>

  return (
    <Page>
      <Card title='用户管理' actions={actions}>
        <Table url="/sys/users" columns={columns}>
          <Item name='tenantId'>
            <Kv url='/sys/tenants/kv' placeholder='租户' style={{ width: '100%' }} />
          </Item>
          <Item name="key">
            <Input placeholder="用户名/邮箱/手机号" />
          </Item>
        </Table>
      </Card>
    </Page>
  );
};

export {
  UserList,
  Add as UserAdd,
  Edit as UserEdit,
  Profile as UserProfile
};