import React, { FC, useState } from 'react';

import { Http, Context } from '@utils';
import { Row, Col, Form } from 'antd';
import { Card, Kv } from '@comps';
import Button from '@comps/Button';

export interface Props {
    onCancel: () => void,
    onConfirm: () => void,
}

const Item = Form.Item
const SwitchRole: FC<Props> = ({
    onCancel, onConfirm
}) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    async function handleSave() {
        setLoading(true)
        const roleId = form.getFieldValue("roleId")
        await Http.get(`/sys/users/switchRole?roleId=${roleId}`)
        await Context.initUserContext()
        setLoading(false)
        onConfirm()
        // 清除路由缓存
        window.location.reload()
    }

    return (
        <Card title='切换角色' loading={loading}>
            <Form form={form}>
                <Item name='roleId' label='请选择角色' rules={[{ required: true }]}>
                    <Kv url='/sys/users/roleKv' />
                </Item>
            </Form>
            <Row>
                <Col offset={10} span={10}>
                    <Button type="primary" clickCallback={handleSave}>确认切换</Button>
                </Col>
            </Row>
        </Card>
    )
}

export default SwitchRole