import React, { FC, Fragment, useEffect, useState } from 'react'
import { Form, Row, Col, Input, Space, Popconfirm } from 'antd'
import {Card, Page, DisplayInput, Button, Table, LinkText, Modal, PermissionButton} from '@comps'
import { ColumnProps } from '@comps/types';
import { Http, Context, Message, Lang } from '@utils'
import Adjustment from './adjustment'
import OrderImportBorrowMd from "./orderImportBorrow.md";

const Item = Form.Item

export interface Invoice {
  invoiceNo: string,
  orderNo: string,
  invoiceTypeDesc: string,
  requestDate: string,
  needRedNoticeNo: boolean,
  needBlueCodeAndNo: boolean,
  buyerCode:string,
}

/**
 * 待开票发票详情
 */
const WaitInvoiceDetail: FC = (props: any) => {
  const [model, modalHolder] = Modal.useModal();
  const lineTableRef = React.useRef<Table>();
  const requestNo = Context.getPathVar(props, 'requestNo')
  const [form] = Form.useForm()
  const [invoice, setInvoice] = useState<Optional<Invoice>>()
  const context: JSONObject = Context.getContext();
  const invoiceRef: any = React.useRef()

  async function refreshInvoice() {
    let invoice = await Http.get(`/tax/outputInvoices/${requestNo}`)
    form.setFieldsValue(invoice)
    lineTableRef.current?.refresh()
    setInvoice(invoice)
  }

  useEffect(() => {
    ((async () => {
      let invoice = await Http.get(`/tax/outputInvoices/${requestNo}`)
      form.setFieldsValue(invoice)
      setInvoice(invoice)
      invoiceRef.current = invoice
    })());
  }, [form, requestNo]);

  const orderImportBorrow = React.useCallback((lineId) => Lang.noError(async () => {
    await model.open({
      width: '1150px',
      ignoreError: false,
      content(resolve, reject) {
        return (<OrderImportBorrowMd onRefresh={refreshInvoice} onClose={reject} onConfirm={resolve} lineId={lineId} buyerCode={invoiceRef.current.buyerCode} isChange={false}/>);
      }
    });
    //table?.refresh();
  }), [model]);

  const handSave = React.useCallback(async () => {
    try {
      const params = form.getFieldsValue();
      await Http.post(`/tax/outputInvoices/${requestNo}`, {
        ...params,
        requestNo,
      });
      Message.success('保存成功');
    } catch (error) {
      console.error(error);
    }
  }, [form, requestNo]);

  const handLineEdit = React.useCallback((line: JSONObject) => {
    line._isEdit = true;
    line._goodsName = line.goodsName;
    line._taxCode = line.taxCode;
    line._spec = line.spec;
    line._unit = line.unit;
    line._withTaxAmount = line.withTaxAmount;
    lineTableRef.current?.resetDataSource();
  }, []);

  const handLineSave = React.useCallback((line: JSONObject) => Lang.noError(async () => {
    let data: any = await Http.put('/tax/outputInvoices/lines', {
      id: line.id,
      goodsName: line._goodsName,
      taxCode: line._taxCode,
      spec: line._spec,
      unit: line._unit,
      withTaxAmount: line._withTaxAmount
    });
    let withTaxAmtChanged = line._withTaxAmount !== line.withTaxAmount
    line.goodsName = line._goodsName;
    line.taxCode = data.taxCode;
    line.spec = line._spec;
    line.unit = line._unit;
    line.withTaxAmount = line._withTaxAmount;
    line._isEdit = false;
    lineTableRef.current?.resetDataSource();

    if (withTaxAmtChanged) {
      lineTableRef.current?.refresh()
      await refreshInvoice()
    }
    // eslint-disable-next-line
  }), []);

  const handLineCancel = React.useCallback((line: JSONObject) => {
    line._isEdit = false;
    lineTableRef.current?.resetDataSource();
  }, []);

  const handleGoodNameChange = React.useCallback((goodsName: string, line: JSONObject) => {
    line._goodsName = goodsName;
  }, []);

  const handleTaxCodeChange = React.useCallback((taxCode: string, line: JSONObject) => {
    line._taxCode = taxCode;
  }, []);

  const handleSpecChange = React.useCallback((spec: string, line: JSONObject) => {
    line._spec = spec;
  }, []);

  const handleUnitChange = React.useCallback((unit: string, line: JSONObject) => {
    line._unit = unit;
  }, []);

  const handleWithTaxAmtChange = React.useCallback((withTaxAmount: string, line: JSONObject) => {
    line._withTaxAmount = withTaxAmount;
  }, []);

  async function handleAdjustment(id : number) {
    await model.open({
      width: '600px',
      content(resolve, reject) {
        return (<Adjustment onConfirm={resolve} onCancel={reject} id={id} source='I' />);
      },
    });
    lineTableRef.current?.refresh()
    await refreshInvoice()
  }

  /**
   * 删除折扣行
   * @param id 发票行ID
   */
  async function deleteDiscountLine(id: number) {
    await Http.del(`/tax/outputInvoices/discountLines/${id}`)
    lineTableRef.current?.refresh()
    Message.ok()
  }

  const columns: ColumnProps[] = [
    { key: 'lineNum', title: '序号', width: 60 },
    {
      key: 'goodsName', title: '商品名称', width: 280,
      render(goodsName, line: JSONObject) {
        if (line._isEdit) {
          return (<Input defaultValue={goodsName}
            onChange={(event) => handleGoodNameChange(event.target.value, line)} />);
        }
        return goodsName;
      },
    },
    {
      key: 'spec', title: '规格型号', width: 120,
      render(spec, line: JSONObject) {
        if (line._isEdit) {
          return (<Input defaultValue={spec}
            onChange={(event) => handleSpecChange(event.target.value, line)} />);
        }
        return spec;
      },
    },
    {
      key: 'unit', title: '单位', width: 60,
      render(unit, line: JSONObject) {
        if (line._isEdit) {
          return (<Input defaultValue={unit}
            onChange={(event) => handleUnitChange(event.target.value, line)} />);
        }
        return unit;
      },
    },
    { key: 'quantity', title: '数量', width: 80 },
    { key: 'price', title: '不含税单价', width: 120 },
    { key: 'withTaxPrice', title: '含税单价', width: 120 },
    { key: 'amount', title: '不含税金额', width: 120 },
    { key: 'taxRate', title: '税率', width: 60 },
    {
      key: 'taxCode', title: '税收分类编码', width: 200,
      render(taxCode, line: JSONObject) {
        if (line._isEdit) {
          return (<Input defaultValue={taxCode}
            onChange={(event) => handleTaxCodeChange(event.target.value, line)} />);
        }
        return taxCode;
      },
    },
    { key: 'taxAmount', title: '税额', width: 150 },
    {
      key: 'withTaxAmount', title: '含税金额', width: 120,
      render(withTaxAmount, line: JSONObject) {
        if (line._isEdit) {
          return (<Input defaultValue={withTaxAmount}
            onChange={(event) => handleWithTaxAmtChange(event.target.value, line)} />);
        }
        return withTaxAmount;
      },
    },
    {
      key: 'id', title: '操作', width: 210, fixed: "right",
      render(_1, line: JSONObject) {
        if (line._isEdit) {
          return (<>
            <Button type="link" size='small'
              clickCallback={() => handLineSave(line)}>保存</Button>
            <Button type="link" size='small'
              onClick={() => handLineCancel(line)}>取消</Button>
          </>);
        }
        return (
          <Fragment>
            <Button type="link" size='small' onClick={() => handLineEdit(line)}>编辑</Button>
            <Button type="link" size='small' onClick={() => handleAdjustment(line.id)}>调整</Button>
            {line.withTaxAmount > 0 ? (<PermissionButton type='link' permissions='OutputInvoice.orderImportBorrow' onClick={() =>orderImportBorrow(line.id)} >添加借贷项</PermissionButton>):undefined}
            {line.discountLineFlag &&
              <Popconfirm title='删除后商品将不再包含折扣,且无法恢复,确认要删除吗?' onConfirm={() => deleteDiscountLine(line.id)}>
                <LinkText label='删除' />
              </Popconfirm>
            }
          </Fragment>);
      },
    },
  ]

  return (
    <Page backable>
      {modalHolder}
      <Card title={`单据详情 | ${invoice?.orderNo} | ${invoice?.invoiceTypeDesc} | ${invoice?.requestDate}`}
        actions={<Fragment>
          {(<Button type='primary' clickCallback={handSave}>保存</Button>)}</Fragment>}
      >
        <Form form={form}>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Item name='buyerName' label="购方名称" >
                {context.isAdmin ? (<Input />) : (<DisplayInput />)}
              </Item>
            </Col>
            <Col span={4}><Item name='invoiceAmount' label="不含税金额" ><DisplayInput /></Item></Col>
            <Col span={4}><Item name='taxAmount' label="税额" ><DisplayInput /></Item></Col>
            <Col span={4}><Item name='totalAmount' label="价税合计" ><DisplayInput /></Item></Col>
            <Col span={12}>
              <Item name='buyerTaxNo' label="购方税号" >
                {context.isAdmin ? (<Input />) : (<DisplayInput />)}
              </Item>
            </Col>
            <Col span={12}>
              {invoice?.needRedNoticeNo && (
                <Item name='redNoticeNo' label="红字通知单号" >
                  <Input />
                </Item>
              )}
              {invoice?.needBlueCodeAndNo && (<Space>
                <Item name='blueInvoiceCode' label="蓝字发票代码" >
                  <Input />
                </Item>
                <Item name='blueInvoiceNo' label="蓝字发票号码" >
                  <Input />
                </Item>
              </Space>)}
              {' '}
            </Col>
            <Col span={12}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Item name='buyerAddrTel' label="地址电话" ><Input /></Item>
                <Item name='buyerBank' label="银行账号" ><Input /></Item>
              </Space>
            </Col>
            <Col span={12}>
              <Item name='remark' label="备注" >
                <Input.TextArea rows={4}
                  disabled={invoice?.needRedNoticeNo || invoice?.needBlueCodeAndNo}
                  style={{ lineHeight: '18px' }} />
              </Item>
            </Col>
          </Row>
          <Table url={`/tax/outputInvoices/${requestNo}/lines`}
            style={{ margin: '20px 0', minHeight: '200px' }}
            onTableRef={(ref) => {
              lineTableRef.current = ref;
            }}
            columns={columns} />
          <Row gutter={[16, 8]}>
            <Col span={12}><Item name='sellerAddrTel' label="销方地址电话" ><DisplayInput /></Item></Col>
            <Col span={12}><Item name='sellerBank' label="银行账号" ><DisplayInput /></Item></Col>
            <Col span={6}>
              <Item name='cashier' label="收款人" ><Input /></Item>
            </Col>
            <Col span={6}>
              <Item name='checker' label="复核人" ><Input /></Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Page>
  )
}

export default WaitInvoiceDetail
