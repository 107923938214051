import * as React from 'react';
import { Page, Card } from '@comps'
import { Descriptions } from 'antd';
import { Http } from '@utils'
import { useParams } from 'react-router';
import cname from 'classnames';
import styles from './index.module.scss';

export interface Props extends React.Props<HTMLElement>,
  React.BaseHTMLAttributes<HTMLElement> {
};

export interface Message {
  id: number,
  content: string,
  title: string,
  sendTime: string,
  msgSubtypeDesc: string,
}

const MessageDetailPage: React.FC<Props> = (props: Props) => {
  const { id } = useParams();
  const [message, setMessage] = React.useState<Optional<Message>>();

  const loadMessage = React.useCallback(async () => {
    const _message = await Http.get<Message>(`/sys/messages/${id}`);
    setMessage(_message);
  }, [id]);

  React.useEffect(() => {
    loadMessage();
  }, [loadMessage]);

  return (<Page backable>
    <Card title={message?.title}>
      <Descriptions className={cname(styles.meta)}>
        <Descriptions.Item label="发送时间">{message?.sendTime}</Descriptions.Item>
        <Descriptions.Item label="类型">{message?.msgSubtypeDesc}</Descriptions.Item>
      </Descriptions>
      <div className={cname(styles.content)}
        dangerouslySetInnerHTML={{__html: message?.content ?? ''}}></div>
    </Card>
  </Page>);
};

export default MessageDetailPage;
