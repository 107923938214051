import React, { FC, useEffect, useState, Fragment } from 'react';
import { Tree, Button, Space, Row, Col, Table, Form, Input, Select, InputNumber } from 'antd';
import { Page, Card, DisplayInput, Modal } from '@comps'
import { Http, Message, Layout } from '@utils'
import { RoleMenu } from './role'

const Item = Form.Item

const MenuList: FC = () => {
    let [rootMenus, setRootMenus] = useState<[]>()
    // 当前聚焦的菜单, 用于新增菜单时提供根菜单与父菜单
    let [focusMenu, setFocusMenu] = useState({ menuId: -1, selected: false, isLeaf: false })
    // 是否在创建菜单
    let [isCreateMenu, setIsCreateMenu] = useState(false)
    // 正在修改的菜单的ID
    let [menuIdOnEdit, setMenuIdOnEdit] = useState(-1)
    let [menuTree, setMenuTree] = useState<any[]>()
    let [subMenus, setSubMenus] = useState<any[]>()
    let [selectedMenu, setSelectedMenu] = useState({ leafMenuSelected: false, levelOneMenuSelected: false })
    let [leafMenuPermissions, setLeafMenuPermissions] = useState<any[]>()
    let [isShowMenuCreateModal, showMenuCreateModal] = useState(false)
    let [functionKv, setFunctionKv] = useState<any[]>()
    const [menuInfoForm] = Form.useForm()
    const [menuForm] = Form.useForm()

    useEffect(() => {
        Http.get('/sys/menus/rootMenus').then((data: any) => {
            let rootMenuArr: any = []
            let firstMenu = true
            let firstMenuId: number = 0
            data.map((e: any) => {
                if (firstMenu) {
                    firstMenu = false
                    firstMenuId = e.id
                }
                rootMenuArr.push({ id: e.id, menuName: e.menuName })
                return e
            })

            setRootMenus(rootMenuArr)

            renderMenuTree(firstMenuId)
        })

        Http.get('/sys/functions/kv').then((data: any) => {
            let options: any[] = []
            data.map((e: any) => options.push({ label: e.v, value: e.k }))
            setFunctionKv(options)
        })
    }, [])

    /** 点击菜单树 */
    const handOnMenuTreeClick = (rootMenuId: number) => {
        renderMenuTree(rootMenuId)
        renderSubMenus(rootMenuId)
        setSelectedMenu({ leafMenuSelected: false, levelOneMenuSelected: true })
        setFocusMenu({ selected: true, menuId: rootMenuId, isLeaf: false })
        menuInfoForm.resetFields()
    }

    /** 渲染左侧菜单树 */
    const renderMenuTree = (rootMenuId: number) => {
        Http.get(`/sys/menus/menuTree?menuId=${rootMenuId}`).then((data: any) => {
            let level1Menus: [] = data.subMenus
            if (level1Menus) {
                let tree: any[] = []
                level1Menus.map((menu: any) => {
                    let subMenus: [] = menu.subMenus
                    let level2Menus: any[] = []
                    if (subMenus) {
                        subMenus.map((subMenu: any) => {
                            level2Menus.push({ title: subMenu.menuName, key: subMenu.menuId, isLeaf: subMenu.isLeaf })
                            return subMenu
                        })
                    }

                    tree.push({ title: menu.menuName, key: menu.menuId, isLeaf: menu.isLeaf, children: level2Menus })
                    return menu
                })

                setMenuTree(tree)
            }
        })
    }

    /** 选中菜单树上任意菜单 */
    const onMenuSelect = (selectedKeys: any, event: any) => {
        let selected = event.selected
        if (selected) {
            let node = event.node
            let menuId = node.key
            let isLeaf = node.isLeaf

            if (isLeaf) {
                renderFunctionPermissions(menuId)
            } else {
                renderSubMenus(menuId)
            }
            setSelectedMenu({ leafMenuSelected: isLeaf, levelOneMenuSelected: !isLeaf })
            setFocusMenu({ selected: true, menuId: menuId, isLeaf: isLeaf })
        } else {
            setSelectedMenu({ leafMenuSelected: false, levelOneMenuSelected: false })
            setFocusMenu({ selected: false, menuId: -1, isLeaf: false })
        }
    };

    /** 点击菜单树上任意菜单 */
    const onMenuClick = (event: any, node: any) => {
        const menuId = node.key

        Http.get(`/sys/menus/${menuId}`).then((data: any) => {
            let isLeaf = data.isLeaf
            menuInfoForm.setFieldsValue({
                ...data,
                menuId: data.id,
                isLeaf: isLeaf ? '叶子菜单' : '一级菜单',
            })
        })
    }

    /** 渲染子菜单 */
    const renderSubMenus = (menuId: number) => {
        Http.get(`/sys/menus/${menuId}/subMenus`).then((data: any) => {
            setSubMenus(data)
        })
    }

    /** 渲染功能下的权限 */
    const renderFunctionPermissions = (menuId: number) => {
        Http.get(`/sys/menus/${menuId}/permissions`).then((data: any) => {
            setLeafMenuPermissions(data)
        })
    }

    /** 进入创建或编辑菜单窗口 */
    const enterCreateOrEditModal = (menu: any, isCreate: boolean = false) => {
        menuForm.resetFields()
        showMenuCreateModal(true)
        setIsCreateMenu(isCreate)

        if (!isCreate) {
            setMenuIdOnEdit(menu.id)
            Http.get(`/sys/menus/${menu.id}`).then(data => {
                menuForm.setFieldsValue({ ...data })
            })
        }
    }

    /** 创建或更新菜单 */
    const saveCreateOrUpdateMenu = () => {
        menuForm.validateFields().then(values => {
            let parentMenuId = focusMenu.menuId

            let params: any = { parentMenuId: parentMenuId, ...values }
            if (!isCreateMenu) {
                params.id = menuIdOnEdit
                Http.put('/sys/menus', params).then((data: any) => {
                    Message.saveOk()
                    renderMenuTree(data.rootMenuId)
                    renderSubMenus(data.parentMenuId)
                })
            } else {
                Http.post('/sys/menus', params).then((data: any) => {
                    Message.saveOk()
                    renderMenuTree(data.rootMenuId)
                    renderSubMenus(data.parentMenuId)
                })
            }

            showMenuCreateModal(false)
        })
    }

    /** 删除菜单 */
    const deleteMenu = (menu: any) => {
        Http.del(`/sys/menus/${menu.id}`).then(data => {
            Message.ok()
            renderMenuTree(menu.rootMenuId)
            renderSubMenus(menu.parentMenuId)
        })
    }

    return (
        <Page title='菜单管理'>
            <Card title='菜单树'>
                <Space>
                    {rootMenus?.map((e: any) =>
                        <Button key={e.id} id={e.id} onClick={() => handOnMenuTreeClick(e.id)}>{e.menuName}</Button>
                    )}
                </Space>
            </Card>

            <Card title='菜单管理'>
                <Row>
                    <Col span={6}>
                        <Tree style={{ backgroundColor: '#FBFCFC' }}
                            onSelect={onMenuSelect}
                            treeData={menuTree}
                            onClick={onMenuClick}
                        />
                    </Col>
                    <Col offset={1} span={15}>
                        <Row>
                            <Form name='menuInfoForm' form={menuInfoForm} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} >
                                <Row>
                                    <Col span={6}><Item name='menuName' label='菜单名称' ><DisplayInput /></Item></Col>
                                    <Col span={6}><Item name='isLeaf' label='菜单类型' ><DisplayInput /></Item></Col>
                                    <Col span={6}><Item name='seq' label='序号' ><DisplayInput /></Item></Col>
                                    {(focusMenu.selected && !focusMenu.isLeaf) && <Col span={6}><Button type='link' onClick={() => enterCreateOrEditModal(null, true)}>新增子菜单</Button></Col>}
                                </Row>
                            </Form>
                        </Row>

                        <Row>
                            {selectedMenu.levelOneMenuSelected &&
                                <Table columns={[
                                    { title: '序号', key: 'seq', dataIndex: 'seq', },
                                    { title: '菜单名称', key: 'menuName', dataIndex: 'menuName', width: 150, },
                                    { title: '功能页面', key: 'functionName', dataIndex: 'functionName', width: 150, },
                                    { title: '路由', key: 'route', dataIndex: 'route', width: 200, },
                                    {
                                        title: '操作', key: 'id', dataIndex: 'id',
                                        render: (value, row) => (
                                            <Fragment>
                                                <Button size='small' type='link' onClick={() => enterCreateOrEditModal(row)}>修改</Button>
                                                <Button size='small' type='link' onClick={() => deleteMenu(row)}>删除</Button>
                                            </Fragment>)
                                    },

                                ]}
                                    dataSource={subMenus} pagination={false}
                                    rowKey='id' />
                            }
                            {selectedMenu.leafMenuSelected &&
                                <Table columns={[
                                    { title: '序号', key: 'seq', dataIndex: 'seq', width: 60, render: (value, row, index) => index + 1 },
                                    { title: '权限编码', key: 'permissionCode', dataIndex: 'permissionCode', width: 200, },
                                    { title: '权限名称', key: 'permissionName', dataIndex: 'permissionName', width: 200, },
                                    { title: '请求方式', key: 'requestMethod', dataIndex: 'requestMethod', width: 80, },
                                    { title: '请求URI', key: 'requestUri', dataIndex: 'requestUri', width: 200, },
                                ]}
                                    dataSource={leafMenuPermissions} pagination={false}
                                    rowKey='id' />
                            }
                        </Row>
                    </Col>
                </Row>
            </Card>

            <Modal title={isCreateMenu ? '创建菜单' : '编辑菜单'}
                visible={isShowMenuCreateModal}
                onCancel={() => showMenuCreateModal(false)}
                onOk={saveCreateOrUpdateMenu}
            >
                <Form name='menuForm' form={menuForm} {...Layout.modal_1c}>
                    <Item name='menuName' label='菜单名称' rules={[{ required: true }]}><Input /></Item>
                    <Item name='seq' label='序号' rules={[{ required: true }]}><InputNumber type='number' /></Item>
                    <Item name='functionId' label='功能'><Select showSearch optionFilterProp='label' options={functionKv}></Select></Item>
                </Form>
            </Modal>
        </Page>

    );
};

export { MenuList, RoleMenu }